import React, { Component } from 'react';
import TableSelect from '../../partials/TableSelect';


class AddTeacher extends Component {
    state = {
        create: false,
        edit: false,
        select: "d0_5",
        data: {
            info: {
                name: "",
                location: "",
                flexspaces: "",
                status: ""
            },
            basicrules: {
                d0_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d1: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d1_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d2: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d2_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d3: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d3_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d4: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: 5,
                        tuesday: 4,
                        wednesday: 2,
                        thursday: 5,
                        friday: 4
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d4_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                flexible_hours: "",
                price_calculation: "",
                special_months_ratio: false
            },
            regularkeys: {
                use_regular_keys: true,
                swap_key: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: ''
                },
                extra_key: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: ''
                }
            },
            extendedhours: {
                planned: {
                    am: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    pm: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                incidental: {
                    am: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    pm: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                }
            },
            lifephases: [],
            products: [],
            holidays: {
                recurring: [],
                specific: []
            }
        }
    }

    componentDidMount() {
        if (this.props.match.params.type === 'create') {
            this.setState({
                create: true,
                edit: false
            });
        } else {
            if (this.props.match.params.type === 'edit') {
                this.setState({
                    create: false,
                    edit: true
                });
            } else {
                this.setState({
                    create: false,
                    edit: false
                });
            }
            this.setState({
                data: {
                    info: {
                        firstName: "Jane",
                        lastName: "Doe",
                        prefName: "Jane",
                        birthday: "05/07/1998",
                        phone: "(12) 3456 7890",
                        email: "test@wondergarden.com",
                        emergencyPhone: "(12) 3456 7890",
                        socialSecurityNumber: "123.1234.1234.12",
                        postal: "8041",
                        city: "Sidney",
                        street: "43th Street",
                        houseNumber: "145",
                        bsb: "Bank 2",
                        accountNo: "0000 0000 0000 0000",
                        img: "/assets/teacher.png"
                    },
                    basicrules: {
                        d0_5: {
                            p1: {
                                monday: 1,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d1: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d1_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d2: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d2_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d3: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d3_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d4: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d4_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        flexible_hours: 12,
                        price_calculation: 5,
                        special_months_ratio: false
                    },
                    regularkeys: {
                        use_regular_keys: true,
                        swap_key: {
                            monday: 5,
                            tuesday: 4,
                            wednesday: 2,
                            thursday: 5,
                            friday: 4
                        },
                        extra_key: {
                            monday: 5,
                            tuesday: 4,
                            wednesday: 2,
                            thursday: 5,
                            friday: 4
                        }
                    },
                    extendedhours: {
                        planned: {
                            am: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            pm: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        incidental: {
                            am: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            pm: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        }
                    }
                }
            })
        }
    }

    selectDays = (i) => {
        this.setState({
            select: i
        });
    }



    dataBasicRules = (i) => {
        if (i === 'd0_5') {
            return (this.state.data.basicrules.d0_5);
        }
        if (i === 'd1') {
            return (this.state.data.basicrules.d1);
        }
        if (i === 'd1_5') {
            return (this.state.data.basicrules.d1_5);
        }
        if (i === 'd2') {
            return (this.state.data.basicrules.d2);
        }
        if (i === 'd2_5') {
            return (this.state.data.basicrules.d2_5);
        }
        if (i === 'd3') {
            return (this.state.data.basicrules.d3);
        }
        if (i === 'd3_5') {
            return (this.state.data.basicrules.d3_5);
        }
        if (i === 'd4') {
            return (this.state.data.basicrules.d4);
        }
        if (i === 'd4_5') {
            return (this.state.data.basicrules.d4_5);
        }
        if (i === 'd5') {
            return (this.state.data.basicrules.d5);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.state.create ? "aside-bar info create" : "aside-bar info"}>
                    <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => window.history.back()}><i className="material-icons">arrow_back_ios</i> Back</button>
                                {!this.state.edit && !this.state.create ? <button className="right" onClick={() => this.setState({ edit: true })}><i className="material-icons">create</i> Edit</button> : ''}
                            </div>
                        </div>
                        <div className="img-container">
                            <div className="circle">
                                {this.state.create ? <i className="material-icons">person</i> : <img src={this.state.data.info.img} alt="" />}
                                {this.state.create ? <button><i className="material-icons">add</i></button> : <button><i className="material-icons">edit</i></button>}
                            </div>
                        </div>
                        <div className="subtitle">INFO</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">First Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.firstName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Last Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.lastName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Pref Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.prefName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Birthday</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.birthday : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                        </div>

                        <div className="group-input">
                            <label htmlFor="">Phone</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.phone : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Email</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.email : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>

                        <div className="group-input">
                            <label htmlFor="">Emergency Phone</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.emergencyPhone : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Social Security Number</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.socialSecurityNumber : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>

                        <div className="subtitle">ADDRESS</div>

                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Postal</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.postal : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">City</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.city : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Street</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.street : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">House Number</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.houseNumber : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                        </div>

                        <div className="subtitle">BANK DETAILS</div>
                        <div className="group-input">
                            <label htmlFor="">BSB</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.bsb : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Account No.</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.accountNo : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>


                        <div className={this.state.edit || this.state.create ? "btn-container" : "btn-container dissapear"}>
                            <button className="principal">Save</button>
                        </div>
                    </div>
                </div>


                <div className={this.state.create ? "content create" : "content"}>
                    <div className="content-head">
                        <div className="title">
                            <h1>{this.state.create ? "Add Teacher" : this.state.data.info.firstName}</h1>
                            <div className="route">HR and Finances &gt; Teacher Overview &gt; {this.state.create ? "Add Teacher" : this.state.data.info.firstName}</div>
                        </div>
                    </div>
                    <div className="content-body teacher-card">
                        <div className="card">
                            <div className="card-title">PLANNING</div>
                            <div className="flex">
                                <div className="flex-4">
                                    <div className="flex-6 column left">

                                        <div className="check-week">
                                            <table>
                                                <tr>
                                                    <td className="principal">Mon</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Tue</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Wed</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Thu</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Fri</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Sat</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Sun</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="subtitle">Dates</div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Start:</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">End:</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>
                                        
                                    </div>
                                    <div className="flex-6 column right">
                                        <div className="subtitle">Info</div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Location</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Group</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Status</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Contract</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Notes</label>
                                            <textarea name="" id="" cols="30" rows="2"></textarea>
                                        </div>
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-8 column right">
                                    <TableSelect
                                        data={
                                            [
                                                {
                                                    id: 1,
                                                    contract: 1,
                                                    days: 'Mon, Tue, Wed',
                                                    start: '2020/07/05',
                                                    end: '2020/07/05',
                                                    location: 'Brisbane',
                                                    group: 'Kindergarden'
                                                },
                                                {
                                                    id: 2,
                                                    contract: 2,
                                                    days: 'Mon, Tue, Wed',
                                                    start: '2020/07/05',
                                                    end: '2020/07/05',
                                                    location: 'Brisbane',
                                                    group: 'Kindergarden'
                                                },

                                            ]
                                        }
                                        columns={[
                                            {
                                                title: "Contract",
                                                name: "contract",
                                                bool: false
                                            },
                                            {
                                                title: "Days",
                                                name: "days",
                                                bool: false
                                            },
                                            {
                                                title: "Start",
                                                name: "start",
                                                bool: false
                                            },
                                            {
                                                title: "End",
                                                name: "end",
                                                bool: false
                                            },
                                            {
                                                title: "Location",
                                                name: "location",
                                                bool: false
                                            },
                                            {
                                                title: "Group",
                                                name: "group",
                                                bool: false
                                            },
                                        ]}
                                        change={false}
                                        delete={true}
                                        details={true}
                                        delete_function=""
                                        edit_function=""
                                        view_function=""

                                    />
                                </div>

                            </div>

                        </div>
                        <div className="card">
                            <div className="card-title">CONTRACTS</div>
                            <div className="flex">
                                <div className="flex-4">

                                    <div className="flex-6 column left">
                                        <div className="subtitle">Info</div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Sort</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Function</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Legal Care Values</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Qualification description</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Hours</label>
                                            <input type="text" name="" id=""/>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Product</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="flex-6 column right">
                                        <div className="subtitle">Dates</div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Start:</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">End:</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Signing Date</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>

                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-8 column right">
                                    <TableSelect
                                        data={
                                            [
                                                {
                                                    id: 1,
                                                    start: '2020/07/05',
                                                    end: '2020/07/05',
                                                    function: 'Qualified',
                                                    legalCareValues: 'Qualified',
                                                    hours: 42.50
                                                },
                                                {
                                                    id: 2,
                                                    start: '2020/07/05',
                                                    end: '2020/07/05',
                                                    function: 'Qualified',
                                                    legalCareValues: 'Qualified',
                                                    hours: 42.50
                                                },

                                            ]
                                        }
                                        columns={[
                                            {
                                                title: "ID",
                                                name: "id",
                                                bool: false
                                            },
                                            {
                                                title: "Start",
                                                name: "start",
                                                bool: false
                                            },
                                            {
                                                title: "End",
                                                name: "end",
                                                bool: false
                                            },
                                            {
                                                title: "Function",
                                                name: "function",
                                                bool: false
                                            },
                                            {
                                                title: "Legal Care Values",
                                                name: "legalCareValues",
                                                bool: false
                                            },
                                            {
                                                title: "Hours",
                                                name: "hours",
                                                bool: false
                                            },
                                        ]}
                                        change={false}
                                        delete={true}
                                        details={true}
                                        delete_function=""
                                        edit_function=""
                                        view_function=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">SALARY MODIFICATIONS</div>
                            <div className="flex">

                                <div className="flex-5 column">
                                        <div className="subtitle">Dates</div>
                                        <div className="flex">
                                    <div className="flex-6">
                                        <div className="group-input col2">
                                            <label htmlFor="">From</label>
                                            <input type="date" name="" id="name"/>
                                        </div>
                                    </div>
                                    <div className="flex-6">
                                    <div className="group-input col2">
                                        <label htmlFor="" className="center">to</label>
                                        <input type="date" name=""  id="name"/>
                                    </div>
                                    </div>
                                </div>
                                        <div className="group-input">
                                            <div className="flex-6 left">
                                                <div className="group-input no-padding-bottom column">
                                                    <label htmlFor="">Sort</label>
                                                    <select name="location" id="location-select">
                                                        <option value="0" selected>Addition</option>
                                                        <option value="0" selected>Subtraction</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex-6 right">
                                                <div className="group-input no-padding-bottom column">
                                                    <label htmlFor="">Use</label>
                                                    <select name="location" id="location-select">
                                                        <option value="0" selected>Percentage</option>
                                                        <option value="0" selected>Absolute</option>
                                                    </select>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="group-input">
                                            <div className="flex-6 left">
                                                <div className="group-input no-padding-bottom column">
                                                    <label htmlFor="">Timing</label>
                                                    <select name="location" id="location-select">
                                                        <option value="0" selected>One time</option>
                                                        <option value="0" selected>Interval</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex-6 right">
                                                <div className="group-input no-padding-bottom column">
                                                    <label htmlFor="">Value</label>
                                                    <input type="text" name="" id=""/>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="group-input no-padding-bottom column">
                                                <label htmlFor="">Comment</label>
                                                <textarea name="" id="" cols="2" rows=""></textarea>
                                            </div>
                                            <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                                <button>Save</button>
                                            </div>
                                </div>

                                <div className="flex-7 column right">
                                <TableSelect 
                                    data = {this.state.create ? [] :[
                                        {
                                            id: 1,
                                            start: '01/01/2021',
                                            end: '01/06/2021',
                                            location: 'Percentage',
                                            product: '-5%',
                                            paymentby: 'Susan Bloom',
                                            paymentsort: 'Automatic debit'
                                        }
                                    ]}
                                    columns = {[
                                        {
                                            title: 'From',
                                            name: 'start'
                                        },
                                        {
                                            title: 'to',
                                            name: 'end'
                                        },
                                        {
                                            title: 'Use',
                                            name: 'location'
                                        },
                                        {
                                            title: 'Value',
                                            name: 'product'
                                        }
                                    ]}
                                    change = {this.state.create||this.state.edit}
                                    delete = {this.state.create||this.state.edit}
                                    details = {this.state.create||this.state.edit}
                                    delete_function = ""
                                    edit_function = ""
                                    view_function = ""
                                />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AddTeacher;