import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class Report extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false
    }

    render(){
        return ( 
            <React.Fragment>
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i class="material-icons edit">filter_alt</i> FILTERS 2</h3>
                            <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">NAME</label>
                                <input type="text" name="" id=""/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">CONTRACTED LOCATION</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LINKED CHILDREN</label>
                                <input type="text" name="" id=""/>
                            </div>
                            
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="content">
                      <div className="content-head">
                        <div className="title">
                          <h1>Invoices</h1>
                          <div className="route">Caretakers &gt; Invoices</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({display_asidebar: true})}><i class="material-icons edit">filter_alt</i></button>
                        
                      </div>
                      <Table 
                        data = {[
                            {
                                id: 1,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 2,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 3,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 4,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 5,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 6,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 7,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 8,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 1,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 2,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 3,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 4,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 5,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 6,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 7,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 8,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            }
                        ]}
                        columns = {[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Payment by",
                                name: "paymentby",
                                bool: false
                            },
                            {
                                title: "Location",
                                name: "location",
                                bool: false
                            },
                            {
                                title: "Year",
                                name: "year",
                                bool: false
                            },
                            {
                                title: "Period",
                                name: "period",
                                bool: false
                            },
                            {
                                title: "Send date",
                                name: "senddate",
                                bool: false
                            },
                            {
                                title: "Amount",
                                name: "amount",
                                bool: false
                            },
                            {
                                title: "Payment due",
                                name: "paymentdue",
                                bool: false
                            },
                            {
                                title: "Status",
                                name: "status",
                                bool: false
                            }
                        ]}
                        viewLink = "/caretakers/overview/view/"
                        view = {true}
                        edit = {false}
                        delete = {false}
                        editLink = "/caretakers/overview/edit/"
                        deleteFunction = ""
                        totalElements = "47"
                      />
                      <Pagination 
                        page = {this.state.page}
                        per_page = {this.state.per_page}
                        total_elements = {this.state.total_elements}
                        total_pages = {this.state.total_pages}
                        change_page = {page => this.setState({page})}
                        />
                    </div>
            </React.Fragment> 
        );
    }
}

export default Report;