import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class Leads extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false,
        asiderbar_create: false,
        data: {
            caretakerName: 'Charlie Bloom',
            email: 'test@wondergarden.com',
            phone: '(12) 3456 7890',
            preferredContact: 'Phone',
            numChildren: 1,
            birthChild: '05/07/2019',
            age: '14 months',
            preferredDays: 'Monday, Wednesday, Friday',
            additionalInfo: 'This is a test text'
        }
    }

    viewLead = (id) => {
        this.setState({
            asiderbar_create: true,
            display_asidebar: true
        })
    }


    showAsideBar = () => {
        if(this.state.asiderbar_create){
            return(
                <div className={this.state.display_asidebar ? "aside-bar popup info active" : "aside-bar info popup"}>
                <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={()=>this.setState({asiderbar_create: false})}><i className="material-icons">arrow_back_ios</i> Back</button>
                                <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                            </div>
                        </div>
                        <div className="filters">
                        <h3 className="body">CARETAKER INFO</h3>
                            <div className="group-input">
                                <label htmlFor="">Name</label>
                                <input type="text" name="" id="" value={this.state.data.caretakerName}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Email</label>
                                <input type="text" name="" id="" value={this.state.data.email}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Phone</label>
                                <input type="text" name="" id="" value={this.state.data.phone}/>
                            </div>
                            <h3 className="body">CHILD INFO</h3>
                            <div className="group-input">
                                <label htmlFor="">Num. of children requiring care</label>
                                <input type="text" name="" id="" value={this.state.data.numChildren}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Birthday's child</label>
                                <input type="text" name="" id="" value={this.state.data.birthChild}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Age</label>
                                <input type="text" name="" id="" value={this.state.data.age}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Preferred days of care</label>
                                <input type="text" name="" id="" value={this.state.data.preferredDays}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Additional info</label>
                                <input type="text" name="" id="" value={this.state.data.additionalInfo}/>
                            </div>
                            <div className="safe-zone">
                                <div className="group-input">
                                    <label htmlFor="">Location</label>
                                    <select name="" id="" className="safe-zone">
                                        <option value="">Location 1</option>
                                        <option value="">Location 2</option>
                                    </select>
                                </div>
                                <div className="group-input">
                                    <label htmlFor="">Status</label>
                                    <select name="" id="" className="safe-zone">
                                        <option value="">New</option>
                                        <option value="">Working - Contacted</option>
                                        <option value="">Working - Not contacted</option>
                                        <option value="">Closed - Won</option>
                                        <option value="">Closed - Waiting list</option>
                                        <option value="">Closed - Lost</option>
                                    </select>
                                </div>
                                <div className="group-input">
                                    <label htmlFor="">Additional info</label>
                                    <div className="col-2">
                                    <textarea name="" id="" cols="30" rows="1"></textarea> 
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="waiting-list-btn-block">
                            <button className="green">Change</button>
                        </div>
                        <div className="waiting-list-btn-block">
                        <button className="primary">Create caretaker</button>
                        </div>

                        
                        </div>
                        </div>
                        </div>
            );
        }else{
            return(
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                        
                <div className="container">
                        <div className="header">
                            <h3><i class="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">CARETAKER NAME</label>
                                <input type="text" name="" id=""/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">PREFERRED START DAY</label>
                                <input type="date" name="" id=""/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">STATUS</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">New</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Working - Contacted</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city3" value="3"/>
                                    <label htmlFor="city3">Working - Not Contacted</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city4" value="4"/>
                                    <label htmlFor="city4">Closed - Won</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city4" value="4"/>
                                    <label htmlFor="city4">Closed - Waiting List</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city4" value="4"/>
                                    <label htmlFor="city4">Closed - Lost</label>
                                </div>
                            </div>
                            <div className="group-input no-padding-bottom">
                                <div className="subtitle">SUBMITTED DATE</div>
                            </div>
                            <div className="flex">
                                <div className="flex-6">
                                <div className="group-input col-2 no-padding-top">
                                <label htmlFor="">From</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                </div>
                                <div className="flex-6">
                                <div className="group-input col-2 no-padding-top">
                                        
                                        <label htmlFor="" className="center">to</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                        </div>
                    </div>
            );
        }
    }

    render(){
        return ( 
            <React.Fragment>
                    {this.showAsideBar()}
                    <div className="content">
                      <div className="content-head">
                        <div className="title">
                          <h1>Leads</h1>
                          <div className="route">Caretakers &gt; Leads</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({display_asidebar: true, asiderbar_create: false})}><i class="material-icons edit">filter_alt</i></button>
                      </div>
                      <Table 
                        data = {[
                            {
                                id: 1,
                                caretakername: "Charlie Bloom",
                                location: "Not defined",
                                numchildren: 2,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "Phone",
                                status: {
                                    name: "New",
                                    color: "red"
                                }
                            },
                            {
                                id: 2,
                                caretakername: "Susan Doe Bloom",
                                location: "Location 1",
                                numchildren: 1,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "E-mail",
                                status: {
                                    name: "Working-contacted",
                                    color: "blue"
                                }
                            },
                            {
                                id: 3,
                                caretakername: "Charlie Bloom",
                                location: "Location 1",
                                numchildren: 2,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "Phone",
                                status: "Working-Contacted",
                                status: {
                                    name: "Working - Not contacted",
                                    color: "yellow"
                                }
                            },
                            {
                                id: 4,
                                caretakername: "Susan Doe Bloom",
                                location: "Location 2",
                                numchildren: 1,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "E-mail",
                                status: {
                                    name: "Closed - Won",
                                    color: "green"
                                }
                            },
                            {
                                id: 5,
                                caretakername: "Charlie Bloom",
                                location: "Not defined",
                                numchildren: 2,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "Phone",
                                status: {
                                    name: "Closed - Waiting list",
                                    color: "pink"
                                }
                            },
                            {
                                id: 6,
                                caretakername: "Susan Doe Bloom",
                                location: "Location 1",
                                numchildren: 1,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "E-mail",
                                status: {
                                    name: "Closed - Lost",
                                    color: "gray"
                                }
                            },
                            {
                                id: 1,
                                caretakername: "Charlie Bloom",
                                location: "Not defined",
                                numchildren: 2,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "Phone",
                                status: {
                                    name: "New",
                                    color: "red"
                                }
                            },
                            {
                                id: 2,
                                caretakername: "Susan Doe Bloom",
                                location: "Location 1",
                                numchildren: 1,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "E-mail",
                                status: {
                                    name: "Working-contacted",
                                    color: "blue"
                                }
                            },
                            {
                                id: 3,
                                caretakername: "Charlie Bloom",
                                location: "Location 1",
                                numchildren: 2,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "Phone",
                                status: "Working-Contacted",
                                status: {
                                    name: "Working - Not contacted",
                                    color: "yellow"
                                }
                            },
                            {
                                id: 4,
                                caretakername: "Susan Doe Bloom",
                                location: "Location 2",
                                numchildren: 1,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "E-mail",
                                status: {
                                    name: "Closed - Won",
                                    color: "green"
                                }
                            },
                            {
                                id: 5,
                                caretakername: "Charlie Bloom",
                                location: "Not defined",
                                numchildren: 2,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "Phone",
                                status: {
                                    name: "Closed - Waiting list",
                                    color: "pink"
                                }
                            },
                            {
                                id: 6,
                                caretakername: "Susan Doe Bloom",
                                location: "Location 1",
                                numchildren: 1,
                                submitteddate: "04/01/2021",
                                startday: "15/01/2021",
                                contactmethod: "E-mail",
                                status: {
                                    name: "Closed - Lost",
                                    color: "gray"
                                }
                            },
                        ]}
                        columns = {[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Caretaker name",
                                name: "caretakername",
                                bool: false
                            },
                            {
                                title: "Location",
                                name: "location",
                                bool: false
                            },
                            {
                                title: "Num. of children",
                                name: "numchildren",
                                bool: false
                            },
                            {
                                title: "Submitted date",
                                name: "submitteddate",
                                bool: false
                            },
                            {
                                title: "Preferred start day",
                                name: "startday",
                                bool: false
                            },
                            {
                                title: "Preferred contact method",
                                name: "contactmethod",
                                bool: false
                            },
                            {
                                title: "Status",
                                name: "status",
                                bool: false,
                                tab: true
                            }
                        ]}
                        view = {true}
                        edit = {false}
                        delete = {false}
                        viewFunction = {this.viewLead}
                        editFunction = ""
                        deleteFunction = ""
                        totalElements = "47"
                      />
                      <Pagination 
                        page = {this.state.page}
                        per_page = {this.state.per_page}
                        total_elements = {this.state.total_elements}
                        total_pages = {this.state.total_pages}
                        change_page = {page => this.setState({page})}
                        />
                    </div>
            </React.Fragment> 
        );
    }
}

export default Leads;