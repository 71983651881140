import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class ChildrenOverview extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">NAME</label>
                                <input type="text" name="" id="" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">GROUP</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a group...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">GENDER</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">Male</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">Female</label>
                                </div>
                            </div>
                            <div className="group-input no-padding-bottom">
                                <div className="subtitle">AGE</div>
                            </div>
                            <div className="group-input col-2 no-padding-top">
                                <label htmlFor="">From</label>
                                <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />

                                <label htmlFor="" className="center">to</label>
                                <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Children Overview</h1>
                            <div className="route">Children &gt; Children Overview</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({ display_asidebar: true })}><i className="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <Link to="/children/overview/create" className="btn principal"><i className="material-icons">add</i>Add Child</Link>
                        </div>
                    </div>
                    <Table
                        data={[
                            {
                                id: 1,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            },
                            {
                                id: 2,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            },
                            {
                                id: 3,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            },
                            {
                                id: 4,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            },
                            {
                                id: 5,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            },
                            {
                                id: 6,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            },
                            {
                                id: 1,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: false
                            },
                            {
                                id: 2,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: false
                            },
                            {
                                id: 3,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            },
                            {
                                id: 4,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            },
                            {
                                id: 5,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: false
                            },
                            {
                                id: 6,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                gender: "Male",
                                age: "24",
                                birthday: "12/08/2019",
                                product: "Summer camp",
                                group: "Group's name 1",
                                status: true
                            }
                        ]}
                        columns={[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Name",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "Gender",
                                name: "gender",
                                bool: false
                            },
                            {
                                title: "Age",
                                name: "age",
                                bool: false
                            },
                            {
                                title: "Birthday",
                                name: "birthday",
                                bool: false
                            },
                            {
                                title: "Product",
                                name: "product",
                                bool: false
                            },
                            {
                                title: "Group",
                                name: "group",
                                bool: false
                            },
                            {
                                title: "Status",
                                name: "status",
                                bool: true
                            }
                        ]}
                        viewFunction={(id)=>{this.props.history.push(`/children/overview/view/${id}`)}}
                        view={true}
                        edit={true}
                        delete={false}
                        editFunction={(id)=>{this.props.history.push(`/children/overview/edit/${id}`)}}
                        deleteFunction=""
                        totalElements="47"
                    />
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default ChildrenOverview;