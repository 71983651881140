import React, { Component } from 'react';
import {
    Redirect
  } from "react-router-dom";

class Children extends Component {


    render(){
        return ( 
            <React.Fragment>
                <Redirect to="/children/overview" />
            </React.Fragment> 
        );
    }
}

export default Children;