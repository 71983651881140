import React, { Component } from "react";

class CheckList extends Component {


    state = {
        display_asidebar: false,
        create: true,
        edit: true,
        selectedQuestion: 0,
        numberAnswers: 1,
        selectedType: '1',
        data: {
            info: {
                name: "",
                city: "",
                postal: "",
                street: "",
                houseno: "",
                phone: "",
                email: "",
                localmanager: ""
            },
            exitmoment: {
                age: "",
                exitdate: []
            },
            payment: {
                paymentdue: "",
                cancelationdays: "",
                monthinadvance: "",
                otherfromlog: ""
            },
            legalcarevalues: {
                data: [],
                columns: []
            },
            lifephases: [],
            products: [],
            holidays: {
                recurring: [],
                specific: []
            }
        },
        questions: [
            {
                id: 1,
                order: 1,
                type: 'check',
                name: 'Sleeping hours',

            },
            {
                id: 2,
                order: 2,
                type: 'text',
                name: 'Eating time',
            },
            {
                id: 3,
                order: 3,
                type: 'radio',
                name: 'Mood',
            }
        ]
    }

    handleSelect = (e) =>{
        let value = e.target.value;
        this.setState({

        })
    }

     showInputAnswers = () =>{
         const array = []
         for(let i = 0; i < this.state.numberAnswers; i++){
             const element = 'hi';
             array.push(element);
         }
         return (
            array.map((question, i) => (
                <div className="group-input no-padding-bottom">
                    <input type="text" name="" id="" defaultValue="" placeholder={"Option "+(i+1)+"..."} key={i}/>
                </div>
            ))
            
         );
     }

    render() {
        return (

            <React.Fragment>
                <div className="aside-bar"
                >
                    <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => window.history.back()}><i className="material-icons">arrow_back_ios</i> Back</button>
                                {!this.state.edit && !this.state.create ? <button className="right" onClick={() => this.setState({ edit: true })}><i className="material-icons">create</i> Edit</button> : ''}
                            </div>
                            <h3 className="center">create checklist</h3>
                        </div>

                        <div className="group-input">
                                <label htmlFor="">Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.name : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                                <label htmlFor="">Active</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city6" value="1"/>
                                    <label htmlFor="city6">Active</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city7" value="2"/>
                                    <label htmlFor="city7">Non-active</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Location</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Location 1</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Location 2</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city3" value="3"/>
                                    <label htmlFor="city3">Location 3</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Type</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city4" value="1"/>
                                    <label htmlFor="city4">Children</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city5" value="2"/>
                                    <label htmlFor="city5">Group</label>
                                </div>
                            </div>
                            <div className="group-input">
                            <label htmlFor="">Visible to parents</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Visible</label>
                                </div>
                            </div>
                            <div className={this.state.edit || this.state.create ? "btn-container" : "btn-container dissapear"}>
                            <button className="principal">Save</button>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Create Checklist</h1>
                            <div className="route">Control &gt; Checklist Overview &gt; Create Checklist</div>
                        </div>

                    </div>
                    <div className="content-body">
                        <div className="flex-4 column left">
                            <div className="card card-vertical">
                            <div className="card-title">QUESTIONS</div>
                                <div className="button-filters">
                                    {
                                        this.state.questions.map((question) => (
                                            <div onClick={() => { this.setState({ selectedQuestion: question.name }) }} className={this.state.selectedQuestion === question.name ? "button-filter selected-filter questions" : "button-filter questions"}>
                                                <p> {question.order+". "+question.name}
                                                <b> {question.type} </b>
                                                </p>
                                                
                                                <i className="material-icons">
                                                    arrow_forward_ios
                                                </i>
                                            </div>
                                        ))
                                    }
                                    <button onClick={() => { this.setState({ add: true }) }} className="wage-add">
                                        <i className="material-icons">add</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                            <div className="flex-8 column right">
                                <div className="card card-vertical">
                                    <div className="card-title">NEW QUESTION</div>

                                    <div className="flex">
                                        <div className="flex-4 left column">
                                            <div className="group-input">
                                                <label htmlFor="">Type</label>
                                                <select name="location" id="location-select" onChange={(e) => this.setState({selectedType: e.target.value})}>
                                                    <option value="1" selected>Checkbox</option>
                                                    <option value="2">Text</option>
                                                    <option value="3">Number</option>
                                                    <option value="4">Radio</option>
                                                    <option value="5">Date</option>
                                                    <option value="6">Time</option>
                                                </select>
                                            </div>
                                            {this.state.selectedType === '1' || this.state.selectedType === '4' ? 
                                            <div className="group-input">
                                                <label htmlFor="">Number of answers</label>
                                                <input type="number" name="" id="" defaultValue="1" onChange={(e)=>this.setState({numberAnswers: e.target.value})}/>
                                            </div>
                                            : ''}
                                            
                                        </div>
                                        <div className="flex-8 right column">
                                            <div className="group-input ">
                                                <label htmlFor="">Question</label>
                                                <input type="text" name="" id="" />
                                            </div>
                                            
                                            {this.state.selectedType === '1' || this.state.selectedType === '4' ? 
                                            <React.Fragment>
                                                <div className="subtitle">Answers</div>
                                                {this.showInputAnswers()} 
                                            </React.Fragment>
                                            : ''}
                                            
                                        </div>
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                        <button>Save</button>
                                    </div>
                                </div>

                                </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default CheckList;