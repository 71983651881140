import React, { Component } from 'react';
import {
    Redirect
} from "react-router-dom";

class Locations extends Component {


    render() {
        return (
            <React.Fragment>
                <Redirect to="/locations/overview" />
            </React.Fragment>
        );
    }
}

export default Locations;