import React, { Component } from "react";
import { RichTextEditor } from "../../partials/Editor";

export class Templates extends Component {

    state = {
        display_asidebar: false,
        selectedFilter: 'Child’s contract',
        templates: [
            {
                name: 'Child’s contract'
            },
            {
                name: 'Child’s contract email'
            },
            {
                name: 'Invoice template'
            },
            {
                name: 'Invoice template email'
            },
            {
                name: 'Teachers contract'
            },
            {
                name: 'Teachers contract email'
            },
            {
                name: 'Letter of offer'
            },
            {
                name: 'Letter of offer email'
            },
        ]
    }

    render() {
        return <React.Fragment>

            <div className="aside-bar">
                <div className="container">
                    <div className="header">
                        <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>

                    </div>

                    <div className="button-filters">
                        {
                            this.state.templates.map((template) => (
                                <React.Fragment>
                                    <div onClick={() => { this.setState({ selectedFilter: template.name }) }} className={this.state.selectedFilter === template.name ? "button-filter selected-filter" : "button-filter"}>
                                        <p> {template.name}</p>
                                        <i className="material-icons">
                                            arrow_forward_ios
                                        </i>

                                    </div>

                                </React.Fragment>
                            ))
                        }
                    </div>

                </div>
            </div>

            <div className="content">
                <div className="content-head">
                    <div className="title">
                        <h1>{this.state.selectedFilter} </h1>
                        <div className="route">Admin Tools &gt; Templates &gt; {this.state.selectedFilter} </div>
                    </div>

                </div>
                <div className="content-body">
                    <RichTextEditor></RichTextEditor>
                </div>
            </div>

        </React.Fragment>
    }
}