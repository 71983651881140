import React, { Component } from 'react';

class TableCardSmall extends Component {

    printRows = () =>{
        if(this.props.data.length !== 0){
            return(
                <tbody>
                        {this.props.data.map((item, key) => (
                            <tr>
                                {this.props.buttons_first 
                                ? 
                                    <React.Fragment>
                                        <td>
                                            {this.props.edit ? <button className="edit"><i className="material-icons">create</i></button> : null}
                                            {this.props.delete ? <button className="remove"><i className="material-icons">remove</i></button>: null}
                                        </td>
                                        {this.printTd(key)}
                                    </React.Fragment>
                                :
                                    <React.Fragment>
                                        {this.printTd(key)}
                                        <td>
                                            {this.props.edit ? <button className="edit"><i className="material-icons">create</i></button>: null}
                                            {this.props.delete ? <button className="remove"><i className="material-icons">remove</i></button> : null}
                                        </td>
                                    </React.Fragment>
                                }
                                
                            </tr>
                        ))}
                </tbody>
            );
        }else{
            return(
                <tbody>
                    <tr>
                        <td colSpan={this.props.columns.length+1} rowSpan="8" className="error">No records</td>
                    </tr>
                </tbody>
            );
        }
    }

    printTd = (i) =>{
        return(
            <React.Fragment>
                {this.props.columns.map(column => (
                    <td>
                        {
                        (this.props.data[i][column.name] ? this.props.data[i][column.name] :'')
                        }
                    </td>
                ))}
            </React.Fragment>
        ); 
        
    }

    printColumns = () =>{
        if(this.props.columns.length !== 0){
            return(
                this.props.columns.map(column => (
                    <th>{column.title}</th>
                ))
            );
          
        }
    }

    render(){
        return ( 
            <React.Fragment>
                <div className="table-card">
                    <table>
                        <thead>
                        {this.props.buttons_first 
                                ? 
                                <tr>
                                    <th className={this.props.delete && this.props.edit ? "btn-container2" :"btn-container"}></th>
                                    {this.printColumns()}
                                </tr>
                                :
                                <tr>
                                    {this.printColumns()}
                                    <th className={this.props.delete && this.props.edit ? "btn-container2" :"btn-container"}></th>
                                </tr>
                                }
                            
                        </thead>
                        {this.printRows()}
                    </table>
                </div>
            </React.Fragment> 
        );
    }
}

export default TableCardSmall;