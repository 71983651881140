import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";
import OccupancyChart from './partials/OccupancyChart';
import TableReports from './partials/TableReports';

class ReportsOccupancy extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false,
        asiderbar_create: false,
        selected: 1,
        location: 'All locations',
        group: 'All groups',
        startDate: '',
        endDate: '',
        orderBy: 'Location',
        orderBy2: 'ASC'
    }



    showFilters = () => {
        if(this.state.selected === 1){
            return(
                   <React.Fragment>
                       <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i>FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                        </div>        
                            <div className="group-input column">
                            <div className="subtitle">location</div>
                                <select name="location" id="location-select" onChange={(e)=>this.setState({location: e.target.value})}>
                                    <option value="All locations" selected>All locations</option>
                                    <option value="Location's name 1">Location's name 1</option>
                                    <option value="Location's name 2">Location's name 2</option>
                                </select>
                            </div>
                            
                            <div className="group-input column">
                            <div className="subtitle">group</div>
                                <select name="location" id="location-select" onChange={(e)=>this.setState({group: e.target.value})}>
                                    <option value="All groups" selected>All groups</option>
                                    <option value="Group's name 1">Group's name 1</option>
                                    <option value="Group's name 2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="col-2">
                                <div className="group-input column">

                                <div className="subtitle">start date</div>
                                    <input type="date" name="" id=" " onChange={(e)=>this.setState({startDate: e.target.value})}/>
                                    </div>
                                <div className="group-input column">
                                <div className="subtitle">end date</div>
                                <input type="date" name="" id="" onChange={(e)=>this.setState({endDate: e.target.value})}/>
                                    </div>
                                    </div>
                            
                            <div className="subtitle">Sort by</div>
                            <div className="group-input">
                                <select name="location" id="location-select" onChange={(e)=>this.setState({orderBy: e.target.value})}>
                                    <option value="Location" selected>Location</option>
                                    <option value="Date">Date</option>
                                    <option value="Group">Group</option>
                                </select>
                                <select name="location" id="location-select" style={{width: 100}}  onChange={(e)=>this.setState({orderBy2: e.target.value})}>
                                    <option value="ASC" selected>ASC</option>
                                    <option value="DESC">DESC</option>
                                </select>
                            </div>
                            <div className="btn-container">
                                <button className="principal" onClick={()=>this.setState({selected: 2})}>Generate</button>
                            </div>
                            </React.Fragment> 
            );
        }
        if(this.state.selected === 2){
            return(
                <React.Fragment>
                    <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={()=>this.setState({selected: 1})}><i className="material-icons">arrow_back_ios</i> Back</button>
                            </div>
                            <h3 className="center">OCCUPANCY STATISTICS</h3>
                        </div>   
                            <div className="group-input column">
                            <div className="subtitle">location</div>
                               <div className="item-filtered">{this.state.location}</div>
                            </div>
                            
                            <div className="group-input column">
                            <div className="subtitle">group</div>
                            <div className="item-filtered">{this.state.group}</div>
                            </div>
                            <div className="col-2">
                                <div className="group-input column">

                                <div className="subtitle">start date</div>
                                <div className="item-filtered">{this.state.startDate}</div>
                                </div>
                                <div className="group-input column">
                                <div className="subtitle">end date</div>
                                <div className="item-filtered">{this.state.endDate}</div>
                                </div>
                                    </div>
                            
                            <div className="subtitle">Sort by</div>
                            <div className="group-input">
                                <div className="item-filtered">{this.state.orderBy+", "+this.state.orderBy2}</div>
                            </div>
                            </React.Fragment> 
            );
        }
        
    }

    render(){
        return ( 
            <React.Fragment>
                    <div className="aside-bar report-filter-bar-normal-width">
                    <div className="container">
                        
                        {this.showFilters()}
                        </div>
                    </div>
                    {this.state.selected === 2 &&
                    <div className="content">
                      <div className="content-head">
                        <div className="title">
                          <h1>Occupancy Statistics</h1>
                          <div className="route">Reports &gt; Occupancy Statistics</div>
                        </div></div>
                      <div className="content-body">
                        <div className="flex-7 column left">
                            <div className="card">
                                <div className="card-title">CRUD DATA
                                <button className="green">Export</button>
                                </div>
                                <TableReports
                                    data={[
                                        {
                                            location: 'Location 1',
                                            capacity: 9,
                                            childrenoccupancy: 8,
                                            occupancyporcent: '97.4%',
                                            teacheroccupancy: 7,
                                            efficiencyporcent: '97.4%',
                                        },{
                                            location: 'Location 2',
                                            capacity: 9,
                                            childrenoccupancy: 8,
                                            occupancyporcent: '97.4%',
                                            teacheroccupancy: 7,
                                            efficiencyporcent: '97.4%',
                                        },{
                                            location: 'Location 3',
                                            capacity: 9,
                                            childrenoccupancy: 8,
                                            occupancyporcent: '97.4%',
                                            teacheroccupancy: 7,
                                            efficiencyporcent: '97.4%',
                                        },{
                                            location: 'Location 4',
                                            capacity: 9,
                                            childrenoccupancy: 8,
                                            occupancyporcent: '97.4%',
                                            teacheroccupancy: 7,
                                            efficiencyporcent: '97.4%',
                                        }
                                    ]}
                                    columns={[
                                        {
                                            title: 'Location',
                                            name: 'location'
                                        },
                                        {
                                            title: 'Capacity',
                                            name: 'capacity'
                                        },
                                        {
                                            title: 'Children Occupancy',
                                            name: 'childrenoccupancy'
                                        },
                                        {
                                            title: '% occupancy',
                                            name: 'occupancyporcent'
                                        },
                                        {
                                            title: 'Teacher Occupancy',
                                            name: 'teacheroccupancy'
                                        },
                                        {
                                            title: '% efficiency',
                                            name: 'efficiencyporcent'
                                        }
                                    ]}
                                    buttons_first={true}
                                    edit={false}
                                    delete={this.state.edit || this.state.create}
                                    delete_function=""
                                    edit_function=""
                                />
                            </div>
                        </div>
                        <div className="flex-5 column left">
                            <div className="card">
                                <div className="card-title">OCCUPANCY CHART</div>
                                <OccupancyChart
                                    data = {[
                                        {
                                            id: 1,
                                            name: 'Location 1',
                                            value: 67
                                        },
                                        {
                                            id: 2,
                                            name: 'Location 2',
                                            value: 100
                                        },
                                        {
                                            id: 3,
                                            name: 'Location 3',
                                            value: 115
                                        },
                                        {
                                            id: 4,
                                            name: 'Location 4',
                                            value: 45
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                      </div>
                    </div>
    }
            </React.Fragment> 
        );
    }
}

export default ReportsOccupancy;