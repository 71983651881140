import React, { Component } from 'react';
import SelectorPerson from '../../partials/SelectorPerson';
import TableSelect from '../../partials/TableSelect';
import { notification} from 'antd';
import DocumentsTable from './partials/DocumentsTable';
import TableCardSmall from '../../partials/TableCardSmall';

class AddChild extends Component {
    state = {
        create: true,
        edit: false,
        select: "d0_5",
        data: {
            info: {
                firstName: "Charlie",
                lastName: "Bloom",
                prefName: "Charlie",
                birthday: "2020-11-03",
                importantInfo: "This is a test text",
                allergies: "None",
                medConditions: "None",
                medicines: "None"
            },
            basicrules: {
                d0_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d1: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d1_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d2: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d2_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d3: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d3_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d4: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: 5,
                        tuesday: 4,
                        wednesday: 2,
                        thursday: 5,
                        friday: 4
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d4_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                flexible_hours: "",
                price_calculation: "",
                special_months_ratio: false
            },
            regularkeys: {
                use_regular_keys: true,
                swap_key: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: ''
                },
                extra_key: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: ''
                }
            },
            extendedhours: {
                planned: {
                    am: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    pm: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                incidental: {
                    am: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    pm: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                }
            },
            lifephases: [],
            products: [],
            holidays: {
                recurring: [],
                specific: []
            }
        }
    }
    handleClick = () => {
        document.querySelector('#file-input').click()
    }

    componentDidMount() {
        if (this.props.match.params.type === 'create') {
            this.setState({
                create: true,
                edit: false
            });
        } else {
            if (this.props.match.params.type === 'edit') {
                this.setState({
                    create: false,
                    edit: true
                });
            } else {
                this.setState({
                    create: false,
                    edit: false
                });
            }
        }
    }

    selectDays = (i) => {
        this.setState({
            select: i
        });
    }

    dataBasicRules = (i) => {
        if (i === 'd0_5') {
            return (this.state.data.basicrules.d0_5);
        }
        if (i === 'd1') {
            return (this.state.data.basicrules.d1);
        }
        if (i === 'd1_5') {
            return (this.state.data.basicrules.d1_5);
        }
        if (i === 'd2') {
            return (this.state.data.basicrules.d2);
        }
        if (i === 'd2_5') {
            return (this.state.data.basicrules.d2_5);
        }
        if (i === 'd3') {
            return (this.state.data.basicrules.d3);
        }
        if (i === 'd3_5') {
            return (this.state.data.basicrules.d3_5);
        }
        if (i === 'd4') {
            return (this.state.data.basicrules.d4);
        }
        if (i === 'd4_5') {
            return (this.state.data.basicrules.d4_5);
        }
        if (i === 'd5') {
            return (this.state.data.basicrules.d5);
        }
    }

    openNotification = (placement) => {
        notification.info({
          message: `Notification ${placement}`,
          description: `Hello, !`,
          placement,
        });
      };


    render() {
        return (
            <React.Fragment>
                <div className={this.state.create ? "aside-bar info create" : "aside-bar info"}>
                    <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => window.history.back()}><i className="material-icons">arrow_back_ios</i> Back</button>
                                {!this.state.edit && !this.state.create ? <button className="right" onClick={() => this.setState({ edit: true })}><i className="material-icons">create</i> Edit</button> : ''}
                            </div>
                        </div>
                        <div className="img-container">
                            <div className="circle">
                                {this.state.create ? <i className="material-icons">person</i> : <img src="/assets/child_1.png" alt="" />}
                                {this.state.create ? <button><i className="material-icons">add</i></button> : this.state.edit ? <button><i className="material-icons">edit</i></button> : ''}
                            </div>
                        </div>
                        <div className="subtitle">INFO</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">First Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.firstName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Last Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.lastName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Pref Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.prefName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Gender</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Male</option>
                                    <option value="1">Female</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Birthday</label>
                                <input type="date" name="" defaultValue={!this.state.create ? this.state.data.info.birthday : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Status</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Active</option>
                                    <option value="1">Non-active</option>
                                </select>
                            </div>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Important information</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.importantInfo : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>

                        <div className="subtitle">CARETAKERS</div>
                        <div className="group-input">
                            <label htmlFor="">Search caretaker and select:</label>
                            <SelectorPerson 
                                            data={this.state.create ? [] :[{
                                                id: 1,
                                                name: 'Brandon Bloom',
                                                age: 23,
                                                group: 'Name group 1'
                                            },
                                            {
                                                id: 2,
                                                name: 'Susan Bloom',
                                                age: 23,
                                                group: 'Name group 1'
                                            }]}
                                            columns={[
                                                {
                                                    title: 'Name',
                                                    name: 'name'
                                                }
                                            ]}
                                            parents={true}
                                            header = {false}
                                            remove={this.state.edit || this.state.create}
                                            view={true}
                                            onlyView = {this.state.edit || this.state.create}
                                            delete={this.state.edit || this.state.create}
                                            delete_function=""
                                            edit_function=""
                                        />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Primary Residence</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>...</option>
                            </select>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Primary Contact</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>...</option>
                            </select>
                        </div>

                        <div className="subtitle">HEALTH</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Allergies</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.allergies : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Med Conditions</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.medConditions : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Medicines/Schedules</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.medicines : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className={this.state.edit || this.state.create ? "btn-container" : "btn-container dissapear"}>
                                <button className="principal">Save</button>
                            </div>
                        
                    </div>
                </div>


                <div className={this.state.create ? "content create" : "content"}>
                    <div className="content-head">
                        <div className="title">
                            <h1>{this.state.create ? "Add Child" : this.state.data.info.firstName+" "+this.state.data.info.lastName}</h1>
                            <div className="route">Children &gt; Children Overview &gt; {this.state.create ? "Add Child" : this.state.data.info.firstName+" "+this.state.data.info.lastName}</div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="flex-5 column left">
                            <div className="card">
                                <div className="card-title">PLANNING</div>
                                <div className="flex">
                                    <div className="flex-6 column left">
                                        <div className="check-week">
                                            <table>
                                                <tr>
                                                    <td className="principal">Mon</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Tue</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Wed</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Thu</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Fri</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Sat</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Sun</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="subtitle">Dates</div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Start:</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">End:</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Deadline</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>
                                    </div>
                                    <div className="flex-6 column right">
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Location</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Group</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Status</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Contract</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input column">
                                            <label htmlFor="">Signing date</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                    <button>Save</button>
                                </div>
                                <TableSelect 
                                    data = {this.state.create ? [] :[
                                        {
                                            id: 1,
                                            start: '01/01/2021',
                                            end: '01/06/2021',
                                            location: 'Brisbane',
                                            product: 'Kindergarden'
                                        },
                                        {
                                            id: 2,
                                            start: '01/01/2021',
                                            end: '01/06/2021',
                                            location: 'Brisbane',
                                            product: 'Kindergarden'
                                        }
                                    ]}
                                    columns = {[
                                        {
                                            title: 'Start',
                                            name: 'start'
                                        },
                                        {
                                            title: 'End',
                                            name: 'end'
                                        },
                                        {
                                            title: 'Location',
                                            name: 'location'
                                        },
                                        {
                                            title: 'Product',
                                            name: 'product'
                                        }
                                    ]}
                                    change = {this.state.edit || this.state.create}
                                    delete = {this.state.edit || this.state.create}
                                    details = {this.state.edit || this.state.create}
                                    delete_function = ""
                                    edit_function = ""
                                    view_function = ""
                                />
                                
                            </div>
                            <div className="card">
                                <div className="card-title">DOCUMENTS</div>
                                <div className="subtitle">Add document</div>
                                        <div className="group-input" >
                                            <label htmlFor="">Name</label>
                                            <input type="text" name="" defaultValue="" id="name" />
                                        </div>
                                        <div className="group-input" >
                                            <label htmlFor="">Additional info</label>
                                            <input type="text" name="" defaultValue="" id="name" />
                                        </div>
                                        <div className="group-input checkbox">
                                <input type="checkbox" name="" id="remember_login" />
                                <label htmlFor="remember_login">Visible for families</label>
                            </div>
                                        <div className="group-input" >
                                        <div className="group-input">
                                                <input className="file-input-hidden" type="file" name="" id="file-input" />
                                                <button onClick={this.handleClick} className="file-input-button-children"><i className="material-icons">attach_file</i>Attach document</button>
                                            </div>
                                            <div>
                                            <button className="add"><i className="material-icons">add</i></button>
                                            </div>
                                        </div>
                                <DocumentsTable
                                        data = {[
                                            {
                                                name: 'Contract No. 12342',
                                                aditionalInfo: 'This is a test text for documents table'
                                            },
                                            {
                                                name: 'Contract No. 12342',
                                                aditionalInfo: 'This is a test text for documents table'
                                            },
                                        ]}
                                        columns = {[
                                            {
                                                title: "Name",
                                                name: "name",
                                                bool: false
                                            },
                                            {
                                                title: "Additional info",
                                                name: "aditionalInfo",
                                                bool: false
                                            }
                                        ]}
                                        change = {this.state.edit || this.state.create}
                                        download = {this.state.edit || this.state.create}
                                        delete_function = ""
                                        edit_function = ""
                                        view_function = ""
                                    />
                            </div>
                        </div>
                        <div className="flex-7 column right">
                            <div className="card">
                                <div className="card-title">WAITING LIST</div>
                                <div className="flex">

                                <div className="flex-4 column left">
                                        <div className="check-week">
                                            <table>
                                                <tr>
                                                    <td className="principal">Mon</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Tue</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Wed</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Thu</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Fri</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Sat</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Sun</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="flex-4 column">
                                        <div className="group-input column">
                                            <label htmlFor="">Priority</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input column">
                                            <label htmlFor="">Location</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input column">
                                            <label htmlFor="">Preferred start</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name"/>
                                        </div>
                                    </div>
                                    <div className="flex-4 column right">
                                        <div className="group-input column">
                                            <label htmlFor="">Priority</label>
                                            <textarea name="" id=""  rows="4"></textarea>
                                        </div>
                                        
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <TableSelect 
                                    data = {this.state.create ? [] :[
                                        {
                                            id: 1,
                                            preferredstart: '01/01/2021',
                                            priority: 'Principal',
                                            location: 'Brisbane',
                                            monday: 'AM PM',
                                            tuesday: 'AM',
                                            wednesday: 'AM',
                                            thursday: 'AM',
                                            friday: 'AM'
                                        }
                                    ]}
                                    columns = {[
                                        {
                                            title: 'Preferred start',
                                            name: 'preferredstart'
                                        },
                                        {
                                            title: 'Location',
                                            name: 'location'
                                        },
                                        {
                                            title: 'Priority',
                                            name: 'priority'
                                        },
                                        {
                                            title: 'Mon',
                                            name: 'monday'
                                        },
                                        {
                                            title: 'Tue',
                                            name: 'tuesday'
                                        },
                                        {
                                            title: 'Wed',
                                            name: 'wednesday'
                                        },
                                        {
                                            title: 'Thu',
                                            name: 'thursday'
                                        },
                                        {
                                            title: 'Fri',
                                            name: 'friday'
                                        }
                                    ]}
                                    change = {this.state.edit || this.state.create}
                                    delete = {this.state.edit || this.state.create}
                                    details = {this.state.edit || this.state.create}
                                    delete_function = ""
                                    edit_function = ""
                                    view_function = ""
                                />
                            </div>
                            <div className="card">
                                <div className="card-title">
                                    CONTRACTS 
                                    {(this.state.edit || this.state.create) && <button className="primary">New Contract</button>}
                                        
                                </div>
                                <TableSelect 
                                    data = {this.state.create ? [] : [
                                        {
                                            id: 1,
                                            start: '01/01/2021',
                                            end: '01/06/2021',
                                            location: 'Brisbane',
                                            product: 'Kindergarden',
                                            paymentby: 'Susan Bloom',
                                            paymentsort: 'Automatic debit'
                                        },
                                        {
                                            id: 2,
                                            start: '01/01/2021',
                                            end: '01/06/2021',
                                            location: 'Brisbane',
                                            product: 'Kindergarden',
                                            paymentby: 'Susan Bloom',
                                            paymentsort: 'Automatic debit'
                                        }
                                    ]}
                                    columns = {[
                                        {
                                            title: 'Start',
                                            name: 'start'
                                        },
                                        {
                                            title: 'End',
                                            name: 'end'
                                        },
                                        {
                                            title: 'Location',
                                            name: 'location'
                                        },
                                        {
                                            title: 'Product',
                                            name: 'product'
                                        },
                                        {
                                            title: 'Payment by',
                                            name: 'paymentby'
                                        },
                                        {
                                            title: 'Payment sort',
                                            name: 'paymentsort'
                                        }
                                    ]}
                                    change = {this.state.edit || this.state.create}
                                    delete = {this.state.edit || this.state.create}
                                    details = {this.state.edit || this.state.create}
                                    delete_function = ""
                                    edit_function = ""
                                    view_function = ""
                                />
                            </div>
                            <div className="flex">
                                <div className="flex-6 left">
                                <div className="card">
                                        <div className="card-title">SETTLING IN</div>
                                        <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                            <label htmlFor="">Date</label>
                                            <input type="date" name="" defaultValue="Susan Doe" id="text" />
                                        </div>
                                        <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                            <label htmlFor="">Time</label>
                                            <input type="time" name="" defaultValue="Susan Doe" id="text" />
                                            <div>
                                            <button className="add"><i className="material-icons">add</i></button>
                                        </div>
                                        </div>
                                        <TableCardSmall
                                            data={[
                                                {
                                                    date: '01-02-2021',
                                                    time: '12:30'
                                                },
                                                {
                                                    date: '02-02-2021',
                                                    time: '12:30'
                                                }
                                            ]}
                                            columns={[
                                                {
                                                    title: 'Date',
                                                    name: 'date'
                                                },
                                                {
                                                    title: 'Time',
                                                    name: 'time'
                                                }
                                            ]}
                                            buttons_first={true}
                                            edit={false}
                                            delete={this.state.edit || this.state.create}
                                            delete_function=""
                                            edit_function=""
                                        />
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-6 right">
                                <div className="card">
                                        <div className="card-title">EXTRA</div>
                                        <div className="group-input checkbox">
                                <input type="checkbox" name="" id="remember_login" />
                                <label htmlFor="remember_login">Changes to SIDS</label>
                            </div>
                            <div className="group-input column">
                                <label htmlFor="">Comment</label>
                                <textarea name="" id="" cols="30" rows="3"></textarea>
                            </div>

                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AddChild;