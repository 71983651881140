import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export class Control extends Component {
    render() {
        return (
            <React.Fragment>
                <Redirect to="/control/complete" />
            </React.Fragment>
        );
    }
}