import React, { Component } from "react";
import { Calendar } from "../../partials/Calendar";

export class LunchMenu extends Component {


    state = {
        display_asidebar: false,
        asiderbar_details: false,
        segments: [
            {
                name: 'am',
                title: 'AM'
            },
            {
                name: 'pm',
                title: 'PM'
            }
        ],
        week: [
            {
                id: 1,
                title: 'MON',
                name: 'monday',
                day: 20201228
            },
            {
                id: 2,
                title: 'TUE',
                name: 'tuesday',
                day: 20201228
            },
            {
                id: 3,
                title: 'WED',
                name: 'wednesday',
                day: 20201228
            },
            {
                id: 4,
                title: 'THU',
                name: 'thursday',
                day: 20201228
            },
            {
                id: 5,
                title: 'FRI',
                name: 'friday',
                day: 20201228
            },
            {
                id: 6,
                title: 'SAT',
                name: 'saturday',
                day: 20201228
            },
            {
                id: 7,
                title: 'SUN',
                name: 'sunday',
                day: 20201228
            },
        ],
        data: {
            displayId: 3,
            monday: {
                am: [
                    {

                        id: 5,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 2,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 3,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ],
            },
            tuesday: {
                am: [
                    {
                        id: 5,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 2,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 3,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ],
            },
            wednesday: {
                am: [
                    {
                        id: 5,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 2,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 3,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ],
            },
            thursday: {
                am: [
                    {
                        id: 5,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 2,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 3,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ]
            },
            friday: {
                am: [
                    {
                        id: 5,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 2,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    },
                    {
                        id: 3,
                        time: '09:30 AM',
                        title: 'Chicken and vegetables',
                        description: 'Chicken with vegetables aside and rice',
                        group: 'Group 1'
                    }
                ]
            },
            saturday: {
                am: [],
                pm: []
            },
            sunday: {
                am: [],
                pm: []
            },
        },
    }

    activities = [
        {
            id: 5,
            time: '10:30am-11:30am',
            title: `Chicken and vegetables`,
            place: 'Place name',
            group: 'Group 1',
            from: '2020/10/19',
            to: '2020/11/25',
            repeating: 'Every monday',
            content: `Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed
            eiusmod tempor.`
        },
        {
            id: 2,
            time: '10:30am-11:30am',
            title: `Chicken and vegetables`,
            place: 'Place name',
            group: 'Group 1',
            from: '2020/10/19',
            to: '2020/11/25',
            repeating: 'Every monday',
            content: `Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed
            eiusmod tempor.`
        },
        {
            id: 6,
            time: '10:30am-11:30am',
            title: `Chicken and vegetables`,
            place: 'Place name',
            group: 'Group 1',
            from: '2020/10/19',
            to: '2020/11/25',
            repeating: 'Every monday',
            content: `Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed
            eiusmod tempor.`
        },
        {
            id: 3,
            time: '10:30am-11:30am',
            title: `Chicken and vegetables`,
            place: 'Place name',
            group: 'Group 1',
            from: '2020/10/19',
            to: '2020/11/25',
            repeating: 'Every monday',
            content: `Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed
            eiusmod tempor.`
        }
    ]

    showDetails = (id) => {
        console.log(id);
        this.setState({
            asiderbar_details: true,
            activity: this.activities.find(activity => activity.id === id)
        })
    }

    displayAsidebar = () => {
        this.setState({
            display_asidebar: true
        })
    }

    showAsideBar = () => {
        return this.state.asiderbar_details ?
            <div className="aside-bar popup active info">
                <div className="container aside-bar-info-active">
                    <div className="header">
                        <div className="btn-container">
                            <button className="left" onClick={() => this.setState({ asiderbar_details: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                        </div>
                    </div>

                    <div className="activity-info-panel">
                        <h3>{this.state.activity.time}</h3>
                        <h2>{this.state.activity.title}</h2>
                    </div>

                    <div className="col-2">
                        <div className="group-input">
                            <label htmlFor="">Location</label>
                            <input type="text" name="" value={this.state.activity.place} disabled id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Group</label>
                            <input type="text" value={this.state.activity.group} disabled id="text" />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="group-input">
                            <label htmlFor="">From</label>
                            <input type="text" value={this.state.activity.from} disabled id="text" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">To</label>
                            <input type="text" value={this.state.activity.to} disabled id="text" />
                        </div>
                    </div>

                    <div className="group-input">
                        <label htmlFor="">Repeating</label>
                        <input type="text" value={this.state.activity.repeating} disabled id="text" />
                    </div>

                    <div className="group-input">
                        <label htmlFor="">Content</label>
                        <input type="text" value={this.state.activity.content} disabled id="text" />
                    </div>

                </div>
            </div> :

            <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                <div className="container">
                    <div className="header">
                        <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                        <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                    </div>
                    <div className="filters">
                        <div className="group-input">
                            <label htmlFor="">LOCATION</label>
                            <div className="group-radio">
                                <input type="radio" name="city" id="city1" value="1" />
                                <label htmlFor="city1">Location's name 1</label>
                            </div>
                            <div className="group-radio">
                                <input type="radio" name="city" id="city2" value="2" />
                                <label htmlFor="city2">Location's name 2</label>
                            </div>
                        </div>
                        <div className="btn-container">
                            <button className="principal">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
    }

    render() {
        return <React.Fragment>
            {this.showAsideBar()}
            <div className="content">
                <Calendar
                    title="Lunch Menu"
                    subtitle="Family Panel > Lunch Menu"
                    data={this.state.data}
                    week={this.state.week}
                    segments={this.state.segments}
                    view="week"
                    showDetails={this.showDetails}
                    displayAsidebar={this.displayAsidebar}
                />
            </div>
        </React.Fragment>
    }
}