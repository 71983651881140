import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class Invoices extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false,
        asiderbar_create: false,
        data: {
            id: 123456,
            paymentby: 'Charlie Bloom',
            refNumber: '234556',
            year: 2020,
            period: 12,
            sendDate: '05/01/2020',
            amount: '78.90',
            paymentDue: 'None',
            status: 'None',
            payment: 'None'
        }
    }

    viewInvoice = (id) => {
        this.setState({
            asiderbar_create: true,
            display_asidebar: true
        })
    }

    showAsideBar = () => {
        if(this.state.asiderbar_create){
            return(
                <div className={this.state.display_asidebar ? "aside-bar popup info active" : "aside-bar info popup"}>
                <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={()=>this.setState({asiderbar_create: false})}><i className="material-icons">arrow_back_ios</i> Back</button>
                                <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                            </div>
                            <h3 className="center">INVOICE #{this.state.data.id}</h3>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">Payment by</label>
                                <input type="text" name="" id="" value={this.state.data.paymentby}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Ref. number</label>
                                <input type="text" name="" id="" value={this.state.data.refNumber}/>
                            </div>
                            <div className="col-2">
                                <div className="group-input">
                                    <label htmlFor="">Year</label>
                                    <input type="text" name="" id="" value={this.state.data.year}/>
                                </div>
                                <div className="group-input">
                                    <label htmlFor="">Period</label>
                                    <input type="text" name="" id="" value={this.state.data.period}/>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Send date</label>
                                <input type="text" name="" id="" value={this.state.data.sendDate}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Amount</label>
                                <input type="text" name="" id="" value={"$"+this.state.data.amount}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Payment due</label>
                                <input type="text" name="" id="" value={this.state.data.paymentDue}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Status</label>
                                <input type="text" name="" id="" value={this.state.data.status}/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Payment</label>
                                <input type="text" name="" id="" value={this.state.data.payment}/>
                            </div>
                            <div className="safe-zone">
                                <div className="group-input">
                                    <label htmlFor="">Status</label>
                                    <div className="col-2">
                                    <select name="" id="" className="safe-zone">
                                        <option value="">Payed</option>
                                        <option value="">Payed</option>
                                    </select>
                                    <button className="green">Change</button>
                                    </div>
                                </div>
                                <div className="group-input">
                                    <label htmlFor="">Additional info</label>
                                    <div className="col-2">
                                    <input type="text" name="" id="" className="safe-zone"/>
                                    <button className="green">Change</button>
                                    </div>
                                </div>
                            </div>

                        
                        </div>
                        </div>
                        </div>
            );
        }else{
            return(
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i class="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">NAME</label>
                                <input type="text" name="" id=""/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">CONTRACTED LOCATION</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LINKED CHILDREN</label>
                                <input type="text" name="" id=""/>
                            </div>
                            
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                        </div>
                    </div>
            );
        }
    }

    render(){
        return ( 
            <React.Fragment>
                    {this.showAsideBar()}
                    <div className="content">
                      <div className="content-head">
                        <div className="title">
                          <h1>Invoices</h1>
                          <div className="route">Caretakers &gt; Invoices</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({display_asidebar: true})}><i class="material-icons edit">filter_alt</i></button>
                        
                      </div>
                      <Table 
                        data = {[
                            {
                                id: 1,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 2,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 3,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 4,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 5,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 6,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 7,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 8,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 1,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 2,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 3,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 4,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 5,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 6,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 7,
                                paymentby: 'Susan Bloom',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            },
                            {
                                id: 8,
                                paymentby: 'Charlie Doe',
                                location: "Brisbane",
                                year: 2020,
                                period: 1,
                                status: "None",
                                paymentdue: "None",
                                senddate: '01/01/2021',
                                amount: '75.50'
                            }
                        ]}
                        columns = {[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Payment by",
                                name: "paymentby",
                                bool: false
                            },
                            {
                                title: "Location",
                                name: "location",
                                bool: false
                            },
                            {
                                title: "Year",
                                name: "year",
                                bool: false
                            },
                            {
                                title: "Period",
                                name: "period",
                                bool: false
                            },
                            {
                                title: "Send date",
                                name: "senddate",
                                bool: false
                            },
                            {
                                title: "Amount",
                                name: "amount",
                                bool: false
                            },
                            {
                                title: "Payment due",
                                name: "paymentdue",
                                bool: false
                            },
                            {
                                title: "Status",
                                name: "status",
                                bool: false
                            }
                        ]}
                        viewFunction = {this.viewInvoice}
                        view = {true}
                        edit = {false}
                        download = {true}
                        delete = {false}
                        editLink = ""
                        deleteFunction = ""
                        totalElements = "47"
                      />
                      <Pagination 
                        page = {this.state.page}
                        per_page = {this.state.per_page}
                        total_elements = {this.state.total_elements}
                        total_pages = {this.state.total_pages}
                        change_page = {page => this.setState({page})}
                        />
                    </div>
            </React.Fragment> 
        );
    }
}

export default Invoices;