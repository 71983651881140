import React, { Component } from 'react';
import SelectorPerson from '../../partials/SelectorPerson';
import TableSelect from '../../partials/TableSelect';
import InvoiceTableSelect from './partials/InvoiceTableSelect';


class AddCaretaker extends Component {
    state = {
        create: true,
        edit: false,
        select: "d0_5",
        data: {
            info: {
                initials: 'SB',
                lastName: 'Bloom',
                prefName: 'Susan',
                salutation: 'Ms',
                phone: '(12) 3456 7890',
                email: 'test@wondergarden.com',
                postal: '8041',
                city: 'Sydney',
                street: 'Name street 1',
                houseNumber: '145',
                bsb: 'Name bank 2',
                accountNo: '0000 0000 0000 0000'
            },
            invoices: [
                {
                    id: 123456,
                    paymentby: 'Charlie Bloom',
                    refNumber: 234556,
                    year: 2020,
                    period: 12,
                    sendDate: '05/01/2020',
                    amount: 78.90,
                    paymentDue: 'None',
                    status: 'None',
                    payment: 'None'
                },
                {
                    id: 123457,
                    paymentby: 'Charlie Bloom',
                    refNumber: 234556,
                    year: 2020,
                    period: 12,
                    sendDate: '05/01/2020',
                    amount: 78.90,
                    paymentDue: 'None',
                    status: 'None',
                    payment: 'None'
                },
            ],
            contracts: [
                {
                    id: 1,
                    start: '01/01/2021',
                    end: '01/06/2021',
                    location: 'Brisbane',
                    product: 'Kindergarden',
                    paymentby: 'Susan Bloom',
                    paymentsort: 'Automatic debit'
                },
                {
                    id: 2,
                    start: '01/01/2021',
                    end: '01/06/2021',
                    location: 'Brisbane',
                    product: 'Kindergarden',
                    paymentby: 'Susan Bloom',
                    paymentsort: 'Automatic debit'
                }
            ],
            children: [{
                id: 1,
                name: 'Brandon Bloom',
                age: 23,
                group: 'Name group 1'
            },
            {
                id: 2,
                name: 'Susan Bloom',
                age: 23,
                group: 'Name group 1'
            }],
            panelAccess: {
                newPassword: 'holaaa',
                email: ''
            }
        }
    }

    componentDidMount() {
        if (this.props.match.params.type === 'create') {
            this.setState({
                create: true,
                edit: false
            });
        } else {
            if (this.props.match.params.type === 'edit') {
                this.setState({
                    create: false,
                    edit: true
                });
            } else {
                this.setState({
                    create: false,
                    edit: false
                });
            }

        }
    }

    selectDays = (i) => {
        this.setState({
            select: i
        });
    }

    dataBasicRules = (i) => {
        if (i === 'd0_5') {
            return (this.state.data.basicrules.d0_5);
        }
        if (i === 'd1') {
            return (this.state.data.basicrules.d1);
        }
        if (i === 'd1_5') {
            return (this.state.data.basicrules.d1_5);
        }
        if (i === 'd2') {
            return (this.state.data.basicrules.d2);
        }
        if (i === 'd2_5') {
            return (this.state.data.basicrules.d2_5);
        }
        if (i === 'd3') {
            return (this.state.data.basicrules.d3);
        }
        if (i === 'd3_5') {
            return (this.state.data.basicrules.d3_5);
        }
        if (i === 'd4') {
            return (this.state.data.basicrules.d4);
        }
        if (i === 'd4_5') {
            return (this.state.data.basicrules.d4_5);
        }
        if (i === 'd5') {
            return (this.state.data.basicrules.d5);
        }
    }

    render() {
        console.log(this.state.create)
        return (
            <React.Fragment>
                <div className={this.state.create ? "aside-bar info create" : "aside-bar info"}>
                    <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => window.history.back()}><i className="material-icons">arrow_back_ios</i> Back</button>
                                {!this.state.edit && !this.state.create ? <button className="right" onClick={() => this.setState({ edit: true })}><i className="material-icons">create</i> Edit</button> : ''}
                            </div>
                        </div>
                        <div className="img-container">
                            <div className="circle">
                                {this.state.create ? <i className="material-icons">person</i> : <img src="/assets/caretaker_2.png" alt="" />}
                                {this.state.create ? <button><i className="material-icons">add</i></button> : this.state.edit ? <button><i className="material-icons">edit</i></button> : ''}
                            </div>
                        </div>
                        <div className="subtitle">INFO</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Initials</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.initials: ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Last Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.lastName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Pref Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.prefName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Salutation</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.salutation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Phone</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.phone : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Email</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.email : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>

                        <div className="subtitle">ADDRESS</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Postal</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.postal : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">City</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.city : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Street</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.street : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">House no.</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.houseNumber : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                        </div>

                        <div className="subtitle">ADDRESS</div>
                        <div className="group-input">
                            <label htmlFor="">BSB</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.bsb : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Acount No.</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.accountNo : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                        </div>


                        <div className={this.state.edit || this.state.create ? "btn-container" : "btn-container dissapear"}>
                            <button className="principal">Save</button>
                        </div>
                    </div>
                </div>


                <div className={this.state.create ? "content create" : "content"}>
                    <div className="content-head">
                        <div className="title">
                            <h1>{this.state.create ? "Add Caretaker" : this.state.data.info.prefName + " " + this.state.data.info.lastName}</h1>
                            <div className="route">Caretakers &gt; Caretaker Overview &gt; {this.state.create ? "Add Caretaker" : this.state.data.info.prefName + " " + this.state.data.info.lastName}</div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="flex column">
                            <div className="flex-12 column">
                                <div className="card">
                                    <div className="card-title">INVOICES</div>
                                    <InvoiceTableSelect 
                                        data = {this.state.create ? [] : this.state.data.invoices}
                                        columns = {[
                                            {
                                                title: "ID",
                                                name: "id",
                                                bool: false
                                            },
                                            {
                                                title: "Payment by",
                                                name: "paymentby",
                                                bool: false
                                            },
                                            {
                                                title: "Ref. number",
                                                name: "refNumber",
                                                bool: false
                                            },
                                            {
                                                title: "Year",
                                                name: "year",
                                                bool: false
                                            },
                                            {
                                                title: "Period",
                                                name: "period",
                                                bool: false
                                            },
                                            {
                                                title: "Send date",
                                                name: "sendDate",
                                                bool: false
                                            },
                                            {
                                                title: "Amount",
                                                name: "amount",
                                                bool: false
                                            },
                                            {
                                                title: "Payment due",
                                                name: "paymentDue",
                                                bool: false
                                            },
                                            {
                                                title: "Status",
                                                name: "status",
                                                bool: false
                                            },
                                            {
                                                title: "Payment",
                                                name: "payment",
                                                bool: false
                                            }
                                        ]}
                                        change = {this.state.edit || this.state.create}
                                        download = {this.state.edit || this.state.create}
                                        delete_function = ""
                                        edit_function = ""
                                        view_function = ""
                                    />
                                </div>

                            </div>
                            <div className="flex">
                                <div className="flex-4 left column">
                                    <div className="card">
                                        <div className="card-title">LINKED CHILDREN</div>
                                        <SelectorPerson 
                                            data={this.state.create ? [] :this.state.data.children}
                                            columns={[
                                                {
                                                    title: 'Name',
                                                    name: 'name'
                                                },
                                                {
                                                    title: 'Age',
                                                    name: 'age'
                                                },
                                                {
                                                    title: 'Group',
                                                    name: 'group'
                                                }
                                            ]}
                                            parents={false}
                                            header = {true}
                                            remove={this.state.edit || this.state.create}
                                            view={true}
                                            onlyView = {this.state.edit || this.state.create}
                                            delete={this.state.edit || this.state.create}
                                            delete_function=""
                                            edit_function=""
                                        />
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-title">FAMILY PANEL ACCESS</div>
                                        {this.state.create ? 
                                        <React.Fragment>
                                            <div className="group-input">
                                                <label htmlFor="">Email</label>
                                                <input type="email" name="" defaultValue={!this.state.create ? this.state.data.info.email : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                                            </div>
                                            <div className="group-input">
                                                <label htmlFor="">Password</label>
                                                <input type="password" name="" defaultValue={!this.state.create ? this.state.data.panelAccess.newPassword : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="group-input no-padding-bottom">
                                                <label htmlFor="">Email</label>
                                                <input type="email" name="" defaultValue={!this.state.create ? this.state.data.info.email : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                                            </div>
                                            <div className="group-input no-padding-bottom">
                                                <label htmlFor="">New password</label>
                                                <input type="password" name="" defaultValue={!this.state.create ? this.state.data.panelAccess.newPassword : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                                            </div>
                                        </React.Fragment>
                                    }
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-8 right">
                                <div className="card">
                                    <div className="card-title">CONTRACTS
                                    <button className={this.state.edit || this.state.create ? "primary" : "primary dissapear"}>New Contract</button>
                                    </div>
                                    <TableSelect 
                                    data = {this.state.create ? [] :this.state.data.contracts}
                                    columns = {[
                                        {
                                            title: 'Start',
                                            name: 'start'
                                        },
                                        {
                                            title: 'End',
                                            name: 'end'
                                        },
                                        {
                                            title: 'Location',
                                            name: 'location'
                                        },
                                        {
                                            title: 'Product',
                                            name: 'product'
                                        },
                                        {
                                            title: 'Payment by',
                                            name: 'paymentby'
                                        },
                                        {
                                            title: 'Payment sort',
                                            name: 'paymentsort'
                                        }
                                    ]}
                                    change = {this.state.edit || this.state.create}
                                    delete = {this.state.edit || this.state.create}
                                    details = {this.state.edit || this.state.create}
                                    delete_function = ""
                                    edit_function = ""
                                    view_function = ""
                                />
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AddCaretaker;