import { Component } from "react";
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

function getWeekDays(weekStart) {
    const days = [];
    for (let i = 1; i <= 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('week')
            .toDate(),
        to: moment(date)
            .endOf('week')
            .toDate(),
    };
}

export class WeekPicker extends Component {
    state = {
        hoverRange: undefined,
        selectedDays: [],
        currentWeek: []
    };

    handleDayChange = date => {
        this.setState({
            selectedDays: getWeekDays(getWeekRange(date).from),
        });
        let days = getWeekDays(getWeekRange(date).from)
        this.setState({
            currentWeek: days
        })
    };

    handleDayEnter = date => {
        this.setState({
            hoverRange: getWeekRange(date),
        });
    };

    handleDayLeave = () => {
        this.setState({
            hoverRange: undefined,
        });
    };

    handleWeekClick = (weekNumber, days) => {

        this.setState({
            selectedDays: days,
        });
    };

    render() {
        const { selectedDays } = this.state;
        const currentMonth = `${this.props.month}, ${this.props.year}`
        return (
            <div className="week-picker">
                <DayPicker
                    firstDayOfWeek={1}
                    month={new Date(currentMonth)}
                    selectedDays={selectedDays}

                    onDayClick={this.handleDayChange}
                    onDayMouseEnter={this.handleDayEnter}
                    onDayMouseLeave={this.handleDayLeave}
                    onWeekClick={this.handleWeekClick}
                />
                <div className="apply">
                    <button onClick={() => { this.props.apply(this.state.currentWeek) }}>Apply</button>
                </div>
            </div>
        );
    }
}