import React, { Component } from 'react';
import {
    Redirect
  } from "react-router-dom";

class Reports extends Component {


    render(){
        return ( 
            <React.Fragment>
                <Redirect to="/reports/children" />
            </React.Fragment> 
        );
    }
}

export default Reports;