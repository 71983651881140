import React, { Component } from 'react';
import TableCardSmall from '../../partials/TableCardSmall';
import TableCardLarge from '../../partials/TableCardLarge';
import CustomizeSelect from './partials/CustomizeSelect';
import CustomizeInputTable from './partials/CustomizeInputTable';


class CreateProduct extends Component {
    state = {
        create: false,
        edit: false,
        select: "d0_5",
        data: {
            info: {
                name: "",
                location: "",
                flexspaces: "",
                status: ""
            },
            basicrules: {
                d0_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d1: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d1_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d2: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d2_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d3: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d3_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d4: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: 5,
                        tuesday: 4,
                        wednesday: 2,
                        thursday: 5,
                        friday: 4
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d4_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                flexible_hours: "",
                price_calculation: "",
                special_months_ratio: false
            },
            regularkeys: {
                use_regular_keys: true,
                swap_key: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: ''
                },
                extra_key: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: ''
                }
            },
            extendedhours: {
                planned: {
                    am: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    pm: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                incidental: {
                    am: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    pm: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                }
            },
            lifephases: [],
            products: [],
            holidays: {
                recurring: [],
                specific: []
            }
        }
    }

    componentDidMount() {
        if (this.props.match.params.type === 'create') {
            this.setState({
                create: true,
                edit: false
            });
        } else {
            if (this.props.match.params.type === 'edit') {
                this.setState({
                    create: false,
                    edit: true
                });
            } else {
                this.setState({
                    create: false,
                    edit: false
                });
            }
            this.setState({
                data: {
                    info: {
                        name: "Summer camp",
                        location: "Brisbane",
                        flexspaces: false,
                        status: true
                    },
                    basicrules: {
                        d0_5: {
                            p1: {
                                monday: 1,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d1: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d1_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d2: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d2_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d3: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d3_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d4: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d4_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        d5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4,
                                saturday: 5,
                                sunday: 4
                            }
                        },
                        flexible_hours: 12,
                        price_calculation: 5,
                        special_months_ratio: false
                    },
                    regularkeys: {
                        use_regular_keys: true,
                        swap_key: {
                            monday: 5,
                            tuesday: 4,
                            wednesday: 2,
                            thursday: 5,
                            friday: 4
                        },
                        extra_key: {
                            monday: 5,
                            tuesday: 4,
                            wednesday: 2,
                            thursday: 5,
                            friday: 4
                        }
                    },
                    extendedhours: {
                        planned: {
                            am: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            pm: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        incidental: {
                            am: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            pm: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        }
                    }
                }
            })
        }
    }

    selectDays = (i) => {
        this.setState({
            select: i
        });
    }

    dataBasicRules = (i) => {
        if (i === 'd0_5') {
            return (this.state.data.basicrules.d0_5);
        }
        if (i === 'd1') {
            return (this.state.data.basicrules.d1);
        }
        if (i === 'd1_5') {
            return (this.state.data.basicrules.d1_5);
        }
        if (i === 'd2') {
            return (this.state.data.basicrules.d2);
        }
        if (i === 'd2_5') {
            return (this.state.data.basicrules.d2_5);
        }
        if (i === 'd3') {
            return (this.state.data.basicrules.d3);
        }
        if (i === 'd3_5') {
            return (this.state.data.basicrules.d3_5);
        }
        if (i === 'd4') {
            return (this.state.data.basicrules.d4);
        }
        if (i === 'd4_5') {
            return (this.state.data.basicrules.d4_5);
        }
        if (i === 'd5') {
            return (this.state.data.basicrules.d5);
        }
    }

    render() {
        console.log(this.state)
        return (
            <React.Fragment>
                <div className={this.state.create ? "aside-bar info create" : "aside-bar info"}>
                    <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => window.history.back()}><i className="material-icons">arrow_back_ios</i> Back</button>
                                {!this.state.edit && !this.state.create ? <button className="right" onClick={() => this.setState({ edit: true })}><i className="material-icons">create</i> Edit</button> : ''}
                            </div>
                            <h3>{this.state.create ? "CREATE" : "INFO"}</h3>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.name : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Location</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.location : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                            <div className="group-input checkbox">
                                <input type="checkbox" checked={!this.state.create ? this.state.data.info.flexspaces : false} disabled={this.state.edit || this.state.create ? false : true} name="" id="remember_login" />
                                <label htmlFor="remember_login">Flexspaces</label>
                            </div>
                            <div className="group-input checkbox">
                                <input type="checkbox" name="" checked={!this.state.create ? this.state.data.info.status : false} disabled={this.state.edit || this.state.create ? false : true} id="remember_login" />
                                <label htmlFor="remember_login">Enable</label>
                            </div>

                            <div className={this.state.edit || this.state.create ? "btn-container" : "btn-container dissapear"}>
                                <button className="principal">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.create ? "content create" : "content"}>
                    <div className="content-head">
                        <div className="title">
                            <h1>{this.state.create ? "Create Product" : this.state.data.info.name}</h1>
                            <div className="route">Locations &gt; Product Management &gt; {this.state.create ? "Create Product" : this.state.data.info.name}</div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="flex-6 column left">
                            <div className="card">
                                <div className="card-title">BASIC RULES</div>
                                <div className="table-container">
                                    <CustomizeSelect
                                        function={this.selectDays}
                                    />
                                    <CustomizeInputTable
                                        data={this.dataBasicRules(this.state.select)}
                                        create={this.state.create}
                                        edit={this.state.edit}
                                    />
                                </div>
                                <div className="group-input">
                                    <label htmlFor="">Number of days the product will be charged</label>
                                    <input type="number" /></div>
                                <div className="group-input column">
                                    <div className="subtitle">Special months</div>
                                    <div className="group-radio">
                                        <input type="radio" name="city" id="city1" value="1" checked={!this.state.create ? this.state.data.basicrules.special_months_ratio : false} disabled={this.state.edit || this.state.create ? false : true} />
                                        <label htmlFor="city1">Price by ratio of days in month</label>
                                    </div>
                                    <div className="group-radio">
                                        <input type="radio" name="city" id="city2" value="2" checked={!this.state.create ? !this.state.data.basicrules.special_months_ratio : false} disabled={this.state.edit || this.state.create ? false : true} />
                                        <label htmlFor="city2">Price by absolute days in month</label>
                                    </div>
                                </div>
                                <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                    <button>Save</button>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-title">EXTENDED HOURS</div>
                                <div className="flex column">
                                    <div className="group-input">
                                        <label htmlFor="">Start charging from:</label>
                                        <input type="number" name="" defaultValue={!this.state.create ? this.state.data.info.location : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                                        <label htmlFor="">minutes</label>
                                    </div>
                                    <div className="flex-12 column left">
                                        <div className="subtitle">Planned extended hours</div>
                                        <div className="table-container">
                                        <CustomizeInputTable
                                            data={this.dataBasicRules(this.state.select)}
                                            create={this.state.create}
                                            edit={this.state.edit}
                                        />
                                        </div>
                                    </div>
                                    <div className="flex-12 column">
                                        <div className="subtitle">Incidental extended hours</div>
                                        <div className="table-container">
                                        <CustomizeInputTable
                                            data={this.dataBasicRules(this.state.select)}
                                            create={this.state.create}
                                            edit={this.state.edit}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                    <button>Save</button>
                                </div>
                            </div>
                        </div>
                        <div className="flex-6 column right">
                            <div className="card">
                                <div className="card-title">SWAP DAY RATES</div>
                                <div className="flex column">
                                <div className="group-input checkbox">
                                    <input type="checkbox" name="" id="remember_login" defaultChecked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true} />
                                    <label htmlFor="remember_login">Use regular rates if applicables (then rate = fee)</label>
                                </div>
                                    <div className="flex-12 column left">
                                        <div className="subtitle">Swap date rate</div>
                                    <div className="table-container">
                                        <CustomizeInputTable
                                            data={this.dataBasicRules(this.state.select)}
                                            create={this.state.create}
                                            edit={this.state.edit}
                                        />
                                        </div>
                                    </div>
                                    <div className="flex-12 column">
                                    <div className="subtitle">Extra date rate</div>
                                    <div className="table-container">
                                        
                                        <CustomizeInputTable
                                            data={this.dataBasicRules(this.state.select)}
                                            create={this.state.create}
                                            edit={this.state.edit}
                                        />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                    <button>Save</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CreateProduct;