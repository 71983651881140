import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class GroupOverview extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false,
        asiderbar_create: false
    }

    showAsideBar = () => {
        if (this.state.asiderbar_create) {
            return (
                <div className="container create">
                    <div className="header">
                        <div className="btn-container">
                            <button className="left" onClick={() => this.setState({ asiderbar_create: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                        </div>
                        <h3 className="center">CREATE GROUP</h3>
                    </div>
                    <div className="filters">
                        <div className="group-input">
                            <label htmlFor="">Name</label>
                            <input type="text" name="" id="" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Location</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>Choose a location...</option>
                                <option value="1">Location's name 1</option>
                                <option value="2">Location's name 2</option>
                            </select>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Composition</label>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city1" value="1" />
                                <label htmlFor="city1">0-18</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city2" value="2" />
                                <label htmlFor="city2">18-24</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city3" value="3" />
                                <label htmlFor="city3">24-72</label>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-6">
                            <div className="group-input">
                                <label htmlFor="">Capacity</label>
                                <input type="number" name="" id="" />
                            </div>
                            </div>
                            <div className="flex-6">
                            <div className="group-input">
                                <label htmlFor="">No. children</label>
                                <input type="number" name="" id="" />
                            </div>
                            </div>
                            <div className="flex-6">
                            <div className="group-input">
                                <label htmlFor="">Flexpaces</label>
                                <input type="text" name="" id="" />
                            </div>
                            </div>
                        </div>
                            
                            
                        <div className="group-input">
                            <div className="subtitle">Daycare hours</div>
                            <table className="input">
                                <tr>
                                    <td></td>
                                    <td>Start</td>
                                    <td>End</td>
                                </tr>
                                <tr>
                                    <td>AM</td>
                                    <td>
                                        <div className="group-input">
                                            <input type="text" name="" id="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="group-input">
                                            <input type="text" name="" id="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>PM</td>
                                    <td>
                                        <div className="group-input">
                                            <input type="text" name="" id="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="group-input">
                                            <input type="text" name="" id="" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Extended Hour Count Per</label>
                            <div className="group-input col-2">
                                <input type="number" name="" id="" />
                                <p>minutes</p>
                            </div>
                        </div>
                        <div className="btn-container">
                            <button className="principal">Create</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container">
                    <div className="header">
                        <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                        <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                    </div>
                    <div className="filters">
                        <div className="group-input">
                            <label htmlFor="">NAME</label>
                            <input type="text" name="" id="" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">LOCATION</label>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city1" value="1" />
                                <label htmlFor="city1">Location's name 1</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city2" value="2" />
                                <label htmlFor="city2">Location's name 2</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city3" value="3" />
                                <label htmlFor="city3">Location's name 3</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city4" value="4" />
                                <label htmlFor="city4">Location's name 4</label>
                            </div>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">COMPOSITION</label>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city1" value="1" />
                                <label htmlFor="city1">0-18</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city2" value="2" />
                                <label htmlFor="city2">18-24</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city3" value="3" />
                                <label htmlFor="city3">24-72</label>
                            </div>
                        </div>
                        <div className="group input col-2">

                        </div>
                        <div className="btn-container">
                            <button className="principal">Apply</button>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    {this.showAsideBar()}
                </div>
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Group Overview</h1>
                            <div className="route">Locations &gt; Group Overview</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({ display_asidebar: true, asiderbar_create: false })}><i className="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <button className="btn principal" onClick={() => this.setState({ display_asidebar: true, asiderbar_create: true })}><i className="material-icons">add</i>Create Group</button>
                        </div>
                    </div>
                    <Table
                        data={[
                            {
                                id: 1,
                                name: "Group's name 1",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 2,
                                name: "Group's name 2",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 3,
                                name: "Group's name 3",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 4,
                                name: "Group's name 4",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 5,
                                name: "Group's name 5",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 6,
                                name: "Group's name 6",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 1,
                                name: "Group's name 1",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 2,
                                name: "Group's name 2",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 3,
                                name: "Group's name 3",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 4,
                                name: "Group's name 4",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 5,
                                name: "Group's name 5",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                            {
                                id: 6,
                                name: "Group's name 6",
                                location: "Brisbane",
                                composition: "0-18,18-24,24-72",
                                capacity: "9/11"
                            },
                        ]}
                        columns={[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Name",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "Location",
                                name: "location",
                                bool: false
                            },
                            {
                                title: "Composition",
                                name: "composition",
                                bool: false
                            },
                            {
                                title: "Capacity",
                                name: "capacity",
                                bool: false
                            }
                        ]}
                        viewFunction=""
                        view={true}
                        edit={true}
                        delete={true}
                        editFunction=""
                        deleteFunction=""
                        totalElements="47"
                    />
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default GroupOverview;