import React, { Component } from 'react';
import TableSelect from '../../partials/TableSelect';


class AddContract extends Component {
    state = {
        create: (this.props.match.params.type === 'create' ? true : false),
        edit: false,
        select: "d0_5",
        data: {
            info: {
                id: 0,
                childName: "Charlie Bloom",
                caretakerName: "Susan Bloom",
                flexspaces: "",
                status: ""
            },
            basicrules: {
                d0_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d1: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d1_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d2: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d2_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d3: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d3_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d4: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: 5,
                        tuesday: 4,
                        wednesday: 2,
                        thursday: 5,
                        friday: 4
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d4_5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                d5: {
                    p1: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p2: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    p3: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                flexible_hours: "",
                price_calculation: "",
                special_months_ratio: false
            },
            regularkeys: {
                use_regular_keys: true,
                swap_key: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: ''
                },
                extra_key: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: ''
                }
            },
            extendedhours: {
                planned: {
                    am: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    pm: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                },
                incidental: {
                    am: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    },
                    pm: {
                        monday: '',
                        tuesday: '',
                        wednesday: '',
                        thursday: '',
                        friday: ''
                    }
                }
            },
            lifephases: [],
            products: [],
            holidays: {
                recurring: [],
                specific: []
            }}
    }

    componentDidMount(){
        
        if(this.props.match.params.type === 'create'){
            this.setState({
                create: true,
                edit: false
            });
        }else{
            if(this.props.match.params.type === 'edit'){
                this.setState({
                    create: false,
                    edit: true
                });
            }else{
                this.setState({
                    create: false,
                    edit: false
                });
                console.log(this.state.create)
            }
            this.setState({
                data: {
                    info: {
                        id: 122344322,
                        name: "Summer camp",
                        location: "Brisbane",
                        flexspaces: false,
                        status: true
                    },
                    basicrules: {
                        d0_5: {
                            p1: {
                                monday: 1,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d1: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d1_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d2: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d2_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d3: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d3_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d4: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d4_5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        d5: {
                            p1: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p2: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            p3: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        flexible_hours: 12,
                        price_calculation: 5,
                        special_months_ratio: false
                    },
                    regularkeys: {
                        use_regular_keys: true,
                        swap_key: {
                            monday: 5,
                            tuesday: 4,
                            wednesday: 2,
                            thursday: 5,
                            friday: 4
                        },
                        extra_key: {
                            monday: 5,
                            tuesday: 4,
                            wednesday: 2,
                            thursday: 5,
                            friday: 4
                        }
                    },
                    extendedhours: {
                        planned: {
                            am: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            pm: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        },
                        incidental: {
                            am: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            },
                            pm: {
                                monday: 5,
                                tuesday: 4,
                                wednesday: 2,
                                thursday: 5,
                                friday: 4
                            }
                        }
                    }}
            })
        }
    }

    selectDays = (i) =>{
        this.setState({
            select: i
        });
    }

    dataBasicRules = (i) => {
        if(i === 'd0_5'){
            return(this.state.data.basicrules.d0_5);
        }
        if(i === 'd1'){
            return(this.state.data.basicrules.d1);
        }
        if(i === 'd1_5'){
            return(this.state.data.basicrules.d1_5);
        }
        if(i === 'd2'){
            return(this.state.data.basicrules.d2);
        }
        if(i === 'd2_5'){
            return(this.state.data.basicrules.d2_5);
        }
        if(i === 'd3'){
            return(this.state.data.basicrules.d3);
        }
        if(i === 'd3_5'){
            return(this.state.data.basicrules.d3_5);
        }
        if(i === 'd4'){
            return(this.state.data.basicrules.d4);
        }
        if(i === 'd4_5'){
            return(this.state.data.basicrules.d4_5);
        }
        if(i === 'd5'){
            return(this.state.data.basicrules.d5);
        }
    }

    render(){
        return ( 
            <React.Fragment>
                <div className={this.state.create ? "aside-bar info create" : "aside-bar info"}>
                    <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={()=>window.history.back()}><i className="material-icons">arrow_back_ios</i> Back</button>
                            </div>
                            <h3 className="center margin-bottom">{this.state.create ? "ADD CONTRACT"  : "CONTRACT #"+this.state.data.info.id}</h3>
                        </div>
                                <div className="group-input">
                                    <label htmlFor="">Child Name</label>
                                    <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.childName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name"/>
                                </div>
                                <div className="group-input">
                                    <label htmlFor="">Caretaker</label>
                                    <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.caretakerName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"}  id="text"/>
                                </div>

                            <div className={this.state.edit || this.state.create ? "btn-container" : "btn-container dissapear"}>
                                <button className="principal">Save</button>
                            </div>
                            <div className={this.state.edit || this.state.create ? "btn-container dissapear" : "col-2 waiting-list-btn"}>
                            
                            <button className="yellow">Terminate contract</button>
                            <button className="red">Cancel contract</button>
                            </div>
                        </div>
                    </div>


                    <div className={this.state.create ? "content create" : "content" }>
                      <div className="content-head">
                        <div className="title">
                          <h1>{this.state.create ? "Add Contract"  : this.state.data.info.name}</h1>
                          <div className="route">Children &gt; Contract List &gt; {this.state.create ? "Add Contract"  : this.state.data.info.name}</div>
                        </div>
                      </div>
                      <div className="content-body">
                          <div className="flex-4 column left">
                            <div className="card">
                                <div className="card-title">CONTRACT DATES <button className="green">Autofill</button></div>
                                <div className="flex">
                                    <div className="flex-6">
                                        <div className="group-input col2">
                                            <label htmlFor="">From</label>
                                            <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                        </div>
                                    </div>
                                    <div className="flex-6">
                                    <div className="group-input col2">
                                        <label htmlFor="" className="center">to</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                    </div>
                                </div>
                                    
                                    <div className="group-input column">
                                        <label htmlFor="">Signing date</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                    <div className="group-input column">
                                        <label htmlFor="">Deadline</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                        <button>Save</button>
                                    </div>
                            </div>
                            <div className="card">
                                <div className="card-title">INFO</div>
                                    <div className="group-input column">
                                        <label htmlFor="">Contract status</label>
                                        <select name="location" id="location-select">
                                            <option value="0" selected>...</option>
                                        </select>
                                    </div>
                                    <div className="group-input column">
                                        <label htmlFor="">Payment by</label>
                                        <select name="location" id="location-select">
                                            <option value="0" selected>...</option>
                                        </select>
                                    </div>
                                    <div className="group-input column">
                                        <label htmlFor="">Payment method</label>
                                        <select name="location" id="location-select">
                                            <option value="0" selected>...</option>
                                        </select>
                                    </div>
                                    <div className="flex">
                                        <div className="flex-6 left">
                                            <div className="group-input column">
                                                <label htmlFor="">Location</label>
                                                <select name="location" id="location-select">
                                                    <option value="0" selected>...</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="flex-6 right">
                                            <div className="group-input column">
                                                <label htmlFor="">Group</label>
                                                <select name="location" id="location-select">
                                                    <option value="0" selected>...</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group-input column">
                                        <label htmlFor="">Flexible No. Days (if applicable)</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                        <button>Save</button>
                                    </div>
                            </div>
                        </div>
                            <div className="flex-8 column right">
                            <div className="card">
                                <div className="card-title">PLANNING</div>
                                <div className="flex">
                                <div className="flex-4 column left">
                                        <div className="check-week">
                                            <table>
                                                <tr>
                                                    <td className="principal">Mon</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Tue</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Wed</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Thu</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Fri</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Sat</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="principal">Sun</td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">AM</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="group-input checkbox">
                                                            <input type="checkbox" name="" id="remember_login" checked={!this.state.create ? this.state.data.regularkeys.use_regular_keys : false} disabled={this.state.edit || this.state.create ? false : true}/>
                                                            <label htmlFor="remember_login">PM</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="subtitle">Dates</div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Start:</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name"/>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">End:</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name"/>
                                        </div>
                                    </div>
                                    <div className="flex-4 column">
                                    <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Location</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Group</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Status</label>
                                            <select name="location" id="location-select">
                                                <option value="0" selected>...</option>
                                            </select>
                                        </div>
                                        <div className="group-input no-padding-bottom column">
                                            <label htmlFor="">Deadline</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name"/>
                                        </div>
                                        <div className="group-input column">
                                            <label htmlFor="">Signing date</label>
                                            <input type="date" name="" defaultValue={!this.state.create ? this.state.data.basicrules.price_calculation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name"/>
                                        </div>
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                    <div className="flex-4 column right">
                                    <TableSelect 
                                    data = {this.state.create ? [] :[
                                        {
                                            id: 1,
                                            start: '01/01/2021',
                                            end: '01/06/2021',
                                            group: 'Brisbane',
                                            product: 'Kindergarden'
                                        },
                                        {
                                            id: 2,
                                            start: '01/01/2021',
                                            end: '01/06/2021',
                                            group: 'Brisbane',
                                            product: 'Kindergarden'
                                        }
                                    ]}
                                    columns = {[
                                        {
                                            title: 'Start',
                                            name: 'start'
                                        },
                                        {
                                            title: 'End',
                                            name: 'end'
                                        },
                                        {
                                            title: 'Group',
                                            name: 'group'
                                        }
                                    ]}
                                    change = {false}
                                    delete = {this.state.create}
                                    details = {true}
                                    delete_function = ""
                                    edit_function = ""
                                    view_function = ""
                                />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="card">
                                <div className="card-title">
                                    CONTRACT ADDITIONS
                                </div>
                                <div className="flex">
                                    <div className="flex-6 column left">
                                        <div className="subtitle">Dates</div>
                                        <div className="flex">
                                    <div className="flex-6">
                                        <div className="group-input col2">
                                            <label htmlFor="">From</label>
                                            <input type="date" name="" id="name"/>
                                        </div>
                                    </div>
                                    <div className="flex-6">
                                    <div className="group-input col2">
                                        <label htmlFor="" className="center">to</label>
                                        <input type="date" name=""  id="name"/>
                                    </div>
                                    </div>
                                </div>
                                        <div className="group-input">
                                            <div className="flex-6 left">
                                                <div className="group-input no-padding-bottom column">
                                                    <label htmlFor="">Sort</label>
                                                    <select name="location" id="location-select">
                                                        <option value="0" selected>...</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex-6 right">
                                                <div className="group-input no-padding-bottom column">
                                                    <label htmlFor="">Use</label>
                                                    <select name="location" id="location-select">
                                                        <option value="0" selected>...</option>
                                                    </select>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="group-input">
                                            <div className="flex-6 left">
                                                <div className="group-input no-padding-bottom column">
                                                    <label htmlFor="">Timing</label>
                                                    <select name="location" id="location-select">
                                                        <option value="0" selected>...</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex-6 right">
                                                <div className="group-input no-padding-bottom column">
                                                    <label htmlFor="">Value</label>
                                                    <select name="location" id="location-select">
                                                        <option value="0" selected>...</option>
                                                    </select>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="group-input no-padding-bottom column">
                                                <label htmlFor="">Comment</label>
                                                <textarea name="" id="" cols="2" rows=""></textarea>
                                            </div>
                                            <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                                <button>Save</button>
                                            </div>
                                    </div>
                                    <div className="flex-6 right column">
                                    <TableSelect 
                                    data = {this.state.create ? [] :[
                                        {
                                            id: 1,
                                            start: '01/01/2021',
                                            end: '01/06/2021',
                                            location: 'Percentage',
                                            product: '-5%',
                                            paymentby: 'Susan Bloom',
                                            paymentsort: 'Automatic debit'
                                        }
                                    ]}
                                    columns = {[
                                        {
                                            title: 'From',
                                            name: 'start'
                                        },
                                        {
                                            title: 'to',
                                            name: 'end'
                                        },
                                        {
                                            title: 'Use',
                                            name: 'location'
                                        },
                                        {
                                            title: 'Value',
                                            name: 'product'
                                        }
                                    ]}
                                    change = {false}
                                    delete = {this.state.create}
                                    details = {false}
                                    delete_function = ""
                                    edit_function = ""
                                    view_function = ""
                                />
                                    </div>
                                </div>
                                
                            </div>
                            
                          </div>
                          
                      </div>
                    </div>
            </React.Fragment> 
        );
    }
}

export default AddContract;