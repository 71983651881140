import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class LocationOverview extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 10,
        display_asidebar: false
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">NAME</label>
                                <input type="text" name="" id="" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">CITY</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">City's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">City's name 2</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city3" value="3" />
                                    <label htmlFor="city3">City's name 3</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city4" value="4" />
                                    <label htmlFor="city4">City's name 4</label>
                                </div>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Location Overview</h1>
                            <div className="route">Locations &gt; Location Overview</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({ display_asidebar: true })}><i className="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <Link to="/locations/overview/create" className="btn principal"><i className="material-icons">add</i>Create Location</Link>
                        </div>
                    </div>
                    <Table
                        data={[
                            {
                                id: 1,
                                name: "Location's name 1",
                                city: "Brisbane",
                                street: "23th street",
                                phone: "(08) 8968 6281"
                            },
                            {
                                id: 2,
                                name: "Location's name 2",
                                city: "Brisbane",
                                street: "23th street",
                                phone: "(08) 8968 6281"
                            },
                            {
                                id: 3,
                                name: "Location's name 3",
                                city: "Brisbane",
                                street: "23th street",
                                phone: "(08) 8968 6281"
                            },
                            {
                                id: 4,
                                name: "Location's name 4",
                                city: "Brisbane",
                                street: "23th street",
                                phone: "(08) 8968 6281"
                            },
                            {
                                id: 5,
                                name: "Location's name 5",
                                city: "Brisbane",
                                street: "23th street",
                                phone: "(08) 8968 6281"
                            },
                            {
                                id: 6,
                                name: "Location's name 6",
                                city: "Brisbane",
                                street: "23th street",
                                phone: "(08) 8968 6281"
                            }
                        ]}
                        columns={[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Name",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "City",
                                name: "city",
                                bool: false
                            },
                            {
                                title: "Street",
                                name: "street",
                                bool: false
                            },
                            {
                                title: "Phone",
                                name: "phone",
                                bool: false
                            }
                        ]}
                        viewFunction={(id)=>{this.props.history.push(`/locations/overview/view/${id}`)}}
                        view={true}
                        edit={true}
                        delete={true}
                        editFunction={(id)=>{this.props.history.push(`/locations/overview/edit/${id}`)}}
                        deleteFunction=""
                        totalElements="47"
                    />
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default LocationOverview;