import  React, { Component } from "react";
import { Redirect } from "react-router-dom";

export class AdminTools extends Component {
    render(){
        return ( 
            <React.Fragment>
                <Redirect to="/admin-tools/users" />
            </React.Fragment> 
        );
    }
}