import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class ProductOverview extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">NAME</label>
                                <input type="text" name="" id="" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city3" value="3" />
                                    <label htmlFor="city3">Location's name 3</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city4" value="4" />
                                    <label htmlFor="city4">Location's name 4</label>
                                </div>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Product Management</h1>
                            <div className="route">Locations &gt; Product Management</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({ display_asidebar: true })}><i className="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <Link to="/locations/products/create" className="btn principal"><i className="material-icons">add</i>Create Product</Link>
                        </div>
                    </div>
                    <Table
                        data={[
                            {
                                id: 1,
                                location: "Location's name 1",
                                name: "Product's name 1",
                                flexspaces: true,
                                status: true
                            },
                            {
                                id: 2,
                                location: "Location's name 2",
                                name: "Product's name 2",
                                flexspaces: false,
                                status: true
                            },
                            {
                                id: 3,
                                location: "Location's name 3",
                                name: "Product's name 3",
                                flexspaces: true,
                                status: true
                            },
                            {
                                id: 4,
                                location: "Location's name 4",
                                name: "Product's name 4",
                                flexspaces: false,
                                status: false
                            },
                            {
                                id: 5,
                                location: "Location's name 5",
                                name: "Product's name 5",
                                flexspaces: false,
                                status: true
                            },
                            {
                                id: 6,
                                location: "Location's name 6",
                                name: "Product's name 6",
                                flexspaces: false,
                                status: true
                            }
                        ]}
                        columns={[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Name",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "Location",
                                name: "location",
                                bool: false
                            },
                            {
                                title: "Flexspaces",
                                name: "flexspaces",
                                bool: true
                            },
                            {
                                title: "Status",
                                name: "status",
                                bool: true
                            }
                        ]}
                        viewFunction={(id)=>{this.props.history.push(`/locations/products/view/${id}`)}}
                        view={true}
                        edit={true}
                        delete={true}
                        editFunction={(id)=>{this.props.history.push(`/locations/products/edit/${id}`)}}
                        deleteFunction=""
                        totalElements="47"
                    />
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default ProductOverview;