import React, { Component } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import logo from '../partials/LOGO.svg'


const getHtml = editorState => draftToHtml(convertToRaw(editorState.getCurrentContent()));

class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty()
        };
    }

    onEditorStateChange = editorState => {
        this.setState({
            editorState
        });
    };

    variables = [
        {
            name: '*CARETAKER_NAME*'
        },
        {
            name: '*CHILD_NAME*'
        },
        {
            name: '*CONTRACT_ID*'
        },
        {
            name: '*CONTRACT_PRODUCT*'
        },
        {
            name: '*CONTRACT_DATE*'
        },
    ]

    render() {
        const { editorState } = this.state;

        return (
            <div className="flex">
                <div className="flex-6 column left editor-left">
                    <div className="card card-vertical">
                        <div className="card-title">EMAIL DESIGN</div>
                        <div className="group-input ">
                            <label htmlFor="">Email subject</label>
                            <input type="text" name="" id="" />
                        </div>
                        <div className="group-input ">
                            <label htmlFor="">Email content</label>

                        </div>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="rich-editor demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.onEditorStateChange}
                        />

                        <div className="editor-variables">
                            <div className="flex">
                                <div className="flex-6 column">
                                    <p>Variabkles</p>
                                    <div className="editor-variables-list">
                                        {
                                            this.variables.map(({ name }) => {
                                                return (
                                                    <p>{name}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="flex-6">
                                    <button>Save</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="flex-6 column right editor-right">
                    <div className="card">

                        <div className="card-title">REAL LOOK</div>

                        <div className="preview-container">
                            <img src={logo} />
                            <div className="card">
                                <div className="preview-content" dangerouslySetInnerHTML={{ __html: getHtml(editorState) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { RichTextEditor };