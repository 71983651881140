import { Component } from "react";

export class CalendarSegmentContent extends Component {



    render() {
        return (
            <div className="segment-content">
                {
                    this.props.data.displayId === 1 ?
                        this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                            return (
                                <div key={item.id} onClick={() => {
                                    this.props.showDetails(item.id)

                                    this.props.setSelected(item.id, this.props.day.name)
                                }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}>
                                    <div className="segment-content-item-image">
                                        <img src={item.img} alt="" />
                                    </div>
                                    <div className="general-item">
                                        <div className="general-item-info">
                                            <p className="name">{item.name} <span className="extra-data">({item.extraData})</span></p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )
                        :
                        this.props.data.displayId === 2 ?
                            this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                                return (
                                    <div key={item.id} onClick={() => {
                                        this.props.showDetails(item.id)
                                        this.props.setSelected(item.id, this.props.day.name)
                                    }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}>
                                        <div className="segment-content-item-image">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="general-item">
                                            <div className="general-item-info">
                                                <p className="name">{item.name} <span className="extra-data">({item.extraData})</span></p>
                                            </div>
                                            {
                                                item.sub &&
                                                <div className="general-item-sub">
                                                    <p>{item.sub}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            )

                            :
                            this.props.data.displayId === 3 ?
                                this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                                    return (
                                        <div key={item.id}
                                            onClick={() => {
                                                this.props.showDetails(item.id)
                                                this.props.setSelected(item.id, this.props.day.name)
                                            }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}
                                        >
                                            <div className="lunch-info">
                                                <p className="lunch-time">{item.time}</p>
                                                <p className="lunch-title">{item.title}</p>
                                                <p className="lunch-description">{item.description}</p>
                                                <p className="lunch-group">Group: {item.group}</p>

                                            </div>
                                        </div>
                                    )
                                }
                                )
                                :

                                this.props.data.displayId === 4 ?
                                    this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                                        return (
                                            <div key={item.id}
                                                onClick={() => {
                                                    this.props.showDetails(item.id)
                                                    this.props.setSelected(item.id, this.props.day.name)
                                                }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}
                                            >
                                                <div className="general-item-info">
                                                    <div className="activity-info">

                                                        <div className="info">
                                                            <p className="item-time">{item.time}</p>
                                                            <p className="item-title">{item.title}</p>
                                                        </div>

                                                        <div className="location">
                                                            <p>Place: {item.place}</p>
                                                            <p>Group: {item.group}</p>
                                                        </div>

                                                        <div className="item-teacher-info">
                                                            <div className="item-teacher-info-img">
                                                                <img src={item.teacher?.img} alt="" />
                                                            </div>
                                                            <div>
                                                                <p>{item.teacher?.category}</p>
                                                                <p>{item.teacher?.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }
                                    ) : this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                                        return (
                                            <div key={item.id}
                                                onClick={() => {
                                                    this.props.showDetails(item.id)
                                                    this.props.setSelected(item.id, this.props.day.name)
                                                }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}
                                            >
                                                <div className="shift-extra-data">
                                                    {item.extraData}
                                                </div>
                                                <div className="shift-info">

                                                    <div className="shift">
                                                        <div className="shift-img">
                                                            <img src={item.img} alt="" />
                                                        </div>
                                                        <div className="info">
                                                            <p className="info-name">{item.name}</p>
                                                            <p>{item.type}</p>
                                                            <p>{item.shift}</p>
                                                            <p>{item.apprentice}</p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        )
                                    }
                                    )
                }
            </div>
        )
    }
}