import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";

class ReportsChildren extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false,
        asiderbar_create: false,
        selected: 1
    }



    showFilters = () => {
        if(this.state.selected === 1){
            return(
                <div className="container info">
                        <div className="header">
                            <h3 className="center">CHILD'S ATTENDANCE</h3>
                        </div>
                        <div className="filters">
                            <div className="subtitle">location</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a location...</option>
                                    <option value="1">Location's name 1</option>
                                    <option value="2">Location's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">group</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a group...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">child</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a child...</option>
                                    <option value="1">Child's name 1</option>
                                    <option value="2">Child's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">Date</div>
                            <div className="group-input">
                                <label htmlFor="">Year</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>2020</option>
                                    <option value="1">2021</option>
                                </select>
                                <label htmlFor="">Month</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>January</option>
                                    <option value="1">February</option>
                                    <option value="2">March</option>
                                </select>
                            </div>
                            <div className="subtitle">Sort by</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Children</option>
                                    <option value="1">Date</option>
                                    <option value="2">Group</option>
                                </select>
                                <select name="location" id="location-select" style={{width: 100}}>
                                    <option value="0" selected>ASC</option>
                                    <option value="1">DESC</option>
                                </select>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Generate</button>
                            </div>
                        </div>
                        </div>
            );
        }
        if(this.state.selected === 2){
            return(
                <div className="container info">
                        <div className="header">
                            <h3 className="center">CHILD'S CONTROL LIST</h3>
                        </div>
                        <div className="filters">
                            <div className="subtitle">location</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a location...</option>
                                    <option value="1">Location's name 1</option>
                                    <option value="2">Location's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">group</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a group...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">child</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>All children...</option>
                                    <option value="1">Child's name 1</option>
                                    <option value="2">Child's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">checklist</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>All checklists...</option>
                                    <option value="1">Checklist's name 1</option>
                                    <option value="2">Checklist's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">Date</div>
                            <div className="group-input">
                                <label htmlFor="">Year</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>2020</option>
                                    <option value="1">2021</option>
                                </select>
                                <label htmlFor="">Month</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>January</option>
                                    <option value="1">February</option>
                                    <option value="2">March</option>
                                </select>
                            </div>
                            <div className="subtitle">Sort by</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Children</option>
                                    <option value="1">Date</option>
                                    <option value="2">Group</option>
                                </select>
                                <select name="location" id="location-select" style={{width: 100}}>
                                    <option value="0" selected>ASC</option>
                                    <option value="1">DESC</option>
                                </select>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Generate</button>
                            </div>
                        </div>
                        </div>
            );
        }
        if(this.state.selected === 3){
            return(
                <div className="container info">
                        <div className="header">
                            <h3 className="center">MEDICAL CONDITIONS</h3>
                        </div>
                        <div className="filters">
                            <div className="subtitle">location</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a location...</option>
                                    <option value="1">Location's name 1</option>
                                    <option value="2">Location's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">group</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a group...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">child</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>All children...</option>
                                    <option value="1">Child's name 1</option>
                                    <option value="2">Child's name 2</option>
                                </select>
                            </div>
                            <div className="subtitle">Sort by</div>
                            <div className="group-input">
                                <select name="location" id="location-select">
                                    <option value="0" selected>Children</option>
                                    <option value="2">Group</option>
                                </select>
                                <select name="location" id="location-select" style={{width: 100}}>
                                    <option value="0" selected>ASC</option>
                                    <option value="1">DESC</option>
                                </select>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Generate</button>
                            </div>
                        </div>
                        </div>
            );
        }
    }

    render(){
        return ( 
            <React.Fragment>
                    <div className="aside-bar">
                    <div className="container">
                        <div className="header">
                            <h3>REPORTS</h3>
                            <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters btn-container">
                            <button className={this.state.selected === 1 && 'active'} onClick={()=>this.setState({selected: 1})}>Child's Attendance <i className="material-icons">arrow_forward_ios</i></button>
                            <button className={this.state.selected === 2 && 'active'} onClick={()=>this.setState({selected: 2})}>Child's Control List<i className="material-icons">arrow_forward_ios</i></button>
                            <button className={this.state.selected === 3 && 'active'} onClick={()=>this.setState({selected: 3})}>Medical Conditions <i className="material-icons">arrow_forward_ios</i></button>
                        </div>
                        </div>
                    </div>
                    <div className="content">
                      <div className="content-head">
                        <div className="title">
                          <h1>Children's Reports</h1>
                          <div className="route">Reports &gt; Children's Reports</div>
                        </div>
                      </div>
                      <div className="principal-content">
                      <div className="report-filter-bar aside-bar">
                        {this.showFilters()}
                      </div>
                      </div>
                    </div>
            </React.Fragment> 
        );
    }
}

export default ReportsChildren;