import React, { Component } from 'react';

class InvoiceTableSelect extends Component {
    state = {
        trSelected : 0
    }

    printRows = () =>{
        if(this.props.data.length !== 0){
            return(
                <tbody>
                        {this.props.data.map((item, key) => (
                            <tr onClick={()=>this.setState({trSelected : item.id})} className={this.state.trSelected === item.id ? 'selected' : ''}>
                                {this.printTd(key)}
                                {this.props.download && <button className="ghost"><i className="material-icons download">get_app</i></button>}
                            </tr>
                        ))}
                </tbody>
            );
        }else{
            return(
                <tbody>
                    <tr>
                        <td colSpan={this.props.columns.length+1} rowSpan="8" className="error">No records</td>

                    </tr>
                </tbody>
            );
        }
    }

    printTd = (i) =>{
        return(
            <React.Fragment>
                {this.props.columns.map(column => (
                    <td>
                        {
                        (this.props.data[i][column.name] ? this.props.data[i][column.name] :'')

                        }
                    </td>
                ))}
            </React.Fragment>

        ); 
        
    }

    printColumns = () =>{
        if(this.props.columns.length !== 0){
            return(
                this.props.columns.map(column => (
                    <th>{column.title}</th>
                ))
            );
          
        }
    }

    render(){
        return ( 
            <React.Fragment>
                <div className="select-table">
                    <table>
                        <thead>
                            <tr>
                                {this.printColumns()}
                            </tr>
                        </thead>
                        {this.printRows()}
                    </table>
                </div>
                {this.props.change && 
                    <div className="btn-container invoice-select-table-btn">
                    <div className="generate-invoice">
                        <div className="group-input">
                            <label htmlFor="">Date: </label>
                            <input type="date" name="" id=""/>
                            <button className="primary">Generate invoice</button>
                        </div>
                    </div>
                    <div className="change-inputs">
                        <div className="group-input">
                            <select name="" id="">
                                <option value="">Payed</option>
                                <option value="">...</option>
                                <option value="">...</option>
                                <option value="">...</option>
                            </select>
                            <button className="green">Change status</button>
                        </div>
                        <div className="group-input">
                            <input type="date" name="" id=""/>
                            <button className="green">Change pay date</button>
                        </div>
                    </div>
                </div>
                }
                
            </React.Fragment> 
        );
    }
}

export default InvoiceTableSelect;