import React, { Component } from 'react';

class TableReports extends Component {

    printRows = () =>{
        if(this.props.data.length !== 0){
            return(
                <tbody>
                        {this.props.data.map((item, key) => (
                            <tr>
                                        {this.printTd(key)}
                                        
                            </tr>
                        ))}
                        <tr>
                            {this.props.showInput ? 
                                <React.Fragment>
                                    {this.printInputs()}
                                    <td><button className="add"><i className="material-icons">add</i></button></td>
                                </React.Fragment>
                            : ""}
                        </tr>
                </tbody>
            );
        }else{
            return(
                <tbody>
                    <tr>
                        <td colSpan={this.props.columns.length+1} rowSpan="8" className="error">No records</td>
                    </tr>
                </tbody>
            );
        }
    }

    printTd = (i) =>{
        return(
            <React.Fragment>
                {this.props.columns.map(column => (
                    <td>
                        {
                        (this.props.data[i][column.name] ? this.props.data[i][column.name] :'')
                        }
                    </td>
                ))}
            </React.Fragment>
        ); 
        
    }

    printColumns = () =>{
        if(this.props.columns.length !== 0){
            return(
                this.props.columns.map(column => (
                    <th>{column.title}</th>
                ))
            ); 
          
        }else{
            return(
                <th>Introduce life phases for set the legal care values</th>
            );
        }
    }

    printInputs = () =>{
        if(this.props.columns.length !== 0){
            return(
                this.props.columns.map(column => (
                    <td>
                        <div className="group-input">
                            <input type="text" name={column.name} required/>
                        </div>
                    </td>
                ))
            );
        }
    }

    render(){
        return ( 
            <React.Fragment>
                <div className="table-report">
                    <table>
                        <thead>
                            <tr>
                                {this.printColumns()}
                            </tr>
                        </thead>
                        {this.printRows()}
                    </table>
                </div>
            </React.Fragment> 
        );
    }
}

export default TableReports;