import React, { Component } from "react";

export class CalendarSegmentHeader extends Component {


    render() {
        return (
            <div className={
                !this.props.segment.content ? 'segment-header segment-center' :
                    this.props.segment.content[this.props.day.name].num === this.props.segment.content[this.props.day.name].total ? "segment-header green" :
                        this.props.segment.content[this.props.day.name].num > this.props.segment.content[this.props.day.name].total ? "segment-header red" : this.props.segment.content[this.props.day.name].num < this.props.segment.content[this.props.day.name].total ? 'segment-header empty' :
                            "segment-header blue"
            }>

                {
                    (this.props.data.displayId === 1 || this.props.data.displayId === 3 || this.props.data.displayId === 4) &&
                    <div>
                        <p>{this.props.segment.title}</p>
                    </div>
                }
                {
                    this.props.data.displayId === 2 &&
                    <div className="segment-waiting-list">
                        <p>{this.props.segment.title}</p>
                        <p>{this.props.segment.content[this.props.day.name].total}</p>
                    </div>
                }

                {
                    this.props.data.displayId === 5 ?
                    <React.Fragment>
                    <div className="segment-waiting-list">
                        <p>{this.props.segment.title}</p>

                    </div>
                    <p>{this.props.segment.content[this.props.day.name].total}/{this.props.segment.content[this.props.day.name].num}</p></React.Fragment>
                    : this.props.segment.content &&
                    (this.props.segment.content[this.props.day.name].num && this.props.segment.content[this.props.day.name].total) &&
                    <p>{this.props.segment.content[this.props.day.name].num}/{this.props.segment.content[this.props.day.name].total} </p>
                }
            </div>
        )
    }
}