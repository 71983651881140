import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Calendar } from "../../partials/Calendar";

export class ShiftPlanning extends Component {

    state = {
        display_asidebar: false,
        asiderbar_details: false,
        add: false,
        segments: [
            {
                content: {
                    monday: {
                        num: 11.5,
                        total: 11.5,
                    },
                    tuesday: {
                        num: 11.5,
                        total: 11.5,
                    },
                    wednesday: {
                        num: 10,
                        total: 11.5,
                    },
                    thursday: {
                        num: 11.5,
                        total: 11.5,
                    },
                    friday: {
                        num: 12,
                        total: 11.5,
                    },
                    saturday: {
                        num: '0',
                        total: 11.5,
                    },
                    sunday: {
                        num: '0',
                        total: 11.5,
                    }
                },
                name: 'occup',
                title: 'Req/Act'
            },
        ],

        data: {
            displayId: 5,
            monday: {
                occup: [
                    {
                        id: 5,
                        name: 'Jane H. Doe',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    },
                    {
                        id: 2,
                        name: 'Harry G. Smith',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    }
                ],
            },
            tuesday: {
                occup: [
                    {
                        id: 5,
                        name: 'Jane H. Doe',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    },
                    {
                        id: 2,
                        name: 'Harry G. Smith',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    }
                ],
            },
            wednesday: {
                occup: [
                    {
                        id: 5,
                        name: 'Jane H. Doe',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    },
                    {
                        id: 2,
                        name: 'Harry G. Smith',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    }
                ],
            },
            thursday: {
                occup: [
                    {
                        id: 5,
                        name: 'Jane H. Doe',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    },
                    {
                        id: 2,
                        name: 'Harry G. Smith',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    }
                ],
            },
            friday: {
                occup: [
                    {
                        id: 5,
                        name: 'Jane H. Doe',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    },
                    {
                        id: 2,
                        name: 'Harry G. Smith',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    }
                ],
            },
            thursday: {
                occup: [
                    {
                        id: 5,
                        name: 'Jane H. Doe',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    },
                    {
                        id: 2,
                        name: 'Harry G. Smith',
                        type: 'Apprentice',
                        img: '/assets/teacher.png',
                        extraData: '3.5',
                        shift: '11:00AM-12:30PM'
                    }
                ],
            },
            saturday: {
                occup: [],
            },
            sunday: {
                occup: [],
            },
        },
        week: [
            {
                id: 1,
                title: 'MON',
                name: 'monday',
                day: 20201228
            },
            {
                id: 2,
                title: 'TUE',
                name: 'tuesday',
                day: 20201228
            },
            {
                id: 3,
                title: 'WED',
                name: 'wednesday',
                day: 20201228
            },
            {
                id: 4,
                title: 'THU',
                name: 'thursday',
                day: 20201228
            },
            {
                id: 5,
                title: 'FRI',
                name: 'friday',
                day: 20201228
            },
            {
                id: 6,
                title: 'SAT',
                name: 'saturday',
                day: 20201228
            },
            {
                id: 7,
                title: 'SUN',
                name: 'sunday',
                day: 20201228
            },
        ]

    }

    teachers = [
        {
            id: 2,
            firstName: "Jane",
            lastName: "Doe",
            prefName: "Jane",
            birthday: "05/07/1998",
            phone: "(12) 3456 7890",
            email: "test@wondergarden.com",
            emergencyPhone: "(12) 3456 7890",
            socialSecurityNumber: "123.1234.1234.12",
            img: "/assets/teacher.png",
            arrival: '09:50:00 AM',
            departure: '11:00:00 AM'

        },

        {
            id: 5,
            firstName: "Jane",
            lastName: "Doe",
            prefName: "Jane",
            birthday: "05/07/1998",
            phone: "(12) 3456 7890",
            email: "test@wondergarden.com",
            emergencyPhone: "(12) 3456 7890",
            socialSecurityNumber: "123.1234.1234.12",
            img: "/assets/teacher.png",
            arrival: '09:50:00 AM',
            departure: '11:00:00 AM'

        }
    ]

    setAdd = () => {
        this.setState({ add: true })
        console.log('add');
    }

    showDetails = (id) => {
        this.setState({
            asiderbar_details: true,
            teacher: this.teachers.find(teacher => teacher.id === id),
            add: false
        })
    }

    displayAsidebar = () => {
        this.setState({
            display_asidebar: true
        })
    }


    showAsideBar = () => {
        return (
            this.state.asiderbar_details ? <div className="aside-bar popup active info">
                <div className="container">
                    <div className="header">
                        <div className="btn-container">
                            <button className="left" onClick={() => { this.setState({ asiderbar_details: false }) }}><i className="material-icons">arrow_back_ios</i> Back</button>

                        </div>
                    </div>
                    <div className="img-container">
                        <div className="circle">
                            {this.state.create ? <i className="material-icons">person</i> : <img src={this.state.teacher.img} alt="" />}
                            {this.state.create ? <button><i className="material-icons">add</i></button> : <button><i className="material-icons">edit</i></button>}
                        </div>
                    </div>
                    <div className="subtitle">INFO</div>
                    <div className="col-2">
                        <div className="group-input">
                            <label htmlFor="">First Name</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.teacher.firstName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Last Name</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.teacher.lastName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="group-input">
                            <label htmlFor="">Pref Name</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.teacher.prefName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Birthday</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.teacher.birthday : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                    </div>

                    <div className="group-input">
                        <label htmlFor="">Phone</label>
                        <input type="text" name="" defaultValue={!this.state.create ? this.state.teacher.phone : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                    </div>
                    <div className="group-input">
                        <label htmlFor="">Email</label>
                        <input type="text" name="" defaultValue={!this.state.create ? this.state.teacher.email : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                    </div>

                    <div className="group-input">
                        <label htmlFor="">Emergency Phone</label>
                        <input type="text" name="" defaultValue={!this.state.create ? this.state.teacher.emergencyPhone : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                    </div>
                    <div className="group-input">
                        <label htmlFor="">Social Security Number</label>
                        <input type="text" name="" defaultValue={!this.state.create ? this.state.teacher.socialSecurityNumber : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                    </div>

                    <div className="subtitle">LOGGING</div>

                    <div className="col-2">
                        <div className="group-input">
                            <label htmlFor="">Arrival time</label>
                            <input type="time" name="" id="name" className="normal-input"/>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Departure time</label>
                            <input type="time" name="" id="name" className="normal-input"/>
                        </div>
                    </div>


                    <div className="col-2">
                        <button className="shift-btn shift-green">Add times</button>
                        <button className="shift-btn shift-red">Absent</button>
                    </div>
                </div>
            </div> :

                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                            </div>

                            <div className="group-input">
                                <label htmlFor="">GROUP</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>

                            <div className="group-input">
                                <label htmlFor="">LEGAL FUNCTION</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>


                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }


    available = [
        {
            id: 1,
            name: 'Jane H. Doe',
            type: 'Apprentice',
            img: '/assets/teacher.png',
            weight: '3.5'
        },
        {
            id: 2,
            name: 'Jane H. Doe',
            type: 'Apprentice',
            img: '/assets/teacher.png',
            weight: '3.5'
        }
    ]

    render() {
        return (

            <React.Fragment>
                {!this.state.add && this.showAsideBar()}
                {
                    this.state.add && <div className={this.state.add ? "aside-bar popup active" : "aside-bar popup"}>
                        <div className="container">
                            <div className="header">

                                <div className="btn-container">
                                    <button className="left" onClick={() => this.setState({ add: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                                </div>
                                <h3>ASSIGN SHIFT</h3>

                            </div>
                            <div className="filters shift-planning">

                                <div className="col-2">
                                    <p>Available teachers</p>
                                    <p>occup: 7/11</p>
                                </div>

                                <div className="teachers">


                                    {
                                        this.available.map(teacher => (
                                            <div onClick={
                                                () => {
                                                    this.setState(
                                                        { selected: teacher }
                                                    )
                                                }
                                            } className={this.state.selected?.id === teacher.id ? "available-teacher teacher-selected" : "available-teacher"}>
                                                <div className="button-filter-outter">
                                                    <div className={
                                                        this.state.selected?.id === teacher.id ? "inner" : "inner inner-hidden"
                                                    }>

                                                    </div>
                                                </div>
                                                <div className="available-teacher-img">
                                                    <img src={teacher.img} alt="" />
                                                </div>
                                                <div className="available-teacher-info">
                                                    <p className="name">{teacher.name}</p>
                                                    <p>{teacher.type}</p>
                                                    <p>{teacher.weight}</p>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>

                                <div className="group-input">
                                    <label htmlFor="">Shift</label>
                                    <div className="group-radio">
                                        <input type="radio" name="city" id="city1" value="1" />
                                        <label htmlFor="city1">09:50:00 AM - 17:30:00 AM</label>
                                    </div>
                                    <div className="group-radio">
                                        <input type="radio" name="city" id="city1" value="1" />
                                        <label htmlFor="city1">09:50:00 AM - 17:30:00 AM</label>
                                    </div>
                                    <div className="group-radio">
                                        <input type="radio" name="city" id="city1" value="1" />
                                        <label htmlFor="city1">09:50:00 AM - 17:30:00 AM</label>
                                    </div>
                                    <div className="group-radio">
                                        <input type="radio" name="city" id="city1" value="1" />
                                        <label htmlFor="city1">09:50:00 AM - 17:30:00 AM</label>
                                    </div>
                                </div>


                                <div className="group-input col-2 no-padding-top">

                                    <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />

                                    <label htmlFor="" className="center">to</label>
                                    <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                                    <button className="teacher-add"><i className="material-icons">add</i></button>
                                </div>




                                <div className="btn-container">
                                    <button className="principal">Assign shift</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="content">
                    <Calendar
                        title="Shift Planning"
                        subtitle="HR and Finances > Shilft Planning"
                        data={this.state.data}
                        segments={this.state.segments}
                        week={this.state.week}
                        showDetails={this.showDetails}
                        view="week"
                        displayAsidebar={this.displayAsidebar}
                        setAdd={this.setAdd}
                    />
                </div>
            </React.Fragment>
        );
    }
}