import React, { Component } from 'react';

class LeaveDaysChart extends Component {



    render(){
        let widthItems = (100/this.props.data.length)+"%";
        return ( 
            <React.Fragment>
                <div id="leaveDays">
                <div className="occupancy-chart">
                    {
                        this.props.data.map(item => (
                            <div className={item.value === 1 ? "item green" : (item.value > 1 ? "item red" : "item gray")} style={{height: (item.value === 2 ? '100' : item.value === 1 ? '50' : '0')+"%", width: widthItems}}>{item.value !== 0 && item.value}</div>
                        ))
                    }
                </div>
                <div className="occupancy-footer">
                    {
                        this.props.data.map(item => (
                            <div className={item.value === 100 ? "item green" : (item.value > 100 ? "item red" : "item gray")} style={{width: widthItems}}>{item.id}</div>
                        ))
                    }
                </div>
                <div className="percent50"></div>
                <div className="percent100"></div>
                </div>
            </React.Fragment> 
        );
    }
}

export default LeaveDaysChart;