import React, { Component } from 'react';


class AddUser extends Component {
    state = {
        create: false,
        edit: false,
        data: {
            info: {
                name: "",
                location: "",
                flexspaces: "",
                status: ""
            }
        },

        permissions: [
            {
                name: 'Superadmin'
            },
            {
                name: 'Local'
            },
            {
                name: 'Teacher'
            },
            {
                name: 'Personalized'
            },
        ]


    }

    componentDidMount() {
        if (this.props.match.params.type === 'create') {
            this.setState({
                create: true,
                edit: false
            });
        } else {
            if (this.props.match.params.type === 'edit') {
                this.setState({
                    create: false,
                    edit: true
                });
            } else {
                this.setState({
                    create: false,
                    edit: false
                });
            }
            this.setState({
                data: {
                    info: {
                        firstName: "Charlie",
                        lastName: "Bloom",
                        email: "test@wondergarden.com",
                        password: "1234567",
                        defaultLocation: "Location 1",
                        active: "Active"
                    },

                }
            })
        }
    }



    render() {
        return (
            <React.Fragment>
                <div className={this.state.create ? "aside-bar info create" : "aside-bar info"}>
                    <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => window.history.back()}><i className="material-icons">arrow_back_ios</i> Back</button>
                                {!this.state.edit && !this.state.create ? <button className="right" onClick={() => this.setState({ edit: true })}><i className="material-icons">create</i> Edit</button> : ''}
                            </div>
                        </div>
                        <div className="img-container">
                            <div className="circle">
                                {this.state.create ? <i className="material-icons">person</i> : <img src="/assets/child_1.png" alt="" />}
                                {this.state.create ? <button><i className="material-icons">add</i></button> : <button><i className="material-icons">edit</i></button>}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">First Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.firstName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Last Name</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.lastName : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Email</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.email : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Password</label>
                            <input type="password" name="" defaultValue={!this.state.create ? this.state.data.info.password : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Default location</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.defaultLocation : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Active</label>
                            <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.active : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                        </div>


                        <div className={this.state.edit || this.state.create ? "btn-container" : "btn-container dissapear"}>
                            <button className="principal">Save</button>
                        </div>
                    </div>
                </div>


                <div className={this.state.create ? "content create" : "content"}>
                    <div className="content-head">
                        <div className="title">
                            <h1>{this.state.create ? "Add user" : this.state.data.info.firstName}</h1>
                            <div className="route">Admin Tools &gt; User Management &gt; {this.state.create ? "Add user" : this.state.data.info.firstName}</div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="flex flex-12">
                            <div className="card">
                                <div className="card-title">
                                    PERMISSION PROFILE
                                </div>
                                <div className="flex">
                                    <div className="flex-4 left">
                                        <div className="button-filters">
                                            {
                                                this.state.permissions.map((permission) => (
                                                    <div
                                                        onClick={() => { this.setState({ selectedFilter: permission.name }) }} className={this.state.selectedFilter === permission.name ? "button-filter button-filter-light selected-filter-light" : "button-filter button-filter-light"}
                                                    >
                                                        <div className="button-filter-outter">
                                                            <div className={
                                                                this.state.selectedFilter === permission.name ? "inner" : "inner inner-hidden"
                                                            }>

                                                            </div>
                                                        </div>
                                                        <p>{permission.name}</p>
                                                        {
                                                            this.state.selectedFilter !== permission.name &&
                                                            <i className="material-icons">
                                                                arrow_forward_ios
                                                            </i>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="flex-8 column right">
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label htmlFor="">Locations</label>
                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-2" htmlFor="">Location Overview</label>
                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Create new location</label>
                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Edit location</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">View location</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Delete location</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-2" htmlFor="">Group overview</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Show only groups from default location</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Create new group</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Edit group</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">View group</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Delete group</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-2" htmlFor="">Product management</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Show only products from default location</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Create new product</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Edit group</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">View product</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-3" htmlFor="">Delete product</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label htmlFor="">Children</label>

                                        </div>
                                        <div className="user-check">
                                            <input type="checkbox" name="" id="" />
                                            <label className="lvl-2" htmlFor="">Children overview</label>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AddUser;