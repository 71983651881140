import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import TableCardSmall from "../../partials/TableCardSmall";
import CustomizeInputTable from "../locations/partials/CustomizeInputTable";
import CustomizeSelect from "../locations/partials/CustomizeSelect";

export class Wage extends Component {


    state = {
        display_asidebar: false,
        create: true,
        edit: true,
        selectedLocation: '',
        selectedProduct: '',
        data: {
            info: {
                name: "",
                city: "",
                postal: "",
                street: "",
                houseno: "",
                phone: "",
                email: "",
                localmanager: ""
            },
            exitmoment: {
                age: "",
                exitdate: []
            },
            payment: {
                paymentdue: "",
                cancelationdays: "",
                monthinadvance: "",
                otherfromlog: ""
            },
            legalcarevalues: {
                data: [],
                columns: []
            },
            lifephases: [],
            products: [],
            holidays: {
                recurring: [],
                specific: []
            }
        },
        locations: [
            {
                name: 'Location name 1'
            },
            {
                name: 'Location name 2'
            },
            {
                name: 'Location name 3'
            },
            {
                name: 'Location name 4'
            },
        ],
        products: [
            {
                name: 'Regular classes',
            },
            {
                name: 'Extracurricular activities',
            },
            {
                name: 'Summer camp',
            }
        ]
    }


    dataBasicRules = (i) => {
        if (i === 'd0_5') {
            return (this.state.data.basicrules.d0_5);
        }
        if (i === 'd1') {
            return (this.state.data.basicrules.d1);
        }
        if (i === 'd1_5') {
            return (this.state.data.basicrules.d1_5);
        }
        if (i === 'd2') {
            return (this.state.data.basicrules.d2);
        }
        if (i === 'd2_5') {
            return (this.state.data.basicrules.d2_5);
        }
        if (i === 'd3') {
            return (this.state.data.basicrules.d3);
        }
        if (i === 'd3_5') {
            return (this.state.data.basicrules.d3_5);
        }
        if (i === 'd4') {
            return (this.state.data.basicrules.d4);
        }
        if (i === 'd4_5') {
            return (this.state.data.basicrules.d4_5);
        }
        if (i === 'd5') {
            return (this.state.data.basicrules.d5);
        }
    }


    render() {
        return (

            <React.Fragment>
                <div className="aside-bar"
                >
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                        </div>

                        <div className="button-filters">
                            {
                                this.state.locations.map((location) => (
                                    <div onClick={() => { this.setState({ selectedLocation: location.name }) }} className={this.state.selectedLocation === location.name ? "button-filter selected-filter" : "button-filter"}>
                                        <p> {location.name}</p>
                                        <i className="material-icons">
                                            arrow_forward_ios
                                        </i>
                                    </div>

                                ))
                            }
                        </div>

                    </div>
                </div>
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Employee Payments</h1>
                            <div className="route">HR and Finances &gt; Employee Payments</div>
                        </div>

                    </div>
                    <div className="content-body">
                        <div className="flex-4 column left">
                            <div className="card card-vertical">
                                <div className="button-filters">
                                    <h2>Products</h2>
                                    <br />
                                    {
                                        this.state.products.map((product) => (
                                            <div onClick={() => { this.setState({ selectedProduct: product.name }) }} className={this.state.selectedProduct === product.name ? "button-filter selected-filter" : "button-filter"}>
                                                <p> {product.name}</p>
                                                <i className="material-icons">
                                                    arrow_forward_ios
                                                </i>
                                            </div>
                                        ))
                                    }
                                    <button onClick={() => { this.setState({ add: true }) }} className="wage-add">
                                        <i className="material-icons">add</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.add &&
                            <div className="flex-8 column right">
                                <div className="card card-vertical">
                                    <div className="card-title">INFO</div>

                                    <div className="flex">
                                        <div className="flex-6 left">
                                            <div className="group-input">
                                                <label htmlFor="">Name</label>
                                                <input type="number" name="" defaultValue={!this.state.create ? this.state.data.exitmoment.age : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                            </div>
                                        </div>
                                        <div className="flex-6 right">
                                            <div className="group-input">
                                                <label htmlFor="">LOCATION</label>
                                                <div className="group-radio">
                                                    <input type="radio" name="city" id="city1" value="1" />
                                                    <label htmlFor="city1">Location's name 1</label>
                                                </div>
                                                <div className="group-radio">
                                                    <input type="radio" name="city" id="city2" value="2" />
                                                    <label htmlFor="city2">Location's name 2</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                        <button>Save</button>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-title">HOURLY WAGE</div>
                                    <div className="table-container">
                                        <CustomizeInputTable
                                            data={this.dataBasicRules(this.state.select)}
                                            create={this.state.create}
                                            edit={this.state.edit}
                                        />
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                        <button>Save</button>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-title">ANUAL SALARY</div>
                                    <div className="group-input">
                                        <label htmlFor="">Value</label>
                                        <input type="text" name="" id=" "/>
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                        <button>Save</button>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-title">EXTENDED HOURS</div>
                                    <div className="flex">
                                    <div className="flex-12 column left">
                                        <div className="subtitle">Planned extended hours</div>
                                        <div className="table-container">
                                        <CustomizeInputTable
                                            data={this.dataBasicRules(this.state.select)}
                                            create={this.state.create}
                                            edit={this.state.edit}
                                        />
                                        </div>
                                    </div>
                                    <div className="flex-12 column">
                                        <div className="subtitle">Incidental extended hours</div>
                                        <div className="table-container">
                                        <CustomizeInputTable
                                            data={this.dataBasicRules(this.state.select)}
                                            create={this.state.create}
                                            edit={this.state.edit}
                                        />
                                        </div>
                                    </div>
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                        <button>Save</button>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}