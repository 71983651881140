import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Calendar } from "../../partials/Calendar";
import Table from "../../partials/Table";

export class LeaveDaysForm extends Component {

    state = {
        display_asidebar: false,
        asiderbar_details: false,
        add: false,
        filters: true,
        user: {

        },
        data: {
            displayId: 6,
            january: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            february: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            march: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            april: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            may: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            june: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            july: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            august: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            september: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            october: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            november: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ],
            december: [
                {
                    id: 1,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '1',
                },
                {
                    id: 2,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '11',
                },
                {
                    id: 3,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '18',
                },
                {
                    id: 4,
                    name: 'Jane H. Doe',
                    img: '/assets/teacher.png',
                    type: 'Apprentice',
                    extraData: '25',
                }
            ]
        }
    }

    displayAsidebar = () => {
        this.setState({
            display_asidebar: true
        })
    }

    showAsideBar = () => {
        return (
            !this.state.asiderbar_details && !this.state.add ?
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                            </div>

                            <div className="group-input">
                                <label htmlFor="">GROUP</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>

                            <div className="group-input">
                                <label htmlFor="">LEGAL FUNCTION</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>


                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>


                : ''

        )
    }

    setAdd = () => {
        this.setState({ add: true })
    }


    render() {
        return (
            <React.Fragment>

                {this.showAsideBar()}

                {
                    this.state.add && <div className={this.state.add ? "aside-bar popup active" : "aside-bar popup"}>
                        <div className="container">
                            <div className="header">

                                <div className="btn-container">
                                    <button className="left" onClick={() => this.setState({ add: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                                </div>
                                <h3>LEAVE DAYS FORM</h3>

                            </div>
                            <div className="filters">

                                <div className="group-input">
                                    <label htmlFor="">Teacher</label>
                                    <input type="text" />

                                </div>
                                <div className="group-input">
                                    <label htmlFor="">Date</label>

                                </div>


                                <div className="group-input col-2 no-padding-top">
                                    <label htmlFor="">From</label>
                                    <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />

                                    <label htmlFor="" className="center">to</label>
                                    <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                                </div>

                                <div className="group-input">
                                    <label htmlFor="">Reason</label>
                                    <input type="text" />
                                </div>


                                <div className="btn-container">
                                    <button className="principal">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="content calendar-content">
                    <Calendar
                        title="Leave Days Form"
                        subtitle="HR and Finances > Leave Days Form"
                        data={this.state.data}
                        segments={this.state.segments}
                        week={this.state.week}
                        showDetails={this.showDetails}
                        displayAsidebar={this.displayAsidebar}
                        view='year'
                        setAdd={this.setAdd}

                    >

                    </Calendar>
                </div>
            </React.Fragment>
        )

    }
}