import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../partials/Pagination";
import Table from "../../partials/Table";

export class DocumentsControl extends Component {

    state = {

        display_asidebar: false,
        filters: true,
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 10,
        view: false,
        document: {}

    }

    handleClick = () => {
        document.querySelector('#file-input').click()
    }

    documents = [
        {
            id: 1,
            name: 'Document 1',
            active: 'Active'
        },
        {
            id: 2,
            name: 'Document 2',
            active: 'Inactive'
        },
        {
            id: 3,
            name: 'Document 3',
            active: 'Active'
        },
        {
            id: 4,
            name: 'Document 4',
            active: 'Inactive'
        }
    ]

    setEdit = (id) => {
        this.setState({ document: this.documents.find(doc => doc.id === id), view: true })
    }


    showFilters = () => {
        return this.state.filters ?
            <div className="container">
                <div className="header">
                    <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                    <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                </div>
                <div className="filters">
                    <div className="group-input">
                        <label htmlFor="">NAME</label>
                        <input type="text" name="" id="name" />
                    </div>
                    <div className="group-input">
                        <label htmlFor="">ACTIVE</label>
                        <select name="location" id="location-select">

                        </select>
                    </div>

                    <div className="group-input">
                        <label htmlFor="">LOCATION</label>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city1" value="1" />
                            <label htmlFor="city1">Location 1</label>
                        </div>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city2" value="2" />
                            <label htmlFor="city2">Location 2</label>
                        </div>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city3" value="3" />
                            <label htmlFor="city3">Location 3</label>
                        </div>
                    </div>


                    <div className="btn-container">
                        <button className="principal">Apply</button>
                    </div>
                </div>
            </div>

            :

            <div className="container">
                <div className="header">
                    <div className="btn-container">
                        <button
                            className="left"
                            onClick={() => { this.setState({ filters: true }) }}>
                            <i className="material-icons">arrow_back_ios</i>
                        Back
                        </button>
                    </div>
                    <h3 className="center">UPLOAD DOCUMENT</h3>
                    <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                </div>
                <div className="filters">
                    <div className="group-input">
                        <label htmlFor="">Name</label>
                        <input type="text" name="" id="name" />
                    </div>
                    <div className="group-input">
                        <label htmlFor="">Active</label>
                        <select name="location" id="location-select">

                        </select>
                    </div>

                    <div className="group-input">
                        <label htmlFor="">Location</label>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city1" value="1" />
                            <label htmlFor="city1">Location 1</label>
                        </div>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city2" value="2" />
                            <label htmlFor="city2">Location 2</label>
                        </div>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city3" value="3" />
                            <label htmlFor="city3">Location 3</label>
                        </div>
                    </div>
                    <div className="group-input">
                        <label htmlFor="">Attach document</label>
                        <input className="file-input-hidden" type="file" name="" id="file-input" />
                        <button onClick={this.handleClick} className="file-input-button"><i className="material-icons">attach_file</i>Attach document</button>
                    </div>

                    <div className="btn-container">
                        <button className="principal">Save</button>
                    </div>
                </div>
            </div>

    }

    render() {
        return (
            <React.Fragment>
                {
                    !this.state.view &&
                    <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                        {this.showFilters()}
                    </div>
                }


                {
                    this.state.view &&
                    <div className="aside-bar popup active info">
                        <div className="container">
                            <div className="header">
                                <div className="btn-container">
                                    <button
                                        className="left"
                                        onClick={() => { this.setState({ filters: true, view: false }) }}>
                                        <i className="material-icons">arrow_back_ios</i>
                                        Back
                                    </button>
                                </div>
                                <h3>EDIT DOCUMENT</h3>
                                <button id="close_popup" onClick={() => this.setState({ display_asidebar: false, view: false })}><i className="material-icons">close</i></button>
                            </div>
                            <div className="filters">
                                <div className="group-input">
                                    <label htmlFor="">NAME</label>
                                    <input disabled type="text" name="" value={this.state.document.name} id="name" />
                                </div>
                                <div className="group-input">
                                    <label htmlFor="">ACTIVE</label>
                                    <input disabled type="text" name="" value={this.state.document.active} id="name" />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Documents</h1>
                            <div className="route">Control &gt; Documents</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({ display_asidebar: true, view: false })}><i className="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <button onClick={() => { this.setState({ filters: false, display_asidebar: true, view: false }) }} className="btn principal"><i className="material-icons">add</i>Add Document</button>
                        </div>
                    </div>
                    <Table
                        data={
                            [
                                {
                                    id: 1,
                                    name: 'Document 1',
                                    active: true,
                                    locations: 'location 1, location 2, location 3'
                                },
                                {
                                    id: 2,
                                    name: 'Document 2',
                                    active: false,
                                    locations: 'location 1, location 2, location 3'
                                },
                                {
                                    id: 3,
                                    name: 'Document 3',
                                    active: true,
                                    locations: 'location 1, location 2, location 3'
                                },
                                {
                                    id: 4,
                                    name: 'Document 4',
                                    active: false,
                                    locations: 'location 1, location 2, location 3'
                                },
                            ]
                        }
                        columns={[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Name",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "Active",
                                name: "active",
                                bool: true
                            },
                            {
                                title: "Locations",
                                name: "locations",
                                bool: false
                            },
                        ]}

                        view={false}
                        edit={true}
                        delete={true}
                        download={true}
                        editFunction={this.setEdit}
                        deleteFunction=""
                        totalElements="47"

                    >

                    </Table>
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        )

    }
}