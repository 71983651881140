import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Calendar } from '../../partials/Calendar'

export class ChildrenOverviewPT extends Component {

    state = {
        display_asidebar: false,
        asiderbar_details: false,
        showDayDetail: false,
        view: 'week',
        selectedDay: {},
        user: {

        },
        segments: [
            {
                content: {
                    monday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    tuesday: {
                        color: 'gray',
                        num: 3,
                        total: 10,
                    },
                    wednesday: {
                        color: 'green',
                        num: 3,
                        total: 10,
                    },
                    thursday: {
                        color: 'green',
                        num: 5,
                        total: 10,
                    },
                    friday: {
                        color: 'green',
                        num: 8,
                        total: 10,
                    },
                    saturday: {
                        color: 'green',
                        num: '0',
                        total: 10,
                    },
                    sunday: {
                        color: 'green',
                        num: '0',
                        total: 10,
                    }
                },
                name: 'am',
                title: 'AM'
            },
            {

                content: {
                    monday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    tuesday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    wednesday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    thursday: {
                        color: 'red',
                        num: 12,
                        total: 10,
                    },
                    friday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    saturday: {
                        color: 'green',
                        num: '0',
                        total: 10,
                    },
                    sunday: {
                        color: 'green',
                        num: '0',
                        total: 10,
                    }
                },
                name: 'pm',
                title: 'PM'
            },
        ],
        data: {
            displayId: 1,
            monday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ],
            },
            tuesday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ]
            },
            wednesday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ],
            },
            thursday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ]
            },
            friday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/child_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/child_2.png',
                        extraData: '17',
                    }
                ]
            },
            saturday: {
                am: [
                ],
                pm: [
                ]
            },
            sunday: {
                am: [
                ],
                pm: [
                ]
            }
        },
        week: [
            {
                id: 1,
                title: 'MON',
                name: 'monday',
                day: 20201228
            },
            {
                id: 2,
                title: 'TUE',
                name: 'tuesday',
                day: 20201228
            },
            {
                id: 3,
                title: 'WED',
                name: 'wednesday',
                day: 20201228
            },
            {
                id: 4,
                title: 'THU',
                name: 'thursday',
                day: 20201228
            },
            {
                id: 5,
                title: 'FRI',
                name: 'friday',
                day: 20201228
            },
            {
                id: 6,
                title: 'SAT',
                name: 'saturday',
                day: 20201228
            },
            {
                id: 7,
                title: 'SUN',
                name: 'sunday',
                day: 20201228
            },
        ]
    }


    users = [
        {
            id: 2,
            firstName: 'Susan',
            lastName: 'G. Smith',
            prefName: 'Susan G. Smith',
            img: '/assets/child_2.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            gender: "Male",
            age: "24",
            group: "Group's name 1",
            address: '61 CliftonStreet , NAGAMBIE 3608',
            phone: '(12) 3456 7890',
            allergies: 'None',
            medConditions: 'None',
            medicines: 'None',
            dropOff: '07:30 am',
            pickUp: '12:30 pm'
        },

        {
            id: 5,
            firstName: 'John',
            lastName: 'H. Doe',
            prefName: 'John H. Doe',
            img: '/assets/child_1.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            gender: "Male",
            age: "17",
            group: "Group's name 2",
            address: '23th street',
            phone: '(08) 8968 6281',
            allergies: 'None',
            medConditions: 'None',
            medicines: 'None',
            dropOff: '09:30 am',
            pickUp: '02:30 pm'
        },
        {
            id: 3,
            firstName: 'Susan',
            lastName: 'G. Smith',
            prefName: 'Susan G. Smith',
            img: '/assets/child_2.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            gender: "Male",
            age: "24",
            group: "Group's name 1",
            address: '61 CliftonStreet , NAGAMBIE 3608',
            phone: '(12) 3456 7890',
            allergies: 'None',
            medConditions: 'None',
            medicines: 'None',
            dropOff: '07:30 am',
            pickUp: '12:30 pm'
        },

        {
            id: 6,
            firstName: 'John',
            lastName: 'H. Doe',
            prefName: 'John H. Doe',
            img: '/assets/child_1.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            gender: "Male",
            age: "17",
            group: "Group's name 2",
            address: '23th street',
            phone: '(08) 8968 6281',
            allergies: 'None',
            medConditions: 'None',
            medicines: 'None',
            dropOff: '09:30 am',
            pickUp: '02:30 pm'
        }
    ]

    planningBy = (e) => {
        const selected = e.target.value
        this.setState({
            view: selected
        })
    }


    showDayDetails = (item, params) => {

        this.setState({
            selectedDay: item,
            showDayDetail: true,
            currentDate: params
        })
    }



    showAsideBar = () => {
        if (!this.state.asiderbar_details) {
            return (
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">GROUP</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a group...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">PRODUCT</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a product...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LIFECYCLE PHASE</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose phase...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="group-input no-padding-bottom">
                                <div className="subtitle">AGE</div>
                            </div>
                            <div className="group-input col-2 no-padding-top">
                                <label htmlFor="">From</label>
                                <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />

                                <label htmlFor="" className="center">to</label>
                                <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">PLANNING BY</label>
                                <select onChange={this.planningBy} name="location" id="location-select">
                                    <option value="week" selected>Week</option>
                                    <option value="month">Month</option>
                                </select>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            console.log(this.state);
            return (
                <div className="aside-bar popup active info">
                    <div className="container aside-bar-info-active">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => this.setState({ asiderbar_details: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                            </div>
                        </div>
                        <div className="img-container">
                            <div className="circle">
                                <img src={this.state.user.img} alt="" />
                            </div>
                        </div>
                        <div className="subtitle">INFO</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">First Name</label>
                                <input type="text" name="" value={this.state.user.firstName} disabled id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Last Name</label>
                                <input type="text" value={this.state.user.lastName} disabled id="text" />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Pref Name</label>
                                <input type="text" value={this.state.user.prefName} disabled id="text" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Age</label>
                                <input type="text" value={this.state.user.age} disabled id="text" />
                            </div>
                        </div>

                        <div className="group-input">
                            <label htmlFor="">Group</label>
                            <input type="text" value={this.state.user.group} disabled id="text" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Address</label>
                            <input type="text" value={this.state.user.address} disabled id="text" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Phone</label>
                            <input type="text" value={this.state.user.phone} disabled id="text" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Caretakers</label>
                            {
                                this.state.user.caretakers.map((caretaker) => (
                                    <p className="caretaker-items"> <span className="material-icons view">remove_red_eye</span> {caretaker.name}</p>
                                ))
                            }
                        </div>

                        <div className="subtitle">HEALTH</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Allergies</label>
                                <input type="text" value={this.state.user.allergies} disabled id="text" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Med Conditions</label>
                                <input type="text" value={this.state.user.medConditions} disabled id="text" />
                            </div>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Medicines/Schedules</label>
                            <input type="text" value={this.state.user.medicines} disabled id="text" />
                        </div>

                        <div className="subtitle">TIMES</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Drop Off</label>
                                <input type="text" value={this.state.user.dropOff} disabled id="text" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Pick Up</label>
                                <input type="text" value={this.state.user.pickUp} disabled id="text" />
                            </div>
                        </div>

                        <div className="btn-container dissapear">
                            <button className="principal">Save</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    showDetails = (id) => {
        console.log(this.users.find(user => user.id === id));
        this.setState({
            asiderbar_details: true,
            user: this.users.find(user => user.id === id)
        })
    }

    displayAsidebar = () => {
        this.setState({
            display_asidebar: true
        })
    }

    render() {
        return (
            <React.Fragment>
                {!this.state.showDayDetail && this.showAsideBar()}
                {
                    this.state.showDayDetail &&
                    <div className="aside-bar popup active info">
                        <div className="container aside-bar-info-active">
                            <div className="header">
                                <div className="btn-container">
                                    <button className="left" onClick={() => this.setState({ showDayDetail: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                                </div>
                            </div>

                            <div className="aside-bar-segments">
                                <h2>{this.state.currentDate}</h2>

                                <div className="aside-bar-segment">
                                    <div className={
                                        this.state.selectedDay.am.num === this.state.selectedDay?.am.total ?
                                            "aside-bar-segment-info green" :
                                            this.state.selectedDay.am.num > this.state.selectedDay?.am.total ?
                                                "aside-bar-segment-info red" : 'aside-bar-segment-info empty'
                                    }>
                                        <p>AM {this.state.selectedDay?.am.num}/{this.state.selectedDay?.am.total}</p>
                                    </div>

                                    <div className="aside-bar-segment-items">
                                        {
                                            this.state.selectedDay?.am?.data?.map((item) => (
                                                <div className="aside-bar-segment-item">
                                                    <div className="segment-item-img">
                                                        <img src={item.img} alt="" />
                                                    </div>

                                                    <p>{item.name} <span>({item.extraData})</span></p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="aside-bar-segment">
                                    <div className={
                                        this.state.selectedDay.pm.num === this.state.selectedDay?.pm.total ?
                                            "aside-bar-segment-info green" :
                                            this.state.selectedDay.pm.num > this.state.selectedDay?.pm.total ?
                                                "aside-bar-segment-info red" : 'aside-bar-segment-info empty'
                                    }>
                                        <p>PM {this.state.selectedDay?.pm.num}/{this.state.selectedDay?.pm.total}</p>
                                    </div>
                                    <div className="aside-bar-segment-items">
                                        {
                                            this.state.selectedDay?.pm?.data?.map((item) => (
                                                <div className="aside-bar-segment-item">
                                                    <div className="segment-item-img">
                                                        <img src={item.img} alt="" />
                                                    </div>

                                                    <p>{item.name} <span>({item.extraData})</span></p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="btn-container dissapear">
                                <button className="principal">Save</button>
                            </div>
                        </div>
                    </div>
                }
                <div className="content">
                    <Calendar
                        title="Children Overview"
                        subtitle="Planning Tools > Children Overview"
                        data={this.state.data}
                        segments={this.state.segments}
                        week={this.state.week}
                        showDetails={this.showDetails}
                        view={this.state.view}
                        displayAsidebar={this.displayAsidebar}
                        showDayDetails={this.showDayDetails}
                    />
                </div>
            </React.Fragment>
        )
    }
}
