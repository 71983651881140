import React, { Component } from 'react';
import RouterApp from './RouterApp'
import logo from './logo.svg';
import './css/App.scss';

class App extends Component {
  state = {
    dark: false,
    loading: false,
    loggued: false
  }

  logguing = () => {
    this.setState({
     loading: true
    });
    setTimeout(function(){
           this.setState({loading:false, loggued: true});
     }.bind(this),2000);
  }
  logOut = () => {
   this.setState({
    loading: true
   });
   setTimeout(function(){
          this.setState({loading:false, loggued: false});
    }.bind(this),5000);
 }

  showContent = () => {
    if (this.state.loading) {
      return (
        <React.Fragment>
          <div id="login">
            <div className="spinner"></div>
          </div>
        </React.Fragment>
      )
    } else {
      if (this.state.loggued) {
        return (
          <RouterApp />
        )
      } else {
        return (
          <React.Fragment>
            <div id="login">
              <div className="container">
                <img src={logo} alt="logo_wondergarden" />
                <form action="">
                  <div className="form-group">
                    <label htmlFor="">Email</label>
                    <input type="text" name="" placeholder="test@wondergarden.com" id="" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Password</label>
                    <input type="password" name="" placeholder="" id="" />
                  </div>
                  <div className="form-group checkbox">
                    <input type="checkbox" name="" id="remember_login" />
                    <label htmlFor="remember_login">Remember me</label>
                  </div>
                  <div className="button-container">
                    <button className="primary" onClick={()=>this.logguing()}>Log in</button>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        )
      }
    }
  }

  render() {
    return (
      <React.Fragment>
          {this.showContent()}
      </React.Fragment>
    );
  }
}

export default App;
