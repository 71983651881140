import React, { Component } from 'react';

class OccupancyChart extends Component {



    render(){
        let widthItems = (100/this.props.data.length)+"%";
        return ( 
            <React.Fragment>
                <div id="occupancy">
                <div className="occupancy-chart">
                    {
                        this.props.data.map(item => (
                            <div className={item.value === 100 ? "item green" : (item.value > 100 ? "item red" : "item gray")} style={{height: item.value+"%", width: widthItems}}>{item.value}%</div>
                        ))
                    }
                </div>
                <div className="occupancy-footer">
                    {
                        this.props.data.map(item => (
                            <div className={item.value === 100 ? "item green" : (item.value > 100 ? "item red" : "item gray")} style={{width: widthItems}}>{item.name}</div>
                        ))
                    }
                </div>
                <div className="percent50"></div>
                <div className="percent100"></div>
                </div>
            </React.Fragment> 
        );
    }
}

export default OccupancyChart;