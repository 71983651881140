import React, { Component } from 'react';

class SelectorPerson extends Component {
    state = {
        trSelected : 0
    }
    printRows = () =>{
        if(this.props.data.length !== 0){
            return(
                <tbody>
                        {this.props.data.map((item, key) => (
                            <tr>
                                {this.props.view && <td><button className="view"><i className="material-icons">visibility</i></button></td>}
                                {this.props.remove && <td><button className="remove"><i className="material-icons">remove</i></button></td>}
                                {this.printTd(key)}
                                
                            </tr>
                        ))}
                </tbody>
            );
        }else{
            return(
                <tbody>
                    <tr>
                        <td colSpan={this.props.columns.length+1} rowSpan="1" className="error">No records</td>
                    </tr>
                </tbody>
            );
        }
    }

    printTd = (i) =>{
        return(
            <React.Fragment>
                {this.props.columns.map(column => (
                    <td>
                        {
                        (this.props.data[i][column.name] ? this.props.data[i][column.name] :'')
                        }
                    </td>
                ))}
            </React.Fragment>
        ); 
        
    }

    printColumns = () =>{
        if(this.props.columns.length !== 0){
            return(
                this.props.columns.map(column => (
                    <th>{column.title}</th>
                ))
            );
          
        }
    }

    render(){
        return ( 
            <React.Fragment>
                {this.props.onlyView &&
                <div className="searcher">
                    <div className="container">
                    <input type="search" name="" id="search" autoComplete="off" placeholder="Search..."/>
                    <div className="search-container">
                        {!this.props.parent ? 
                        <React.Fragment>
                        <div className="result">
                        <div className="img-circle">
                            <img src="/assets/caretaker_2.png" alt="teacher"/>
                        </div>
                        <div className="text">
                            <h5>Susan H. Doe</h5>
                        </div>
                        </div>
                        <div className="result">
                        <div className="img-circle">
                            <img src="/assets/caretaker_1.png" alt="teacher"/>
                        </div>
                        <div className="text">
                            <h5>Charlie G. Bloom</h5>
                        </div>
                        </div>
                        </React.Fragment>
                        : 
                        <React.Fragment>
                        <div className="result">
                        <div className="img-circle">
                            <img src="/assets/child_2.png" alt="teacher"/>
                        </div>
                        <div className="text">
                            <h5>Susan H. Doe</h5>
                            <span>Group's name 1</span>
                        </div>
                        </div>
                        <div className="result">
                        <div className="img-circle">
                            <img src="/assets/child_1.png" alt="teacher"/>
                        </div>
                        <div className="text">
                            <h5>Charlie G. Bloom</h5>
                            <span>Group's name 2</span>
                        </div>
                        </div>
                        </React.Fragment>
                        }
                        
                    </div>
                    </div>

                </div>
                }
                <div className="table-card">
                    <table>
                        {this.props.header && 
                            <thead>
                                    <tr>
                                        {this.props.view && <th></th>}
                                        {this.props.remove && <th></th>}
                                        {this.printColumns()}
                                    </tr>
                            </thead>
                        }
                        {this.printRows()}
                    </table>
                </div>
                
            </React.Fragment> 
        );
    }
}

export default SelectorPerson;