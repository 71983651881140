import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from './partials/Header';
import Categories from './partials/Categories'
import Locations from './components/locations/Locations'
import LocationOverview from './components/locations/LocationOverview'
import CreateLocation from './components/locations/CreateLocation'
import GroupOverview from './components/locations/GroupOverview';
import ProductOverview from './components/locations/ProductOverview';
import CreateProduct from './components/locations/CreateProduct';
import ChildrenOverview from './components/children/ChildrenOverview';
import Children from './components/children/Children';
import ContractList from './components/children/ContractList';
import AddChild from './components/children/AddChild';
import PlanningTools from './components/planning-tools/PlanningTools';
import { ChildrenOverviewPT } from './components/planning-tools/ChildrenOverviewPT';
import { ActivitiesOverview } from './components/planning-tools/ActivitiesOverview';
import { WaitingListOverview } from './components/planning-tools/WaitingListOverview';
import { FamilyPanel } from './components/family-panel/FamilyPanel';
import { Documents } from './components/family-panel/Documents';
import { Images } from './components/family-panel/Images';
import { Messages } from './components/family-panel/Messages';
import { LunchMenu } from './components/family-panel/LunchMenu';
import { TeacherOverview } from './components/hr-finances/TeacherOverview';
import { ShiftPlanning } from './components/hr-finances/ShiftPlanning';
import { WorkingHours } from './components/hr-finances/WorkingHours';
import { LeaveDaysForm } from './components/hr-finances/LeaveDaysForm';
import { Budget } from './components/hr-finances/Budget';
import { HRFinances } from './components/hr-finances/HRFinances';
import { Wage } from './components/hr-finances/Wage';
import { AdminTools } from './components/admin-tools/AdminTools';
import { UserManagement } from './components/admin-tools/UserManagement';
import { Parameters } from './components/admin-tools/Parameters';
import { Templates } from './components/admin-tools/Templates';
import { Activities } from './components/family-panel/Activities';
import AddContract from './components/children/AddContract';
import Caretakers from './components/caretakers/Caretakers';
import CaretakersOverview from './components/caretakers/CaretakersOverview';
import Invoices from './components/caretakers/Invoices';
import Leads from './components/caretakers/Leads';
import Reports from './components/reports/Reports';
import ReportsChildren from './components/reports/ReportsChildren';
import ReportsTeachers from './components/reports/ReportsTeachers';
import ReportsFinancial from './components/reports/ReportsFinancial';
import Report from './components/reports/Report';
import AddTeacher from './components/hr-finances/AddTeacher';
import AddUser from './components/admin-tools/AddUser';
import { Control } from './components/control/Control';
import CompleteCheckList from './components/control/CompleteCheckList';
import { DocumentsControl } from './components/control/DocumentsControl';
import CheckListOverview from './components/control/CheckListOverview';
import CheckList from './components/control/CheckList';
import ReportsOccupancy from './components/reports/ReportsOccupancy';
import Home from './Home';
import AddCaretaker from './components/caretakers/AddCaretaker';
import ScrollTop from './partials/ScrollTop';

class RouterApp extends Component {

  render() {
    return (
      <div>
        <Router>
          <ScrollTop />
          <Header />
          <Categories />
          <div id="todo">
            <Switch>
                  <Route path="/control/overview/:type/:id" component={CheckList}/>
                  <Route path="/control/overview/:type" component={CheckList}/>
                  <Route path="/control/overview" component={CheckListOverview}/>
                  <Route path="/control/documents" component={DocumentsControl}/>
                  <Route path="/control/complete" component={CompleteCheckList}/>
                  <Route path="/control" component={Control}/>

                  <Route path="/reports/:subcategory/:type" component={Report}/>
                  <Route path="/reports/occupancy" component={ReportsOccupancy}/>
                  <Route path="/reports/financial" component={ReportsFinancial}/>
                  <Route path="/reports/teachers" component={ReportsTeachers}/>
                  <Route path="/reports/children" component={ReportsChildren}/>
                  <Route path="/reports" component={Reports}/>

                  <Route path="/caretakers/leads" component={Leads}/>
                  <Route path="/caretakers/invoices" component={Invoices}/>
                  <Route path="/caretakers/overview/:type/:id" component={AddCaretaker}/>
                  <Route path="/caretakers/overview/:type" component={AddCaretaker}/>
                  <Route path="/caretakers/overview" component={CaretakersOverview}/>
                  <Route path="/caretakers" component={Caretakers}/>

                  <Route path="/children/contracts/:type/:id" component={AddContract}/>
                  <Route path="/children/contracts/:type" component={AddContract}/>
                  <Route path="/children/contracts" component={ContractList}/>
                  <Route path="/children/overview/:type/:id" component={AddChild}/>
                  <Route path="/children/overview/:type" component={AddChild}/>
                  <Route path="/children/overview" component={ChildrenOverview}/>
                  <Route path="/children" component={Children}/>
                  
                  <Route path="/locations/products/:type/:id" component={CreateProduct}/>
                  <Route path="/locations/products/:type" component={CreateProduct}/>
                  <Route path="/locations/products" component={ProductOverview}/>
                  <Route path="/locations/groups" component={GroupOverview}/>
                  <Route path="/locations/overview/:type/:id" component={CreateLocation}/>
                  <Route path="/locations/overview/:type" component={CreateLocation}/>
                  <Route path="/locations/overview" component={LocationOverview}/>
                  <Route path="/locations" component={Locations}/>
      

                  <Route path="/planning-tools/children" component={ChildrenOverviewPT} />
                  <Route path="/planning-tools/waiting-list" component={WaitingListOverview} />
                  <Route path="/planning-tools/activities" component={ActivitiesOverview} />
                  <Route path="/planning-tools/children/:type/:id" component={ChildrenOverviewPT} />
                  <Route path="/planning-tools" component={PlanningTools} />

                  <Route path="/family-panel/documents" component={Documents} />
                  <Route path="/family-panel/images" component={Images} />
                  <Route path="/family-panel/messages" component={Messages} />
                  <Route path="/family-panel/lunch-menu" component={LunchMenu} />
                  <Route path="/family-panel/activities" component={Activities} />
                  <Route path="/family-panel" component={FamilyPanel} />


                  <Route path="/hr-finances/teacher/:type/:id" component={AddTeacher} />
                  <Route path="/hr-finances/teacher/:type" component={AddTeacher} />
                  <Route path="/hr-finances/teacher" component={TeacherOverview} />
                  <Route path="/hr-finances/shift" component={ShiftPlanning} />
                  <Route path="/hr-finances/working-hours" component={WorkingHours} />
                  <Route path="/hr-finances/leave-days" component={LeaveDaysForm} />
                  <Route path="/hr-finances/budget" component={Budget} />
                  <Route path="/hr-finances/employee-payments" component={Wage} />
                  <Route path="/hr-finances" component={HRFinances} />


                  <Route path="/admin-tools/users/:type/:id" component={AddUser} />
                  <Route path="/admin-tools/users/:type" component={AddUser} />
                  <Route path="/admin-tools/users" component={UserManagement} />
                  <Route path="/admin-tools/parameters" component={Parameters} />
                  <Route path="/admin-tools/templates" component={Templates} />
                  <Route path="/admin-tools" component={AdminTools} />

              <Route path="/" component={Home}/>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default RouterApp;