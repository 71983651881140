import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class ContractList extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false
    }

    render(){
        return ( 
            <React.Fragment>
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">CHILD NAME</label>
                                <input type="text" name="" id=""/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">STATUS</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Signed</option>
                                    <option value="1">Signed</option>
                                </select>
                            </div>
                            <div className="group-input no-padding-bottom">
                                <div className="subtitle">START DATE</div>
                            </div>
                            <div className="flex">
                                <div className="flex-6">
                                <div className="group-input col-2 no-padding-top">
                                <label htmlFor="">From</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                </div>
                                <div className="flex-6">
                                <div className="group-input col-2 no-padding-top">
                                        
                                        <label htmlFor="" className="center">to</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                </div>
                            </div>

                            <div className="group-input no-padding-bottom">
                            <div className="subtitle">END DATE</div>
                            </div>
                            <div className="flex">
                                <div className="flex-6">
                                <div className="group-input col-2 no-padding-top">
                                <label htmlFor="">From</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                </div>
                                <div className="flex-6">
                                <div className="group-input col-2 no-padding-top">
                                        
                                        <label htmlFor="" className="center">to</label>
                                        <input type="date" name="" defaultValue="test@wondergarden.com" id="name"/>
                                    </div>
                                </div>
                            </div>
                                    <div className="group-input">
                                <label htmlFor="">ACTIVE</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Active</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Non-active</label>
                                </div>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="content">
                      <div className="content-head">
                        <div className="title">
                          <h1>Contract List</h1>
                          <div className="route">Children &gt; Contract List</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({display_asidebar: true})}><i className="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <Link to="/children/contracts/create" className="btn principal"><i className="material-icons">add</i>Add Contract</Link>
                        </div>
                      </div>
                      <Table 
                        data = {[
                            {
                                id: 1,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            },
                            {
                                id: 2,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            },
                            {
                                id: 3,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            },
                            {
                                id: 4,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            },
                            {
                                id: 5,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            },
                            {
                                id: 6,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: false
                            },
                            {
                                id: 1,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: false
                            },
                            {
                                id: 2,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            },
                            {
                                id: 3,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            },
                            {
                                id: 4,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            },
                            {
                                id: 5,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: false
                            },
                            {
                                id: 6,
                                name: [{
                                    principal: "Charlie Bloom",
                                    sub: "Brisbane",
                                    image: "/assets/child_1.png"
                                }],
                                location: "Brisbane",
                                startdate: "01/03/2019",
                                enddate: "12/08/2019",
                                caretaker: "Susan Bloom",
                                product: "Kindergarden",
                                status: "Signed",
                                active: true
                            }
                        ]}
                        columns = {[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Child name",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "Location",
                                name: "location",
                                bool: false
                            },
                            {
                                title: "Start date",
                                name: "startdate",
                                bool: false
                            },
                            {
                                title: "End date",
                                name: "enddate",
                                bool: false
                            },
                            {
                                title: "Main Caretaker",
                                name: "caretaker",
                                bool: false
                            },
                            {
                                title: "Product",
                                name: "product",
                                bool: false
                            },
                            {
                                title: "Status",
                                name: "status",
                                bool: false
                            }
                            ,
                            {
                                title: "Active",
                                name: "active",
                                bool: true
                            }
                        ]}
                        viewFunction = {(id)=>{this.props.history.push(`/children/contracts/view/${id}`)}}
                        view = {true}
                        edit = {false}
                        delete = {false}
                        editFunction =  {(id)=>{this.props.history.push(`/children/contracts/edit/${id}`)}}
                        deleteFunction = ""
                        totalElements = "47"
                      />
                      <Pagination 
                        page = {this.state.page}
                        per_page = {this.state.per_page}
                        total_elements = {this.state.total_elements}
                        total_pages = {this.state.total_pages}
                        change_page = {page => this.setState({page})}
                        />
                    </div>
            </React.Fragment> 
        );
    }
}

export default ContractList;