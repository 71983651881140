import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";
  import moment from 'moment';
import CheckListForm from './partials/CheckListForm';

class CompleteCheckList extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false,
        asiderbar_create: false,
        selectedLocation: 0,
        selectedGroup: 0,
        selectedGroupName: '',
        selectedChild: 0,
        selectedChildName: '',
        checklist1: false,
        checklist2: false,
        checklist3: false,
        excursion: false,
        locations: [
            {
                id: 1,
                name: 'Location name 1'
            },
            {
                id: 2,
                name: 'Location name 2'
            },
            {
                id: 3,
                name: 'Location name 3'
            },
            {
                id: 4,
                name: 'Location name 4'
            },
        ],
        groups: [
            {
                id: 1,
                name: 'Group name 1'
            },
            {
                id: 2,
                name: 'Group name 2'
            },
            {
                id: 3,
                name: 'Group name 3'
            },
            {
                id: 4,
                name: 'Group name 4'
            },
        ],
        children: [
            {
                id: 1,
                name: 'John H. Doe',
                img: '/assets/child_1.png',
                complete: false,
                absent: false,
            },
            {
                id: 2,
                name: 'Susan G. Smith',
                img: '/assets/child_2.png',
                complete: false,
                absent: false,
            },
            {
                id: 3,
                name: 'John H. Doe',
                img: '/assets/child_1.png',
                complete: false,
                absent: false,
            },
            {
                id: 4,
                name: 'Susan G. Smith',
                img: '/assets/child_2.png',
                complete: false,
                absent: false,
            },
        ]
    }



    showFilters = () => {
        if(this.state.selectedLocation === 0){
            return(
                <React.Fragment>
                 <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> LOCATIONS</h3>
                        </div>

                        <div className="button-filters">
                            {
                                this.state.locations.map((location) => (
                                    <div onClick={() => { this.setState({ selectedLocation: location.id }) }} className={this.state.selectedLocation === location.id ? "button-filter selected-filter" : "button-filter"}>
                                        <p> {location.name}</p>
                                        <i className="material-icons">
                                            arrow_forward_ios
                                        </i>
                                    </div>

                                ))
                            }
                        </div>
                        </div>
                </React.Fragment>
            );
        }
        if(this.state.selectedGroup === 0){
            return(
                <React.Fragment>
                    <div className="container">
                    <div className="header">
                        <div className="btn-container">
                            <button className="left" onClick={() => this.setState({selectedLocation: 0})}><i className="material-icons">arrow_back_ios</i> Back</button>
                            
                        </div>
                        <h3><i className="material-icons edit">filter_alt</i> GROUPS</h3>
                    </div>

                    <div className="button-filters">
                        {
                            this.state.groups.map((group) => (
                                <div onClick={() => { this.setState({ selectedGroup: group.id, selectedGroupName: group.name }) }} className={this.state.selectedGroup === group.id ? "button-filter selected-filter" : "button-filter"}>
                                    <p> {group.name}</p>
                                    <i className="material-icons">
                                        arrow_forward_ios
                                    </i>
                                </div>

                            ))
                        }
                    </div>
                    </div>
                </React.Fragment>
            );
        }
        return(
            <React.Fragment>
                 <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => this.setState({selectedGroup: 0, selectedChild: 0, selectedChildName: ''})}><i className="material-icons">arrow_back_ios</i> Back</button>
                                
                            </div>
                            <h3 className="center">{this.state.selectedGroupName}</h3>
                            <h2 className="center">{moment().format('ll')}</h2>
                        </div>

                        <div className="button-filters children">
                            <div onClick={() => { this.setState({ selectedChild: 0, selectedChildName: '' }) }} className={this.state.selectedChild === 0 ? "button-filter selected-filter" : "button-filter"}>
                               <p>Group Checklist</p>
                               <i className="material-icons">
                                    arrow_forward_ios
                                </i>
                            </div>
                            <h4 className="center">CHILDREN'S CHECKLISTS</h4>
                            <div className="children-checklist">
                            {
                                this.state.children.map((child) => (
                                    <div onClick={() => { this.setState({ selectedChild: child.id, selectedChildName: child.name, checklist1: false, checklist2: false, checklist3: false }) }} className={child.complete ? 'button-filter with-image green' : (child.absent ? 'button-filter with-image red': (this.state.selectedChild === child.id ? "button-filter selected-filter with-image" : "button-filter with-image"))}>
                                        <img src={child.img} alt=""/>
                                        <p> {child.name}</p>
                                        <i className="material-icons">
                                            {child.complete ? 'check' : (child.absent ? 'clear': 'arrow_forward_ios')}
                                        </i>
                                    </div>

                                ))
                            }
                            </div>
                        </div>
                        </div>
             
            </React.Fragment>
        );
    }
    changeCheckList1 = () =>{
        this.setState({checklist1: true})
        this.changeStatusChild(1);
    }
    changeCheckList2 = () =>{
        this.setState({checklist2: true})
        this.changeStatusChild(2);
    }
    changeCheckList3 = () =>{
        this.setState({checklist3: true})
        this.changeStatusChild(3);
    }

    changeStatusChild = (id) =>{
        if(id === 1){
            if(this.state.checklist3 && this.state.checklist2){
                var str=this.state.children;
                for(let i = 0; i < this.state.children.length; i++){
                    if(str[i].id === this.state.selectedChild){
                        str[i].complete=true;
                    }
                }
                console.log(str);
                this.setState({children:str});
            }
        }
        if(id === 2){
            if(this.state.checklist3 && this.state.checklist1 ){
                var str=this.state.children;
                for(let i = 0; i < this.state.children.length; i++){
                    if(str[i].id === this.state.selectedChild){
                        str[i].complete=true;
                    }
                }
                console.log(str);
                this.setState({children:str});
            }
        }
        if(id === 3){
            if(this.state.checklist1 && this.state.checklist2){
                var str=this.state.children;
                for(let i = 0; i < this.state.children.length; i++){
                    if(str[i].id === this.state.selectedChild){
                        str[i].complete=true;
                    }
                }
                console.log(str);
                this.setState({children:str});
            }
        }
        
    }
    changeStatusChildAbsent = () =>{
                var str=this.state.children;
                for(let i = 0; i < this.state.children.length; i++){
                    if(str[i].id === this.state.selectedChild){
                        str[i].absent=true;
                    }
                }
                console.log(str);
                this.setState({children:str});
        
    }
    render(){
        return ( 
            <React.Fragment>
                    <div className={this.state.display_asidebar ? "aside-bar active" : "aside-bar"}>
                        {this.showFilters()}
                    </div>
                    <div className="content">
                      <div className="content-head responsive without-title">
                        <div className="title">
                          <h1> {this.state.selectedChildName === '' ? "Complete Checklist" : this.state.selectedChildName}</h1>
                          <div className="route">Control &gt; Complete Checklist {this.state.selectedChildName === '' ? '' : "> "+this.state.selectedChildName}</div>
                        </div>
                        {
                            this.state.selectedChild !== 0 &&
                            <div className="input-container">
                                <div className="group-input">
                                    <div className="recordby">
                                        <div className="name">Susan Bloom</div>
                                        <img src="/assets/caretaker_1.png" alt=""/>
                                    </div>
                                    <label htmlFor="">Drop off</label>
                                    <input type="time" name="" defaultValue="10:04:00"/>
                                    <button className="red" onClick={() => this.changeStatusChildAbsent()}>Absent</button>
                                </div>
                                <div className="group-input">
                                    
                                    <label htmlFor="">Pick up</label>
                                    <input type="time" name="" />
                                    <button>Add times</button>
                                </div>
                            </div>
                        }
                        {
                            this.state.selectedChild === 0 && this.state.selectedGroup !== 0 ?
                            <div className="input-container">
                                <div className="group-input">
                                    <button className="red" onClick={() => this.setState({excursion: true})}>Excursion mode</button>
                                </div>
                            </div> : ''
                        }

                      </div>
                      <div className="content-body">
                        <div className="flex">
                            { this.state.excursion && this.state.selectedChild === 0 ?
                            <div className="flex-6 left">
                                <div className="card">
                                    <div className="card-title">EXCURSION</div>
                                    <div id="excursion_table">
                                        <div className="table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Child</th>
                                                        <th>Before excursion</th>
                                                        <th>In excursion</th>
                                                        <th>After excursion</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.children.map((child) => (
                                                        <tr>
                                                            <td>
                                                                <div className="data">
                                                                    <img src={child.img} alt=""/>
                                                                    <p> {child.name}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="excursion-record">
                                                                    <b>09:03:46 AM</b>
                                                                    <div className="data">
                                                                        <div>
                                                                    <img src="/assets/caretaker_1.png" alt=""/></div>
                                                                    <p>Susan Bloom</p>
                                                                    </div>
                                                                        
                                                                    </div>
                                                            </td>
                                                            <td>
                                                                <div className="group-input">
                                                                <div className="group-check">
                                                                    <input type="checkbox" name="" id=""/>
                                                                </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                            <div className="group-input">
                                                                <div className="group-check">
                                                                    <input type="checkbox" name="" id=""/>
                                                                </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    ))
                                                }
                                                    <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <button>Save</button>
                                                            </td>
                                                            <td>
                                                                <button>Save</button>
                                                            </td>
                                                        </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                            }
                            <div className={this.state.excursion && this.state.selectedChild === 0 ? "flex-6 right column": "flex-12 column"}>
                              {this.state.selectedGroup !== 0 && 
                              <React.Fragment>
                                  <CheckListForm
                                        name = "Checklist Name 1"
                                        order = {1}
                                        data = {[]}
                                        answered = {this.state.checklist1}
                                        function = {this.changeCheckList1}
                                    />
                                    <CheckListForm
                                        name = "Checklist Name 2"
                                        order = {2}
                                        data = {[]}
                                        function = {this.changeCheckList2}
                                        answered = {this.state.checklist2}
                                    />
                                    <CheckListForm
                                        name = "Checklist Name 3"
                                        order = {3}
                                        data = {[]}
                                        answered = {this.state.checklist3}
                                        function = {this.changeCheckList3}
                                    />
                            </React.Fragment>}
                            </div>
                        </div>
                      </div>
                      
                    </div>
            </React.Fragment> 
        );
    }
}

export default CompleteCheckList;