import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../partials/Table";

export class UserManagement extends Component {

    state = {

        display_asidebar: false,
        filters: true,

    }


    showFilters = () => {
        return this.state.filters ?
            <div className="container">
                <div className="header">
                    <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                    <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                </div>
                <div className="filters">
                    <div className="group-input">
                        <label htmlFor="">SEARCH</label>
                        <input type="text" name="" id="name" />
                    </div>

                    <div className="group-input">
                        <label htmlFor="">LOCATION</label>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city1" value="1" />
                            <label htmlFor="city1">Location 1</label>
                        </div>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city2" value="2" />
                            <label htmlFor="city2">Location 2</label>
                        </div>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city3" value="3" />
                            <label htmlFor="city3">Location 3</label>
                        </div>
                    </div>

                    <div className="group-input">
                        <label htmlFor="">PROFILE</label>
                        <select name="location" id="location-select">

                        </select>
                    </div>

                    


                    <div className="btn-container">
                        <button className="principal">Apply</button>
                    </div>
                </div>
            </div>

            :

            <div className="container">
                <div className="header">
                    <div className="btn-container">
                        <button
                            className="left"
                            onClick={() => { this.setState({ filters: true }) }}>
                            <i className="material-icons">arrow_back_ios</i>
                        Back
                        </button>
                    </div>
                    <h3 className="center">UPLOAD DOCUMENT</h3>
                    <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                </div>
                <div className="filters">
                    <div className="group-input">
                        <label htmlFor="">Name</label>
                        <input type="text" name="" id="name" />
                    </div>
                    <div className="group-input">
                        <label htmlFor="">Active</label>
                        <select name="location" id="location-select">

                        </select>
                    </div>

                    <div className="group-input">
                        <label htmlFor="">Location</label>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city1" value="1" />
                            <label htmlFor="city1">Location 1</label>
                        </div>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city2" value="2" />
                            <label htmlFor="city2">Location 2</label>
                        </div>
                        <div className="group-check">
                            <input type="checkbox" name="city" id="city3" value="3" />
                            <label htmlFor="city3">Location 3</label>
                        </div>
                    </div>
                    <div className="group-input">
                        <label htmlFor="">Attach document</label>
                        <input type="text" name="" id="name" />
                    </div>

                    <div className="btn-container">
                        <button className="principal">Save</button>
                    </div>
                </div>
            </div>

    }

    render() {
        return (
            <React.Fragment>
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    {this.showFilters()}
                </div>
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>User Management</h1>
                            <div className="route">Admin Tools &gt; User Management</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({ display_asidebar: true })}><i className="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <Link to="/admin-tools/users/create" className="btn principal"><i className="material-icons">add</i>New user</Link>
                        </div>
                    </div>
                    <Table
                        data={
                            [
                                {
                                    id: 1,
                                    name: 'Susan H. Bloom',
                                    email: 'test@wondergarden.com',
                                    profile: 'Superadmin',
                                    active: true,
                                    location: 'location 1'
                                },
                                {
                                    id: 2,
                                    name: 'Susan H. Bloom',
                                    email: 'test@wondergarden.com',
                                    profile: 'Superadmin',
                                    active: false,
                                    location: 'location 1'
                                },
                                {
                                    id: 3,
                                    name: 'Susan H. Bloom',
                                    email: 'test@wondergarden.com',
                                    profile: 'Superadmin',
                                    active: true,
                                    location: 'location 1'
                                },
                                {
                                    id: 4,
                                    name: 'Susan H. Bloom',
                                    email: 'test@wondergarden.com',
                                    profile: 'Superadmin',
                                    active: false,
                                    location: 'location 1'
                                },
                            ]
                        }
                        columns={[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Name",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "Email",
                                name: "email",
                                bool: false
                            },
                            {
                                title: "Profile",
                                name: "profile",
                                bool: false
                            },
                            {
                                title: "Location",
                                name: "location",
                                bool: false
                            },
                            {
                                title: "Active",
                                name: "active",
                                bool: true
                            },
                        ]}
                        view={true}
                        edit={true}
                        delete={true}
                        download={false}
                        deleteFunction=""
                        totalElements="47"
                        viewFunction={(id)=>{this.props.history.push(`/admin-tools/users/view/${id}`)}}
                        editFunction={(id)=>{this.props.history.push(`/admin-tools/users/edit/${id}`)}}
                    >
                    </Table>
                </div>
            </React.Fragment>
        )

    }
}