import React, { Component } from 'react';

class CustomizeSelect extends Component {
    state = {
        d0_5: true,
        d1: false,
        d1_5: false,
        d2: false,
        d2_5: false,
        d3: false,
        d3_5: false,
        d4: false,
        d4_5: false,
        d5: false
    }
    
    handleOnChange = (i) =>{
        if(i === 'd0_5'){
            this.setState({d0_5: true, d1: false, d1_5: false, d2: false, d2_5: false, d3: false, d3_5: false, d4: false, d4_5: false, d5: false});
        }
        if(i === 'd1'){
            this.setState({d0_5: false, d1: true, d1_5: false, d2: false, d2_5: false, d3: false, d3_5: false, d4: false, d4_5: false, d5: false})
        }
        if(i === 'd1_5'){
            this.setState({d0_5: false, d1: false, d1_5: true, d2: false, d2_5: false, d3: false, d3_5: false, d4: false, d4_5: false, d5: false})
        }
        if(i === 'd2'){
            this.setState({d0_5: false, d1: false, d1_5: false, d2: true, d2_5: false, d3: false, d3_5: false, d4: false, d4_5: false, d5: false})
        }
        if(i === 'd2_5'){
            this.setState({d0_5: false, d1: false, d1_5: false, d2: false, d2_5: true, d3: false, d3_5: false, d4: false, d4_5: false, d5: false})
        }
        if(i === 'd3'){
            this.setState({d0_5: false, d1: false, d1_5: false, d2: false, d2_5: false, d3: true, d3_5: false, d4: false, d4_5: false, d5: false})
        }
        if(i === 'd3_5'){
            this.setState({d0_5: false, d1: false, d1_5: false, d2: false, d2_5: false, d3: false, d3_5: true, d4: false, d4_5: false, d5: false})
        }
        if(i === 'd4'){
            this.setState({d0_5: false, d1: false, d1_5: false, d2: false, d2_5: false, d3: false, d3_5: false, d4: true, d4_5: false, d5: false})
        }
        if(i === 'd4_5'){
            this.setState({d0_5: false, d1: false, d1_5: false, d2: false, d2_5: false, d3: false, d3_5: false, d4: false, d4_5: true, d5: false})
        }
        if(i === 'd5'){
            this.setState({d0_5: false, d1: false, d1_5: false, d2: false, d2_5: false, d3: false, d3_5: false, d4: false, d4_5: false, d5: true})
        }
        this.props.function(i);
    }
    render(){
        return ( 
            <React.Fragment>
                <div className="select">
                    <div className="subtitle">Days</div>
                    <div className="container">
                        <div className={this.state.d0_5 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d0_5')}>0.5</div>
                        <div className={this.state.d1 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d1')}>1</div>
                        <div className={this.state.d1_5 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d1_5')}>1.5</div>
                        <div className={this.state.d2 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d2')}>2</div>
                        <div className={this.state.d2_5 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d2_5')}>2.5</div>
                        <div className={this.state.d3 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d3')}>3</div>
                        <div className={this.state.d3_5 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d3_5')}>3.5</div>
                        <div className={this.state.d4 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d4')}>4</div>
                        <div className={this.state.d4_5 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d4_5')}>4.5</div>
                        <div className={this.state.d5 ? "option selected" :"option"} onClick={()=>this.handleOnChange('d5')}>5</div>
                    </div>
                </div>
            </React.Fragment> 
        );
    }
}

export default CustomizeSelect;