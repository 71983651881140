import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export class FamilyPanel extends Component {
    render() {
        return (
            <React.Fragment>
                <Redirect to="/family-panel/documents" />
            </React.Fragment>
        );
    }
}