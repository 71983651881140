import React, { Component } from "react";

export class Messages extends Component {

    state = {
        display_asidebar: false,
        filters: true,
        newChat: false,
        chatSelected: false,
        chats: [
            {
                id: 1,
                name: 'John H. Doe',
                img: '/assets/child_1.png',
                date: '2020/10/04 04:56:AM',
                preview: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum, adipisci.',
                messages: [
                    {
                        userId: 1,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: 'today',
                        sender: true,
                        receiver: false
                    },
                    {
                        userId: 4,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: 'today',
                        sender: false,
                        receiver: true
                    },
                    {
                        userId: 1,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: 'today',
                        sender: true,
                        receiver: false
                    },
                    {
                        userId: 4,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: 'today',
                        sender: false,
                        receiver: true
                    }
                ]
            },
            {
                id: 2,
                name: 'Susan G. Smith',
                img: '/assets/child_2.png',
                date: '2020/10/04 04:56:AM',
                preview: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum, adipisci.',
                messages: [
                    {
                        userId: 1,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: '2020/10/04 04:56:AM',
                        sender: true,
                        receiver: false
                    },
                    {
                        userId: 4,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: '2020/10/04 04:56:AM',
                        sender: false,
                        receiver: true
                    }
                ]
            },
            {
                id: 3,
                name: 'John H. Doe',
                img: '/assets/child_1.png',
                date: '2020/10/04 04:56:AM',
                preview: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum, adipisci.',
                messages: [
                    {
                        userId: 1,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: '2020/10/04 04:56:AM',
                        sender: true,
                        receiver: false
                    },
                    {
                        userId: 4,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: '2020/10/04 04:56:AM',
                        sender: false,
                        receiver: true
                    }
                ]
            },
            {
                id: 4,
                name: 'Susan G. Smith',
                img: '/assets/child_2.png',
                date: '2020/10/04 04:56:AM',
                preview: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum, adipisci.',
                messages: [
                    {
                        userId: 1,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: '2020/10/04 04:56:AM',
                        sender: true,
                        receiver: false
                    },
                    {
                        userId: 4,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: '2020/10/04 04:56:AM',
                        sender: false,
                        receiver: true
                    }
                ]
            },
            {
                id: 5,
                name: 'John H. Doe',
                img: '/assets/child_1.png',
                date: '2020/10/04 04:56:AM',
                preview: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum, adipisci.',
                messages: [
                    {
                        userId: 1,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: '2020/10/04 04:56:AM',
                        sender: true,
                        receiver: false
                    },
                    {
                        userId: 4,
                        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.`,
                        date: '2020/10/04 04:56:AM',
                        sender: false,
                        receiver: true
                    }
                ]
            },
        ],
    }

    viewChat = (chat) => {
        this.setState({
            currentChat: chat,
            selectedChat: chat.id,
            newChat: false,
            chatSelected: true
        })
        console.log(chat.messages);
    }

    handleClick = () => {
        document.querySelector('#file-input').click()
    }

    setFiles = (e) => {
        const file = e.target.files[0]
        console.log(file.name);
        this.setState({
            files: [file.name]
        })
    }

    render() {
        return <React.Fragment>

            <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup chats-panel"}>
                <div className="container">

                    <div className="header">
                        <div className="chat-actions">
                            <button onClick={() => {
                                this.setState({
                                    newChat: true,
                                    currentChat: {},
                                    selectedChat: null,
                                    chatSelected: true
                                })
                            }}><span className="material-icons">
                                    send
                                </span> Send a new message</button>

                            <h4>LAST MESSAGES</h4>

                        </div>
                        <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                    </div>

                    <div className="chats">
                        {
                            this.state.chats.map(chat => (
                                <div onClick={() => { this.viewChat(chat) }} className={this.state.selectedChat === chat.id ? "chat selected-message" : "chat"}>
                                    <div className="chat-header">
                                        <div className="chat-header-img">
                                            <img src={chat.img} alt="" />
                                        </div>
                                        <div className="chat-header-info">
                                            <p>{chat.name}</p>
                                            <p>{chat.date}</p>
                                        </div>
                                        <span className="material-icons">
                                            chevron_right
                                        </span>
                                    </div>
                                    <div className="chat-msg">

                                        <p>{chat.preview}</p>

                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className="content">

                <div className="content-head">
                    <div className="title">
                        <h1>{this.state.currentChat?.name || 'Messages'}</h1>
                        <div className="route">Family Panel &gt; Messages</div>
                    </div>
                </div>

                <div className="mobile-chat-preview">
                    {
                        this.state.chats.map((chat) => {
                            return (
                                <div onClick={() => { this.viewChat(chat) }} className={this.state.selectedChat === chat.id ? "mobile-chat selected-chat" : "mobile-chat"}>
                                    <div className="mobile-img">
                                        <img src={chat.img} alt="" />
                                    </div>
                                    <p>{chat.name}</p>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="messages-panel">
                    {(!this.state.newChat) &&
                        <div  className="msg-wrapper">
                            {

                                this.state.currentChat?.messages.slice(0).reverse().map(chat => (
                                    <div className={chat.sender ? "current-chat-msg sender" : "current-chat-msg"}>
                                        <div className="current-chat-wrapper">
                                            <div className="current-chat-msg-img">
                                                <img src={this.state.currentChat.img} alt="" />
                                            </div>

                                            <div className="current-chat-content">
                                                <p className="current-chat-msg-content" >{chat.content}</p>
                                                <p className="current-chat-msg-date">{chat.date}</p>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    }

                    {
                        (this.state.newChat) &&
                        <div className="message-options">
                            <div className="group-input col-2">
                                <label htmlFor="">Type of message</label>
                                <select name="" id="" defaultValue="newsletter">
                                    <option value="newsletter">Newsletter</option>
                                    <option value="private">Private</option>
                                </select>
                            </div>
                            <div className="group-input col-2">
                                <label htmlFor="">Location</label>
                                <select name="" id="" defaultValue="brisbane">
                                    <option value="brisbane">Brisbane</option>
                                </select>
                            </div>
                            <div className="group-input col-2">
                                <label htmlFor="">Group</label>
                                <select name="" id="" defaultValue="group-1">
                                    <option value="group-1">Group 1</option>
                                    <option value="group-2">Group 2</option>
                                    <option value="group-3">Group 3</option>
                                </select>
                            </div>
                        </div>

                    }

                    {
                        (this.state.currentChat || this.state.newChat) &&
                        <div className="messages-text-box">
                            <textarea placeholder="Type your message..." name="" id="" cols="30" rows="10"></textarea>
                            <div className="messages-text-box-buttons">

                                <button onClick={this.handleClick} className="btn-file"><span className="material-icons">attach_file</span></button>
                                <input onChange={this.setFiles} className="file-input-hidden" type="file" name="" id="file-input" />
                                <button className="btn-send"><span className="material-icons">send</span></button>

                            </div>
                            {
                                this.state.files?.map((file) => (
                                    <div className="attached-file">
                                        <p>Attached document: {file}</p>
                                    </div>
                                ))
                            }
                        </div>
                    }

                    {
                        !this.state.chatSelected &&
                        <div className="no-chat-selected">
                            <span class="material-icons">
                                forward
                            </span>
                            <h2>Please select a chat from the list</h2>
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>
    }
}