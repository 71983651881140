import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class CheckListOverview extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 3,
        display_asidebar: false,
        asiderbar_create: false
    }

    viewLead = (id) => {
        this.setState({
            asiderbar_create: true,
            display_asidebar: true
        })
        console.log(id)
    }


    showAsideBar = () => {
        if(this.state.asiderbar_create){
            return(
                <div className="container create">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={()=>this.setState({asiderbar_create: false})}><i className="material-icons">arrow_back_ios</i> Back</button>
                                <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                            </div>
                            <h3 className="center">CREATE GROUP</h3>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">Name</label>
                                <input type="text" name="" id=""/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Location</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a location...</option>
                                    <option value="1">Location's name 1</option>
                                    <option value="2">Location's name 2</option>
                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Composition</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">0-18</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">18-24</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city3" value="3"/>
                                    <label htmlFor="city3">24-72</label>
                                </div>
                            </div>
                            <div className="group-input col-2">
                            <div className="group-input">
                                <label htmlFor="">Capacity</label>
                                <input type="number" name="" id=""/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Flexpaces</label>
                                <input type="text" name="" id=""/>
                            </div>
                            </div>
                            <div className="group-input">
                                <div className="subtitle">Daycare hours</div>
                                <table className="input">
                                    <tr>
                                        <td></td>
                                        <td>Start</td>
                                        <td>End</td>
                                    </tr>
                                    <tr>
                                        <td>AM</td>
                                        <td>
                                            <div className="group-input">
                                                <input type="text" name="" id=""/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="group-input">
                                                <input type="text" name="" id=""/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>PM</td>
                                        <td>
                                            <div className="group-input">
                                                <input type="text" name="" id=""/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="group-input">
                                                <input type="text" name="" id=""/>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Extended Hour Count Per</label>
                                <div className="group-input col-2">
                                    <input type="number" name="" id=""/>
                                    <p>minutes</p>
                                </div>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Create</button>
                            </div>
                        </div>
                        </div>
            );
        }else{
            return(
                <div className="container">
                        <div className="header">
                            <h3><i class="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({display_asidebar: false})}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">NAME</label>
                                <input type="text" name="" id=""/>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Location 1</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Location 2</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city3" value="3"/>
                                    <label htmlFor="city3">Location 3</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">ACTIVE</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Active</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Non-active</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">TYPE</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1"/>
                                    <label htmlFor="city1">Children</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city2" value="2"/>
                                    <label htmlFor="city2">Group</label>
                                </div>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                        </div>
            );
        }
    }

    render(){
        return ( 
            <React.Fragment>
                    <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                        {this.showAsideBar()}
                    </div>
                    <div className="content">
                      <div className="content-head">
                        <div className="title">
                          <h1>Checklist Overview</h1>
                          <div className="route">Control &gt; Checklist Overview</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({display_asidebar: true, asiderbar_create: false})}><i class="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <Link to="/control/overview/create" className="btn principal"><i class="material-icons">add</i>Add CheckList</Link>
                        </div>
                      </div>
                      <Table 
                        data = {[
                            {
                                id: 1,
                                name: "Checklist name 1",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 2,
                                name: "Checklist name 2",
                                active: false,
                                type: "Group",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 3,
                                name: "Checklist name 3",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 4,
                                name: "Checklist name 4",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 5,
                                name: "Checklist name 5",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 6,
                                name: "Checklist name 6",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 1,
                                name: "Checklist name 1",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 2,
                                name: "Checklist name 2",
                                active: false,
                                type: "Group",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 3,
                                name: "Checklist name 3",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 4,
                                name: "Checklist name 4",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 5,
                                name: "Checklist name 5",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                            {
                                id: 6,
                                name: "Checklist name 6",
                                active: true,
                                type: "Children",
                                questions: 6,
                                locations: "Location 1, location 2, location 3"
                            },
                        ]}
                        columns = {[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Name",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "Active",
                                name: "active",
                                bool: true
                            },
                            {
                                title: "Type",
                                name: "type",
                                bool: false
                            },
                            {
                                title: "Questions",
                                name: "questions",
                                bool: false
                            },
                            {
                                title: "Locations",
                                name: "locations",
                                bool: false
                            }
                        ]}
                        download = {true}
                        view = {false}
                        edit = {true}
                        delete = {true}
                        viewFunction = {this.viewLead}
                        editFunction = ""
                        deleteFunction = ""
                        totalElements = "47"
                      />
                      <Pagination 
                        page = {this.state.page}
                        per_page = {this.state.per_page}
                        total_elements = {this.state.total_elements}
                        total_pages = {this.state.total_pages}
                        change_page = {page => this.setState({page})}
                        />
                    </div>
            </React.Fragment> 
        );
    }
}

export default CheckListOverview;