import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";

class CheckListForm extends Component {
    state = {
        dropdownOpened: false
    }

    setDoneChecklist = () => {
        this.setState({
            dropdownOpened: false
        })
        this.props.function()
    }

    render() {
        return (
            <React.Fragment>
                <div className="checklist">
                    <div className={this.props.answered ? "header complete" : "header"} onClick={() => this.setState({dropdownOpened: (this.state.dropdownOpened ? false : true)})}>
                        <h3>{this.props.order+'. '+this.props.name}</h3>
                        <i className="material-icons">{this.props.answered ? "check" : "keyboard_arrow_down"}</i>
                    </div>
                    <div className={this.state.dropdownOpened ? "card" : "card hidden"}>
                        <div className="flex">
                            <div className="flex-4 left column">
                                <div className="group-input column">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="" id="name" />
                                </div>
                                <div className="group-input column">
                                    <label htmlFor="">Name</label>
                                    <input type="text" name="" id="name" />
                                </div>
                            </div>
                            <div className="flex-4 column">
                            <div className="group-input column">
                                <label htmlFor="">Group</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a group...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="group-input column">
                                <label htmlFor="">Type</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city4" value="1"/>
                                    <label htmlFor="city4">Children</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city5" value="2"/>
                                    <label htmlFor="city5">Group</label>
                                </div>
                            </div>
                                
                                
                            </div>
                            <div className="flex-4 right column">
                            <div className="group-input column">
                                    <label htmlFor="">Location</label>
                                    <div className="group-check">
                                        <input type="checkbox" name="city" id="city1" value="1"/>
                                        <label htmlFor="city1">Location 1</label>
                                    </div>
                                    <div className="group-check">
                                        <input type="checkbox" name="city" id="city2" value="2"/>
                                        <label htmlFor="city2">Location 2</label>
                                    </div>
                                    <div className="group-check">
                                        <input type="checkbox" name="city" id="city3" value="3"/>
                                        <label htmlFor="city3">Location 3</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-container">
                                <button className="principal" onClick={()=>this.setDoneChecklist()}>Save</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CheckListForm;