import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../partials/Pagination";
import Table from "../../partials/Table";

export class TeacherOverview extends Component {

    state = {
        display_asidebar: false,
        asiderbar_details: false,
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 10,
        user: {

        }
    }

    render() {
        return (

            <React.Fragment>
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>

                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">SEARCH</label>
                                <input type="text" name="" id="name" />
                            </div>

                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">Location 1</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">Location 2</label>
                                </div>
                                <div className="group-check">
                                    <input type="checkbox" name="city" id="city3" value="3" />
                                    <label htmlFor="city3">Location 3</label>
                                </div>
                            </div>

                            <div className="group-input">
                                <label htmlFor="">GROUP</label>
                                <select name="location" id="location-select">

                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LEGAL FUNCTION</label>
                                <select name="location" id="location-select">

                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">CONTRACT FUNCTION</label>
                                <select name="location" id="location-select">

                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">CONTRACT SORT</label>
                                <select name="location" id="location-select">

                                </select>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Teacher Overview</h1>
                            <div className="route">HR and Finances &gt; Teacher Overview</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({ display_asidebar: true, user: {} })}><i className="material-icons edit">filter_alt</i></button>
                        <div className="btn-container">
                            <Link to="/hr-finances/teacher/create" className="btn principal"><i className="material-icons">add</i>Add teacher</Link>
                        </div>
                    </div>

                    <Table
                        data={
                            [
                                {
                                    id: 1,
                                    name: 'Jane Doe (Jane)',
                                    contractSort: 'Sort name',
                                    contractFunction: 'Qualified',
                                    salary: 85.6,
                                    legalFunction: 'Qualified',
                                    planned: true
                                },
                                {
                                    id: 2,
                                    name: 'Jane Doe (Jane)',
                                    contractSort: 'Sort name',
                                    contractFunction: 'Qualified',
                                    salary: 85.6,
                                    legalFunction: 'Qualified',
                                    planned: true
                                },
                                {
                                    id: 3,
                                    name: 'Jane Doe (Jane)',
                                    contractSort: 'Sort name',
                                    contractFunction: 'Qualified',
                                    salary: 85.6,
                                    legalFunction: 'Qualified',
                                    planned: true
                                },
                                {
                                    id: 4,
                                    name: 'Jane Doe (Jane)',
                                    contractSort: 'Sort name',
                                    salary: 85.6,
                                    contractFunction: 'Qualified',
                                    legalFunction: 'Qualified',
                                    planned: true
                                },
                            ]
                        }
                        columns={[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Name (Pref Name)",
                                name: "name",
                                bool: false
                            },
                            {
                                title: "Contract Sort",
                                name: "contractSort",
                                bool: false
                            },
                            {
                                title: "Contract Function",
                                name: "contractFunction",
                                bool: false
                            },
                            {
                                title: "Salary",
                                name: "salary",
                                bool: false
                            },
                            {
                                title: "Legal function",
                                name: "legalFunction",
                                bool: false
                            },
                            {
                                title: "Planned",
                                name: "planned",
                                bool: true
                            },
                        ]}
                        view={true}
                        edit={true}
                        delete={true}
                        download={false}
                        deleteFunction=""
                        totalElements="47"
                        viewFunction={(id)=>{this.props.history.push(`/hr-finances/teacher/view/${id}`)}}
                        editFunction={(id)=>{this.props.history.push(`/hr-finances/teacher/edit/${id}`)}}
                    >

                    </Table>
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        );
    }
}