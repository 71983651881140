import React, { Component } from 'react';

class CustomizeInputTable extends Component {
    state = {
    }

    render() {
        return (
            <React.Fragment>
                <table id="basicrules">
                    <thead>
                        <tr>
                            <th></th>
                            <th>0-18</th>
                            <th>18-24</th>
                            <th>24-72</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Mon
                                                </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p1.monday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.monday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.monday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Tue
                                                </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p1.tuesday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.tuesday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.tuesday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Wed
                                                </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p1.wednesday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.wednesday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.wednesday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Thu
                                                </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p1.thursday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.thursday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.thursday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Fri
                                                </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p1.friday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.friday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.friday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Sat
                                                </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p1.saturday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.saturday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.saturday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Sun
                                                </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p1.sunday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.sunday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                            <td>
                                <div className="group-input">$<input type="number" defaultValue={!this.props.create ? this.props.data.p2.sunday : ""} disabled={this.props.edit || this.props.create ? "" : "disabled"} /></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default CustomizeInputTable;