import React, { Component } from "react";
import TableCardSmall from "../../partials/TableCardSmall";
import TableSelect from "../../partials/TableSelect";

export class Budget extends Component {


    state = {
        display_asidebar: false,
        create: true,
        edit: true,
        selectedFilter: '',
        locations: [
            {
                name: 'Location name 1'
            },
            {
                name: 'Location name 2'
            },
            {
                name: 'Location name 3'
            },
            {
                name: 'Location name 4'
            },
        ]
    }


    render() {
        return (

            <React.Fragment>
                <div className="aside-bar">
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                        </div>

                        <div className="button-filters">
                            {
                                this.state.locations.map((location) => (
                                    <React.Fragment>
                                        <div onClick={() => { this.setState({ selectedFilter: location.name }) }} className={this.state.selectedFilter === location.name ? "button-filter selected-filter" : "button-filter"}>
                                            <p>{location.name}</p>
                                            <i className="material-icons">
                                                arrow_forward_ios
                                            </i>
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>

                    </div>
                </div>
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Budget</h1>
                            <div className="route">HR and Finances &gt; Budget</div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="flex-12 column">
                            <div className="card card-vertical">
                                <div className="card-title">BUDGET</div>
                                <div className="flex">
                                <div className="flex-6 left column">
                                    <div className="flex">
                                        <div className="flex-6 left">
                                            <div className="group-input">
                                                <label htmlFor="">Month</label>
                                                <select defaultValue="January" name="" id="">
                                                <option value="January">January</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div className="flex-6 right">
                                            <div className="group-input">
                                                <label htmlFor="">Year</label>
                                                <select defaultValue="2021" name="" id="">
                                                <option value="2021">2021</option>
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="subtitle">Budget by category</div>
                                    <div className="flex">
                                        <div className="group-input">
                                            <label htmlFor="">Category</label>
                                            <select name="" id="">
                                                <option value="">Furniture</option>
                                            </select>
                                        </div>
                                        <div className="group-input">
                                            <label htmlFor="">Value</label>
                                            <input type="number"/>
                                        </div>
                                        <div style={{paddingTop: 30}}>
                                        <button className="add"><i className="material-icons">add</i></button>
                                        </div>
                                    </div>
                                    <TableCardSmall
                                            data={[
                                                {
                                                    id: 12,
                                                    category: 'Furniture',
                                                    value: 200
                                                },
                                                {
                                                    id: 13,
                                                    category: 'IT equipment',
                                                    value: 250
                                                },
                                            ]}
                                            columns={[
                                                {
                                                    title: 'ID',
                                                    name: 'id'
                                                },
                                                {
                                                    title: 'Category',
                                                    name: 'category'
                                                },
                                                {
                                                    title: 'Value',
                                                    name: 'value'
                                                }
                                            ]}
                                            buttons_first={true}
                                            edit={false}
                                            delete={this.state.edit || this.state.create}
                                            delete_function=""
                                            edit_function=""
                                        />
                                        <div className="subtitle">General budget: $450</div>
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                </div>
                                
                                <div className="flex-6 right column">
                                <div className="row-select">
                                    <div className="select-input-wrapper">
                                        <div className="select-input">
                                            <label htmlFor="">Year: </label>
                                            <select defaultValue="2021" name="" id="">
                                                <option value="2021">2021</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <TableSelect
                                    data={
                                        [
                                            {
                                                id: 1,
                                                year: 2020,
                                                month: 'January',
                                                budget: '$4,500'
                                            },
                                            {
                                                id: 2,
                                                year: 2020,
                                                month: 'February',
                                                budget: '$4,500'
                                            },
                                            {
                                                id: 3,
                                                year: 2020,
                                                month: 'March',
                                                budget: '$4,500'
                                            },
                                            {
                                                id: 4,
                                                year: 2020,
                                                month: 'April',
                                                budget: '$4,500'
                                            },
                                            {
                                                id: 5,
                                                year: 2020,
                                                month: 'May',
                                                budget: '$4,500'
                                            },
                                            {
                                                id: 6,
                                                year: 2020,
                                                month: 'June',
                                                budget: '$4,500'
                                            },

                                        ]
                                    }
                                    columns={[
                                        {
                                            title: "Year",
                                            name: "year",
                                            bool: false
                                        },
                                        {
                                            title: "Month",
                                            name: "month",
                                            bool: false
                                        },
                                        {
                                            title: "Budget",
                                            name: "budget",
                                            bool: false
                                        },
                                    ]}
                                    change={true}
                                    delete={true}
                                    details={true}
                                    delete_function=""
                                    edit_function=""
                                    view_function=""
                                />
                                </div>
                                </div>
                            </div>
                        
                            <div className="card card-vertical">
                                <div className="card-title">EXPENSE CONTROL</div>
                                <div className="flex">
                                    <div className="flex-4 left column">
                                        <div className="group-input">
                                            <label htmlFor="">Date</label>
                                            <input type="text" />
                                        </div>

                                        <div className="group-input">
                                            <label htmlFor="">Concept</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="flex-4 column">
                                        <div className="group-input">
                                            <label htmlFor="">Cost</label>
                                            <input type="text" />
                                        </div>

                                        <div className="group-input">
                                            <label htmlFor="">Category</label>
                                            <select name="" id=""></select>
                                        </div>
                                    </div>
                                    <div className="flex-4 right column">
                                        <div className="group-input">
                                            <label htmlFor="">Details</label>

                                            <textarea name="" id="" cols="30" rows="4"></textarea>
                                        </div>
                                        <div className="group-input">
                                                <input className="file-input-hidden" type="file" name="" id="file-input" />
                                                <button onClick={this.handleClick} className="file-input-button-children yellow"><i className="material-icons">attach_file</i>Attach document</button>
                                            </div>
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Add</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row-select">
                                    <div className="select-input-wrapper">
                                        <div className="select-input">
                                            <label htmlFor="">Month: </label>
                                            <select defaultValue="January" name="" id="">
                                                <option value="January">January</option>
                                            </select>
                                        </div>
                                        <div className="select-input">
                                            <label htmlFor="">Year: </label>
                                            <select defaultValue="2021" name="" id="">
                                                <option value="2021">2021</option>
                                            </select>
                                        </div>
                                    </div>

                                    <p><div>General budget: $4,500</div>  <button className="primary">Export</button></p>
                                </div>
                                <TableSelect
                                    data={
                                        [
                                            {
                                                id: 1,
                                                date: '2020/07/05',
                                                concept: 'Concept name 1',
                                                category: 'Furniture',
                                                details: 'Lorem ipsum dolor est ',
                                                cost: '$250',
                                                remainingGeneral: '$4,250',
                                                remainingCategory: '$350'
                                            },
                                            {
                                                id: 2,
                                                date: '2020/07/05',
                                                concept: 'Concept name 1',
                                                category: 'IT equipment',
                                                details: 'Lorem ipsum dolor est ',
                                                cost: '$250',
                                                remainingGeneral: '$4,000',
                                                remainingCategory: '$150'
                                            },

                                        ]
                                    }
                                    columns={[
                                        {
                                            title: "Date",
                                            name: "date",
                                            bool: false
                                        },
                                        {
                                            title: "Concept",
                                            name: "concept",
                                            bool: false
                                        },
                                        {
                                            title: "Category",
                                            name: "category",
                                            bool: false
                                        },
                                        {
                                            title: "Details",
                                            name: "details",
                                            bool: false
                                        },
                                        {
                                            title: "Cost",
                                            name: "cost",
                                            bool: false
                                        },
                                        {
                                            title: "Remaining general budget",
                                            name: "remainingGeneral",
                                            bool: false
                                        },
                                        {
                                            title: "Remaining category budget",
                                            name: "remainingCategory",
                                            bool: false
                                        },
                                    ]}
                                    download={true}
                                    change={true}
                                    delete={true}
                                    details={false}
                                    delete_function=""
                                    edit_function=""
                                    view_function=""
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}