import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export class HRFinances extends Component {
    render() {
        return (
            <React.Fragment>
                <Redirect to="/hr-finances/teacher" />
            </React.Fragment>
        );
    }
}