import React, { Component } from 'react';
import {
  NavLink,
  Link, useLocation
} from "react-router-dom";
import logo from './LOGO.svg';
import smallLogo from './SMALL-LOGO.svg';

function Subcategories(props){
    const location = useLocation();

    if(location.pathname.startsWith("/locations")){
      return(
        <React.Fragment>
          <div id="name">
            <h1>Locations</h1>
          </div>
          <div id="subcategories">
            <div className="a-container">
          <NavLink to="/locations/overview" activeClassName="selected">Location Overview</NavLink>
          <NavLink to="/locations/groups" activeClassName="selected">Group overview</NavLink>
          <NavLink to="/locations/products" activeClassName="selected">Product Management</NavLink>
          </div>
          </div>
          
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/children")){
      return(
        <React.Fragment>
          <div id="name">
            <h1>Children</h1>
          </div>
          <div id="subcategories">
            <div className="a-container">
          <NavLink to="/children/overview" activeClassName="selected">Children Overview</NavLink>
          <NavLink to="/children/contracts" activeClassName="selected">Contract List</NavLink>
          </div>
          </div>
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/caretakers")){
      return(
        <React.Fragment>
          <div id="name">
            <h1>Caretakers</h1>
          </div>
          <div id="subcategories">
            <div className="a-container">
          <NavLink to="/caretakers/overview" activeClassName="selected">Caretaker Overview</NavLink>
          <NavLink to="/caretakers/invoices" activeClassName="selected">Invoices</NavLink>
          <NavLink to="/caretakers/leads" activeClassName="selected">Leads</NavLink>
          </div>
          </div>
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/planning-tools")){
      return(
        <React.Fragment>
          <div id="name">
            <h1>Planning tools</h1>
          </div>
          <div id="subcategories">
            <div className="a-container">
          <NavLink to="/planning-tools/children" activeClassName="selected">Children Overview</NavLink>
          <NavLink to="/planning-tools/waiting-list" activeClassName="selected">Waiting List Overview</NavLink>
          <NavLink to="/planning-tools/activities" activeClassName="selected">Activities Overview</NavLink>
          </div>
          </div></React.Fragment>
      );
    }
    if(location.pathname.startsWith("/reports")){
      return(
        <React.Fragment>
        <div id="name">
          <h1>Reports</h1>
        </div>
        <div id="subcategories" className="overflow">
          <div className="a-container">
          <NavLink to="/reports/children" activeClassName="selected">Children's Reports</NavLink>
          <NavLink to="/reports/teachers" activeClassName="selected">Teacher's Reports</NavLink>
          <NavLink to="/reports/financial" activeClassName="selected">Financial Reports</NavLink>
          <NavLink to="/reports/occupancy" activeClassName="selected">Occupancy Statistics</NavLink>
          </div>
        </div>
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/control")){
      return(
        <React.Fragment>
        <div id="name">
          <h1>Control</h1>
        </div>
        <div id="subcategories">
          <div className="a-container">
          <NavLink to="/control/complete" activeClassName="selected">Complete Checklist</NavLink>
          <NavLink to="/control/overview" activeClassName="selected">Checklist Overview</NavLink>
          <NavLink to="/control/documents" activeClassName="selected">Documents</NavLink>
          </div>
        </div>
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/family-panel")){
      return(
        <React.Fragment>
        <div id="name">
          <h1>Family panel</h1>
        </div>
        <div id="subcategories" className="overflow">
          <div className="a-container">
          <NavLink to="/family-panel/documents" activeClassName="selected">Documents</NavLink>
          <NavLink to="/family-panel/images" activeClassName="selected">Images</NavLink>
          <NavLink to="/family-panel/messages" activeClassName="selected">Messages</NavLink>
          <NavLink to="/family-panel/lunch-menu" activeClassName="selected">Lunch Menu</NavLink>
          <NavLink to="/family-panel/activities" activeClassName="selected">Activities</NavLink>
          </div>
        </div>
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/hr-finances")){
      return(
        <React.Fragment>
        <div id="name">
          <h1>Hr and finances</h1>
        </div>
        <div id="subcategories" className="overflow">
          <div className="a-container">
          <NavLink to="/hr-finances/teacher" activeClassName="selected">Teacher Overview</NavLink>
          <NavLink to="/hr-finances/shift" activeClassName="selected">Shift Planning</NavLink>
          <NavLink to="/hr-finances/working-hours" activeClassName="selected">Working Hours</NavLink>
          <NavLink to="/hr-finances/leave-days" activeClassName="selected">Leave Days Form</NavLink>
          <NavLink to="/hr-finances/budget" activeClassName="selected">Budget</NavLink>
          <NavLink to="/hr-finances/employee-payments" activeClassName="selected">Employee Payments</NavLink>
          </div>
        </div>
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/admin-tools")){
      return(
        <React.Fragment>
        <div id="name">
          <h1>Admin tools</h1>
        </div>
        <div id="subcategories">
          <div className="a-container">
          <NavLink to="/admin-tools/users" activeClassName="selected">User Management</NavLink>
          <NavLink to="/admin-tools/parameters" activeClassName="selected">Parameters</NavLink>
          <NavLink to="/admin-tools/templates" activeClassName="selected">Templates</NavLink>
          </div>
        </div>
        </React.Fragment>
      );
    }
    return(
      <React.Fragment>
        <div id="name">
          <h1>Home</h1>
        </div>
        <div id="subcategories" className="dissapear-responsive">
        </div>
        </React.Fragment>
    );
}

class Header extends Component {
    state={
      account_container: false,
      search_container: false
    }
    
    
    render(){
      return (
        <React.Fragment>
            <header>
              <div id="logo">
                <img src={logo} alt="logo_wondergarden" className="large"/>
                <img src={smallLogo} alt="logo_wondergarden" className="small"/>
              </div>
              <div id="search-box" className={this.state.search_container ? "active" : ""}>
                <button id="close_search_box" onClick={() => this.setState({search_container: false})}><i className="material-icons">close</i></button>
                <div className="container">
                  <button><i className="material-icons">search</i></button>
                  <input type="search" name="" id="search" autoComplete="off" placeholder="Search..."/>
                  <div className="search-container">
                    <div className="result">
                      <div className="img-circle">
                        <img src="./assets/teacher.png" alt="teacher"/>
                      </div>
                      <div className="text">
                        <h3>Susan H. Doe</h3>
                        <span>Teacher</span>
                      </div>
                    </div>
                    <div className="result">
                      <div className="img-circle">

                        <img src="./assets/caretaker_2.png" alt="teacher"/>
                      </div>
                      <div className="text">
                        <h3>Susan Bloom</h3>
                        <span>Caretaker</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <Subcategories />
              <div id="my-account" >
              <button className="search" onClick={() => this.setState({search_container: true})}><i className="material-icons">search</i></button>
                <div className="img-circle" onClick={() => this.setState({account_container: (this.state.account_container ? false : true)})}>
                  <img src="./assets/teacher.png" alt="teacher"/>
                </div>
                <i className="material-icons">arrow_drop_down</i>
                <div className={this.state.account_container ? "my-account-container active" : "my-account-container"} onClick={() => this.setState({account_container: (this.state.account_container ? false : true)})}>
                  {/* <div className="dark-mode">
                    <i className="material-icons">brightness_2</i> Dark mode
                    <label className="switch">
                      <input type="checkbox"/>
                      <span className="slider round"></span>
                    </label>
                  </div> */}
                  <Link to="/"><button><i className="material-icons">home</i> Go to Home</button></Link>
                  <button className="logout"><i className="material-icons">exit_to_app</i> Log out</button>
                </div>
              </div>
            </header>
        </React.Fragment>
      );
    }
  }
  
  export default Header;