import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../partials/Pagination";
import Table from "../../partials/Table";

export class WorkingHours extends Component {

    state = {

        display_asidebar: false,
        filters: true,
        page: 1,
        per_page: 15,
        total_elements: 54,
        total_pages: 10,
    }


    teachers = [
        {
            id: 1,
            img: '/assets/teacher.png',
            name: 'Jane H. Doe',
            type: 'Qualified',
            timeInterval: '23/10/2020 - 27/10/2020',
            rosteredHours: '72hrs',
            workedHours: '72hrs 54min'
        },{
            id: 2,
            img: '/assets/teacher.png',
            name: 'Jane H. Doe',
            type: 'Qualified',
            timeInterval: '23/10/2020 - 27/10/2020',
            rosteredHours: '72hrs',
            workedHours: '72hrs 54min'
        },{
            id: 3,
            img: '/assets/teacher.png',
            name: 'Jane H. Doe',
            type: 'Qualified',
            timeInterval: '23/10/2020 - 27/10/2020',
            rosteredHours: '72hrs',
            workedHours: '72hrs 54min'
        },{
            id: 4,
            img: '/assets/teacher.png',
            name: 'Jane H. Doe',
            type: 'Qualified',
            timeInterval: '23/10/2020 - 27/10/2020',
            rosteredHours: '72hrs',
            workedHours: '72hrs 54min'
        },
    ]
    showFilters = () => {
        return this.state.filters ?
            <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>

                <div className="container">
                    <div className="header">
                        <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                        <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                    </div>
                    <div className="filters">
                        <div className="group-input">
                            <label htmlFor="">SEARCH</label>
                            <input type="text" name="" id="name" />
                        </div>


                        <div className="group-input">
                            <label htmlFor="">LOCATION</label>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city1" value="1" />
                                <label htmlFor="city1">Location 1</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city2" value="2" />
                                <label htmlFor="city2">Location 2</label>
                            </div>
                            <div className="group-check">
                                <input type="checkbox" name="city" id="city3" value="3" />
                                <label htmlFor="city3">Location 3</label>
                            </div>
                        </div>

                        <div className="group-input">
                            <label htmlFor="">GROUP</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>...</option>
                                <option value="1">Group's name 1</option>
                                <option value="2">Group's name 2</option>
                            </select>
                        </div>

                        <div className="group-input">
                            <label htmlFor="">LEGAL FUNCTION</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>...</option>
                                <option value="1">Group's name 1</option>
                                <option value="2">Group's name 2</option>
                            </select>
                        </div>

                        <div className="group-input no-padding-bottom">
                            <div className="subtitle">DATES</div>
                        </div>
                        <div className="group-input col-2 no-padding-top">
                            <label htmlFor="">From</label>
                            <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />

                            <label htmlFor="" className="center">to</label>
                            <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                        </div>


                        <div className="btn-container">
                            <button className="principal">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
            :

            <div className="aside-bar popup active info">
                <div className="container aside-bar-info-active">
                    <div className="header">
                        <div className="btn-container">
                            <button className="left" onClick={() => this.setState({ asiderbar_details: false, filters:true })}><i className="material-icons">arrow_back_ios</i> Back</button>
                        </div>
                    </div>
                    <div className="img-container">
                        <div className="circle">
                            <img src={this.state.teacher.img} alt="" />
                        </div>
                    </div>

                    <div className="activity-info-panel">
                        <h3>{this.state.teacher.name}</h3>
                        <h2>{this.state.teacher.type}</h2>
                    </div>


                    <div className="group-input">
                        <label htmlFor="">Time Interval</label>
                        <input type="text" name="" value={this.state.teacher.timeInterval} disabled id="name" />
                    </div>


                    <div className="col-2">
                        <div className="group-input">
                            <label htmlFor="">Contract hours</label>
                            <input type="text" value={this.state.teacher.rosteredHours} disabled id="text" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Working hours</label>
                            <input type="text" value={this.state.teacher.workedHours} disabled id="text" />
                        </div>
                    </div>
                </div>
            </div>
    }

    viewDetails = (id) => {
        this.setState({ teacher: this.teachers.find((teacher) => teacher.id === id), filters: false })
    }

    render() {
        return (
            <React.Fragment>

                {this.showFilters()}
                <div className="content">
                    <div className="content-head">
                        <div className="title">
                            <h1>Working Hours</h1>
                            <div className="route">HR and Finances &gt; Working Hours</div>
                        </div>
                        <button className="filters" onClick={() => this.setState({ display_asidebar: true })}><i className="material-icons edit">filter_alt</i></button>
                    </div>
                    <Table
                        data={
                            [
                                {
                                    id: 1,
                                    teacher: [{
                                        principal: "Jane H. Doe",
                                        sub: "Qualified",
                                        image: "/assets/teacher.png"
                                    }],

                                    location: 'location 1',
                                    group: 'Group 1',
                                    rosteredHours: '72hrs',
                                    workedHours: '64hrs 45min',
                                    leaveHours: '7hrs',
                                    totalHours: '71hrs 45min'

                                },
                                {
                                    id: 2,
                                    teacher: [{
                                        principal: "Jane H. Doe",
                                        sub: "Qualified",
                                        image: "/assets/teacher.png"
                                    }],

                                    location: 'location 1',
                                    group: 'Group 1',
                                    rosteredHours: '72hrs',
                                    workedHours: '64hrs 45min',
                                    leaveHours: '7hrs',
                                    totalHours: '71hrs 45min'
                                },
                                {
                                    id: 3,
                                    teacher: [{
                                        principal: "Jane H. Doe",
                                        sub: "Qualified",
                                        image: "/assets/teacher.png"
                                    }],

                                    location: 'location 1',
                                    group: 'Group 1',
                                    rosteredHours: '72hrs',
                                    workedHours: '64hrs 45min',
                                    leaveHours: '7hrs',
                                    totalHours: '71hrs 45min'
                                },
                                {
                                    id: 4,
                                    teacher: [{
                                        principal: "Jane H. Doe",
                                        sub: "Qualified",
                                        image: "/assets/teacher.png"
                                    }],

                                    location: 'location 1',
                                    group: 'Group 1',
                                    rosteredHours: '72hrs',
                                    workedHours: '64hrs 45min',
                                    leaveHours: '7hrs',
                                    totalHours: '71hrs 45min'
                                },
                            ]
                        }
                        columns={[
                            {
                                title: "ID",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Teacher",
                                name: "teacher",
                                bool: false
                            },
                            {
                                title: "Location",
                                name: "location",
                                bool: false
                            },
                            {
                                title: "Group",
                                name: "group",
                                bool: false
                            },
                            {
                                title: "Rostered Hours",
                                name: "rosteredHours",
                                bool: false
                            },
                            {
                                title: "Worked Hours",
                                name: "workedHours",
                                bool: false
                            },
                            {
                                title: "Leave Hours",
                                name: "leaveHours",
                                bool: false
                            },
                            {
                                title: "Total Hours",
                                name: "totalHours",
                                bool: false
                            },
                        ]}

                        view={true}
                        edit={false}
                        delete={false}
                        download={false}
                        deleteFunction=""
                        viewFunction={(id) => { this.viewDetails(id) }}
                        totalElements="47"
                    >

                    </Table>
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        )

    }
}