import React, { Component } from 'react';

class Pagination extends Component {

    showButtons = (page, totalpages) => {
        if (totalpages <= 5) {
            return (
                <div className="btn-container">
                    <button className={page > 1 ? 'ghost active' : 'ghost'} onClick={page > 1 ? e => this.props.change_page(page - 1) : null}>Prev</button>
                    <button className={page === 1 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(1)}>1</button>
                    {totalpages >= 2 ? <button className={page === 2 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(2)}>2</button> : null}
                    {totalpages >= 3 ? <button className={page === 3 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(3)}>3</button> : null}
                    {totalpages >= 4 ? <button className={page === 4 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(4)}>4</button> : null}
                    {totalpages >= 5 ? <button className={page === 5 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(5)}>5</button> : null}
                    <button className={page !== totalpages ? 'ghost active' : 'ghost'} onClick={page !== totalpages ? e => this.props.change_page(page + 1) : null}>Next</button>
                </div>
            );
        } else {
            if ((page + 3) >= totalpages) {
                return (
                    <div className="btn-container">
                        <button className={page > 1 ? 'ghost active' : 'ghost'} onClick={page > 1 ? e => this.props.change_page(page - 1) : null}>Prev</button>

                        <button className={page === 1 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(1)}>1</button>
                        <span>...</span>
                        <button className={page === totalpages - 4 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(totalpages - 4)}>{totalpages - 4}</button>
                        <button className={page === totalpages - 3 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(totalpages - 3)}>{totalpages - 3}</button>
                        <button className={page === totalpages - 2 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(totalpages - 2)}>{totalpages - 2}</button>
                        <button className={page === totalpages - 1 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(totalpages - 1)}>{totalpages - 1}</button>
                        <button className={page === totalpages ? 'circle active' : 'circle'} onClick={e => this.props.change_page(totalpages)}>{totalpages}</button>

                        <button className={page !== totalpages ? 'ghost active' : 'ghost'} onClick={page !== totalpages ? e => this.props.change_page(page + 1) : null}>Next</button>
                    </div>
                );
            } else {
                if ((page - 3) <= 0) {
                    return (
                        <div className="btn-container">
                            <button className={page > 1 ? 'ghost active' : 'ghost'} onClick={page > 1 ? e => this.props.change_page(page - 1) : null}>Prev</button>

                            <button className={page === 1 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(1)}>1</button>
                            <button className={page === 2 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(2)}>{2}</button>
                            <button className={page === 3 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(3)}>{3}</button>
                            <button className={page === 4 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(4)}>{4}</button>
                            <button className={page === 5 ? 'circle active' : 'circle'} onClick={e => this.props.change_page(5)}>{5}</button>
                            <span>...</span>
                            <button className={page === totalpages ? 'circle active' : 'circle'} onClick={e => this.props.change_page(totalpages)}>{totalpages}</button>

                            <button className={page !== totalpages ? 'ghost active' : 'ghost'} onClick={page !== totalpages ? e => this.props.change_page(page + 1) : null}>Next</button>
                        </div>
                    );
                } else {
                    return (
                        <div className="btn-container">
                            <button className={page > 1 ? 'ghost active' : 'ghost'} onClick={page > 1 ? e => this.props.change_page(page - 1) : null}>Prev</button>

                            <button className='circle' onClick={e => this.props.change_page(1)}>1</button>
                            <span>...</span>
                            <button className='circle' onClick={e => this.props.change_page(page - 1)}>{page - 1}</button>
                            <button className='circle active' >{page}</button>
                            <button className='circle' onClick={e => this.props.change_page(page + 1)}>{page + 1}</button>
                            <button className='circle' onClick={e => this.props.change_page(page + 2)}>{page + 2}</button>
                            <span>...</span>
                            <button className={page === totalpages ? 'circle active' : 'circle'} onClick={e => this.props.change_page(totalpages)}>{totalpages}</button>

                            <button className={page !== totalpages ? 'ghost active' : 'ghost'} onClick={page !== totalpages ? e => this.props.change_page(page + 1) : null}>Next</button>
                        </div>
                    );
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="pagination" className="row">
                    <div className="results-qty">
                        <p>Results: <span>50</span></p>
                    </div>
                    <div className="qty-select">
                        <div className="group-input col-2">
                            <label htmlFor="perpage">Per page:</label>
                            <select name="perpage" id="perpage" className="s">
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                    <div className="buttons">
                        {this.showButtons(this.props.page, this.props.total_pages)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Pagination;