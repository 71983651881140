import React, { Component } from "react";
import { Calendar } from "../../partials/Calendar";

export class ActivitiesOverview extends Component {

    state = {
        display_asidebar: false,
        asiderbar_details: false,
        segments: [
            {
                name: 'fullDay',
                title: 'FULL DAY'
            },
            {
                name: 'amPm',
                title: 'AM-PM',
            },
        ],
        data: {
            displayId: 4,
            monday: {
                fullDay: [
                    {
                        id: 2,
                        time: 'FULL DAY',
                        title: `Children's activity`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ],
                amPm: [
                    {
                        id: 6,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    },
                    {
                        id: 3,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ],
            },
            tuesday: {
                fullDay: [
                    {
                        id: 5,
                        time: 'FULL DAY',
                        title: `Children's activity`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    },
                    {
                        id: 2,
                        time: 'FULL DAY',
                        title: `Children's activity`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ],
                amPm: [
                    {
                        id: 6,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    },
                    {
                        id: 3,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ],
            },
            wednesday: {
                fullDay: [
                ],
                amPm: [
                    {
                        id: 6,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    },
                    {
                        id: 3,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ],
            },
            thursday: {
                fullDay: [
                    {
                        id: 5,
                        time: 'FULL DAY',
                        title: `Children's activity`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ],
                amPm: [
                    {
                        id: 6,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    },
                    {
                        id: 3,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ]
            },
            friday: {
                fullDay: [
                    {
                        id: 5,
                        time: 'FULL DAY',
                        title: `Children's activity`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    },
                    {
                        id: 2,
                        time: 'FULL DAY',
                        title: `Children's activity`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ],
                amPm: [
                    {
                        id: 6,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    },
                    {
                        id: 3,
                        time: '10:30am-11:30am',
                        title: `Parents meeting`,
                        place: 'Place name',
                        group: 'Group 1',
                        teacher: {
                            category: 'Teacher',
                            img: '/assets/teacher.png',
                            name: 'Susan Doe'
                        }
                    }
                ]
            },
            saturday: {
                fullDay: [],
                amPm: []
            },
            sunday: {
                fullDay: [],
                amPm: []
            },
        },
        week: [
            {
                id: 1,
                title: 'MON',
                name: 'monday',
                day: 20201228
            },
            {
                id: 2,
                title: 'TUE',
                name: 'tuesday',
                day: 20201228
            },
            {
                id: 3,
                title: 'WED',
                name: 'wednesday',
                day: 20201228
            },
            {
                id: 4,
                title: 'THU',
                name: 'thursday',
                day: 20201228
            },
            {
                id: 5,
                title: 'FRI',
                name: 'friday',
                day: 20201228
            },
            {
                id: 6,
                title: 'SAT',
                name: 'saturday',
                day: 20201228
            },
            {
                id: 7,
                title: 'SUN',
                name: 'sunday',
                day: 20201228
            },
        ]
    }

    activities = [
        {
            id: 5,
            time: 'FULL DAY',
            title: `Children's activity`,
            place: 'Place name',
            group: 'Group 1',
            from: '2020/10/19',
            to: '2020/11/25',
            repeating: 'Every monday',
            teacher: {
                category: 'Teacher',
                img: '/assets/teacher.png',
                name: 'Susan Doe'
            },
            content: `Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed
            eiusmod tempor.`
        },
        {
            id: 2,
            time: '10:30am-11:30am',
            title: `Parents meeting`,
            place: 'Place name',
            group: 'Group 1',
            from: '2020/10/19',
            to: '2020/11/25',
            repeating: 'Every monday',
            teacher: {
                category: 'Teacher',
                img: '/assets/teacher.png',
                name: 'Susan Doe'
            },
            content: `Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed
            eiusmod tempor.`
        },
        {
            id: 6,
            time: 'FULL DAY',
            title: `Children's activity`,
            place: 'Place name',
            group: 'Group 1',
            from: '2020/10/19',
            to: '2020/11/25',
            repeating: 'Every monday',
            teacher: {
                category: 'Teacher',
                img: '/assets/teacher.png',
                name: 'Susan Doe'
            },
            content: `Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed
            eiusmod tempor.`
        },
        {
            id: 3,
            time: '10:30am-11:30am',
            title: `Parents meeting`,
            place: 'Place name',
            group: 'Group 1',
            from: '2020/10/19',
            to: '2020/11/25',
            repeating: 'Every monday',
            teacher: {
                category: 'Teacher',
                img: '/assets/teacher.png',
                name: 'Susan Doe'
            },
            content: `Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed
            eiusmod tempor.`
        }
    ]

    showDetails = (id) => {
        console.log(id);
        this.setState({
            asiderbar_details: true,
            activity: this.activities.find(activity => activity.id === id)
        })
    }

    displayAsidebar = () => {
        this.setState({
            display_asidebar: true
        })
    }

    showAsideBar = () => {
        return this.state.asiderbar_details ?
            <div className="aside-bar popup active info">
                <div className="container aside-bar-info-active">
                    <div className="header">
                        <div className="btn-container">
                            <button className="left" onClick={() => this.setState({ asiderbar_details: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                        </div>
                    </div>
                    <div className="img-container">
                        <div className="circle">
                            <img src={this.state.activity.teacher.img} alt="" />
                        </div>
                    </div>

                    <div className="activity-info-panel">
                        <h3>{this.state.activity.time}</h3>
                        <h2>{this.state.activity.title}</h2>
                    </div>

                    <div className="col-2">
                        <div className="group-input">
                            <label htmlFor="">Place</label>
                            <input type="text" name="" value={this.state.activity.place} disabled id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Group</label>
                            <input type="text" value={this.state.activity.group} disabled id="text" />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="group-input">
                            <label htmlFor="">From</label>
                            <input type="text" value={this.state.activity.from} disabled id="text" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">To</label>
                            <input type="text" value={this.state.activity.to} disabled id="text" />
                        </div>
                    </div>

                    <div className="group-input">
                        <label htmlFor="">Repeating</label>
                        <input type="text" value={this.state.activity.repeating} disabled id="text" />
                    </div>
                    <div className="group-input">
                        <label htmlFor="">In Charge</label>
                        <input type="text" value={this.state.activity.teacher.name} disabled id="text" />
                    </div>
                    <div className="group-input">
                        <label htmlFor="">Content</label>
                        <input type="text" value={this.state.activity.content} disabled id="text" />
                    </div>
                    <div className="group-input">
                        <label htmlFor="">Attached file</label>

                    </div>

                    <div className="activity-info-panel-btn">
                        <button className="green">Go to activity details</button>
                    </div>
                </div>
            </div> :

            <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                <div className="container">
                    <div className="header">
                        <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                        <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                    </div>
                    <div className="filters">
                        <div className="group-input">
                            <label htmlFor="">LOCATION</label>
                            <div className="group-radio">
                                <input type="radio" name="city" id="city1" value="1" />
                                <label htmlFor="city1">Location's name 1</label>
                            </div>
                            <div className="group-radio">
                                <input type="radio" name="city" id="city2" value="2" />
                                <label htmlFor="city2">Location's name 2</label>
                            </div>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">GROUP</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>Choose a group...</option>
                                <option value="1">Group's name 1</option>
                                <option value="2">Group's name 2</option>
                            </select>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">PRODUCT</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>Choose a product...</option>
                                <option value="1">Group's name 1</option>
                                <option value="2">Group's name 2</option>
                            </select>
                        </div>
                        <div className="group-input">
                            <label htmlFor="">LIFECYCLE PHASE</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>Choose phase...</option>
                                <option value="1">Group's name 1</option>
                                <option value="2">Group's name 2</option>
                            </select>
                        </div>
                        <div className="group-input no-padding-bottom">
                            <div className="subtitle">AGE</div>
                        </div>
                        <div className="group-input col-2 no-padding-top">
                            <label htmlFor="">From</label>
                            <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />

                            <label htmlFor="" className="center">to</label>
                            <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">PLANNING BY</label>
                            <select name="location" id="location-select">
                                <option value="0" selected>Week</option>
                                <option value="1">Group's name 1</option>
                                <option value="2">Group's name 2</option>
                            </select>
                        </div>
                        <div className="btn-container">
                            <button className="principal">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
    }

    render() {
        return (
            <React.Fragment>
                {this.showAsideBar()}
                <div className="content">
                    <Calendar
                        title="Activities Overview"
                        subtitle="Planning Tools > Activities Overview"
                        data={this.state.data}
                        week={this.state.week}
                        segments={this.state.segments}
                        view="week"
                        showDetails={this.showDetails}
                        displayAsidebar={this.displayAsidebar}
                    />
                </div>
            </React.Fragment>
        )
    }
}