import React, { Component } from "react";

export class Parameters extends Component {

    state = {
        create: true,
        edit: true,
        selectedFilter: '',
        filters: [
            {
                name: 'Client Side'
            },
            {
                name: 'Teacher Side'
            },
            {
                name: 'Email parameters'
            },
        ],
        parameters: [
            {
                name: 'Parameter 1'
            },
            {
                name: 'Parameter 2'
            },
            {
                name: 'Parameter 3'
            }
        ]
    }

    render() {
        return <React.Fragment>

            <div className="aside-bar">
                <div className="container">
                    <div className="header">
                        <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                    </div>

                    <div className="button-filters">
                        {
                            this.state.filters.map((filter) => (
                                <React.Fragment>
                                    <div  onClick={() => { this.setState({ selectedFilter: filter.name }) }} className={this.state.selectedFilter === filter.name ? "button-filter selected-filter" : "button-filter"}>
                                        <p> {filter.name}</p>
                                        <i className="material-icons">
                                            arrow_forward_ios
                                        </i>
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </div>

                </div>
            </div>

            <div className="content">
                <div className="content-head">
                    <div className="title">
                        <h1>Parameters</h1>
                        <div className="route">Admin Tools &gt; Parameters</div>
                    </div>
                </div>
                <div className="content-body">
                    <div className="flex-4 column left">
                        <div className="card vertical">
                            <div className="card-title">
                                TEACHER ABSENCE REASON
                            </div>
                            <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                <label htmlFor="">Name of parameter</label>
                                <input type="text" name="" id="text" />
                                <div>
                                <button className="add"><i className="material-icons">add</i></button>
                                </div>
                            </div>
                            {
                                this.state.parameters.map((param) => {
                                    return (
                                        <div className="flex column param">
                                            <div className="flex">
                                                <button className="remove"><i className="material-icons">remove</i></button><p>{param.name}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="card">
                            <div className="card-title">
                                TEACHER ACTIVITY
                            </div>
                            <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                <label htmlFor="">Name of parameter</label>
                                <input type="text" name="" id="text" />
                                <div>
                                <button className="add"><i className="material-icons">add</i></button>
                                </div>
                            </div>
                            {
                                this.state.parameters.map((param) => {
                                    return (
                                        <div className="flex column param">
                                            <div className="flex">
                                                <button className="remove"><i className="material-icons">remove</i></button><p>{param.name}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="flex-8 column right">

                        <div className="container">
                            <div className="flex-6 column left">
                                <div className="card">
                                    <div className="card-title">
                                        TEACHER CONTRACT FUNCTION
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                        <label htmlFor="">Name of parameter</label>
                                        <input type="text" name="" id="text" />
                                        <div>
                                <button className="add"><i className="material-icons">add</i></button>
                                </div>
                                    </div>
                                    {
                                        this.state.parameters.map((param) => {
                                            return (
                                                <div className="flex column param">
                                                    <div className="flex">
                                                        <button className="remove"><i className="material-icons">remove</i></button><p>{param.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="card">
                                    <div className="card-title">
                                        TEACHER QUALIFICATION DESCRIPTION
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                        <label htmlFor="">Name of parameter</label>
                                        <input type="text" name="" id="text" />
                                        <div>
                                <button className="add"><i className="material-icons">add</i></button>
                                </div>
                                    </div>
                                    {
                                        this.state.parameters.map((param) => {
                                            return (
                                                <div className="flex column param">
                                                    <div className="flex">
                                                        <button className="remove"><i className="material-icons">remove</i></button><p>{param.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="flex-6 column right">
                                <div className="card">
                                    <div className="card-title">
                                        TEACHER CONTRACT SORT
                                    </div>
                                    <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                        <label htmlFor="">Name of parameter</label>
                                        <input type="text" name="" id="text" />
                                        <div>
                                        <button className="add"><i className="material-icons">add</i></button>
                                        </div>
                                    </div>
                                    {
                                this.state.parameters.map((param) => {
                                    return (
                                        <div className="flex column param">
                                            <div className="flex">
                                                <button className="remove"><i className="material-icons">remove</i></button><p>{param.name}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}