import React, { Component } from "react";

export class MobileCalendarSegmentContent extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.data.displayId === 1 ?
                        this.props.data[this.props.currentWeekDay][this.props.segment.name].map((item) => {
                            return (
                                <div

                                    onClick={() => {
                                        this.props.showDetails(item.id)

                                    }} className="mobile-item"
                                >

                                    <div className="img">
                                        <img src={item.img} alt="" />
                                    </div>
                                    <div className="info child-info">
                                        <p>{item.name} <span className="extra-data">({item.extraData})</span></p>
                                    </div>
                                </div>
                            )
                        }) :

                        this.props.data.displayId === 2 ?

                            this.props.data[this.props.currentWeekDay][this.props.segment.name].map((item) => {
                                return (
                                    <div className="mobile-item"
                                        onClick={() => {
                                            this.props.showDetails(item.id)

                                        }}
                                    >

                                        <div className="img">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="info child-info">
                                            <p>{item.name} <span className="extra-data">({item.extraData})</span></p>
                                            <p>{item.sub}</p>
                                        </div>
                                    </div>
                                )
                            })

                            :

                            this.props.data.displayId === 3 ?
                                this.props.data[this.props.currentWeekDay][this.props.segment.name].map((item) => {
                                    return (
                                        <div className="mobile-item"
                                            onClick={() => {
                                                this.props.showDetails(item.id)

                                            }}

                                        >

                                            <div className="lunch-info">
                                                <p className="lunch-time">{item.time}</p>
                                                <p className="lunch-title">{item.title}</p>
                                                <p className="lunch-description">{item.description}</p>
                                                <p className="lunch-group">Group: {item.group}</p>
                                            </div>
                                        </div>
                                    )
                                }) : this.props.data.displayId === 4 ?
                                    this.props.data[this.props.currentWeekDay][this.props.segment.name].map((item) => {
                                        return (
                                            <div className="mobile-item mobile-item-center"
                                                onClick={() => {
                                                    this.props.showDetails(item.id)

                                                }}

                                            >

                                                <div className="activity-info">

                                                    <div className="info">
                                                        <p className="item-time">{item.time}</p>
                                                        <p className="item-title">{item.title}</p>
                                                    </div>

                                                    <div className="location">
                                                        <p>Place: {item.place}</p>
                                                        <p>Group: {item.group}</p>
                                                    </div>

                                                    <div className="item-teacher-info">
                                                        <div className="item-teacher-info-img">
                                                            <img src={item.teacher?.img} alt="" />
                                                        </div>
                                                        <div>
                                                            <p>{item.teacher?.category}</p>
                                                            <p>{item.teacher?.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : this.props.data.displayId === 7 ?
                                    this.props.data[this.props.currentWeekDay][this.props.segment.name].map((item) => {
                                        return (
                                            //nuevo shift planning
                                            <div className="mobile-item mobile-item-center"
                                                onClick={() => {
                                                    this.props.showDetails(item.id)

                                                }}

                                            >

                                                <div className="activity-info">

                                                    <div className="info">
                                                        <p className="item-time">{item.time}</p>
                                                        <p className="item-title">{item.title}</p>
                                                    </div>

                                                    <div className="location">
                                                        <p>Place: {item.place}</p>
                                                        <p>Group: {item.group}</p>
                                                    </div>

                                                    <div className="item-teacher-info">
                                                        <div className="item-teacher-info-img">
                                                            <img src={item.teacher?.img} alt="" />
                                                        </div>
                                                        <div>
                                                            <p>{item.teacher?.category}</p>
                                                            <p>{item.teacher?.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : this.props.data[this.props.currentWeekDay][this.props.segment.name].map((item) => {
                                        return (
                                            <div className="mobile-item mobile-item-center"
                                                onClick={() => {
                                                    this.props.showDetails(item.id)

                                                }}
                                            >

                                                <div className="shift-extra-data">
                                                    {item.extraData}
                                                </div>
                                                <div className="shift-info">

                                                    <div className="shift">
                                                        <div className="shift-img">
                                                            <img src={item.img} alt="" />
                                                        </div>
                                                        <div className="info">
                                                            <p className="info-name">{item.name}</p>
                                                            <p>{item.type}</p>
                                                            <p>{item.shift}</p>
                                                            <p>{item.apprentice}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                }
            </React.Fragment>
        )
    }
}