import React, { Component } from 'react';
import TableCardSmall from '../../partials/TableCardSmall';
import TableCardLarge from '../../partials/TableCardLarge';

class CreateLocation extends Component {
    state = {
        create: true,
        edit: true,
        data: {
            info: {
                name: "",
                city: "",
                postal: "",
                street: "",
                houseno: "",
                phone: "",
                email: "",
                localmanager: ""
            },
            exitmoment: {
                age: "",
                exitdate: []
            },
            payment: {
                paymentdue: "",
                cancelationdays: "",
                monthinadvance: "",
                otherfromlog: ""
            },
            legalcarevalues: {
                data: [],
                columns: []
            },
            lifephases: [],
            products: [],
            holidays: {
                recurring: [],
                specific: []
            }
        }
    }

    componentDidMount() {
        if (this.props.match.params.type === 'create') {
            this.setState({
                create: true,
                edit: false
            });
        } else {
            if (this.props.match.params.type === 'edit') {
                this.setState({
                    create: false,
                    edit: true
                });
            } else {
                this.setState({
                    create: false,
                    edit: false
                });
            }
            this.setState({
                data: {
                    info: {
                        name: "Location's name 1",
                        city: "Brisbane",
                        postal: "0810",
                        street: "correa Place",
                        houseno: 36,
                        phone: "(08) 8968 6281",
                        email: "test@wondergarden.com.au",
                        localmanager: "Susan Doe"
                    },
                    exitmoment: {
                        age: 12,
                        exitdate: [
                            {
                                id: 1,
                                date: '01/01/2020'
                            }, {
                                id: 2,
                                date: '01/02/2020'
                            }, {
                                id: 3,
                                date: '01/03/2020'
                            }
                        ]
                    },
                    payment: {
                        paymentdue: 2,
                        cancelationdays: 5,
                        monthinadvance: 1,
                        otherfromlog: 2
                    },
                    legalcarevalues: {
                        data: [
                            {
                                id: 1,
                                legalfunction: 'Calified',
                                phase0_18: 2.33,
                                phase18_24: 3.50,
                                phase24_72: 3.50
                            }, {
                                id: 1,
                                legalfunction: 'Calified',
                                phase0_18: 2.33,
                                phase18_24: 3.50,
                                phase24_72: 3.50
                            }
                        ],
                        columns: [
                            {
                                title: 'Legal Function Name',
                                name: 'legalfunction'
                            },
                            {
                                title: '0-18',
                                name: 'phase0_18'
                            },
                            {
                                title: '18-24',
                                name: 'phase18_24'
                            },
                            {
                                title: '24-72',
                                name: 'phase24_72'
                            }
                        ]
                    },
                    lifephases: [
                        {
                            id: 1,
                            phase: '0-18',
                            weighting: 3.50
                        }, {
                            id: 2,
                            phase: '18-24',
                            weighting: 3.00
                        }, {
                            id: 3,
                            phase: '24-72',
                            weighting: 2.50
                        }
                    ],
                    products: [
                        {
                            id: 1,
                            name: 'Kindergarden'
                        }, {
                            id: 2,
                            name: 'Summer camp'
                        }
                    ],
                    holidays: {
                        recurring: [
                            {
                                id: 1,
                                day: 25,
                                month: 12
                            },
                            {
                                id: 2,
                                day: 25,
                                month: 12
                            },
                            {
                                id: 3,
                                day: 25,
                                month: 12
                            }
                        ],
                        specific: [
                            {
                                id: 1,
                                date: '25/12/2020'
                            },
                            {
                                id: 2,
                                date: '25/12/2020'
                            },
                            {
                                id: 3,
                                date: '25/12/2020'
                            }
                        ]
                    }
                }
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.state.create ? "aside-bar info create" : "aside-bar info"}>
                    <div className="container">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => this.props.history.goBack()}><i className="material-icons">arrow_back_ios</i> Back</button>
                                {!this.state.edit && !this.state.create ? <button className="right" onClick={() => this.setState({ edit: true })}><i className="material-icons">create</i> Edit</button> : ''}
                            </div>
                            <h3>{this.state.create ? "CREATE" : "INFO"}</h3>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">Name*</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.name : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">City*</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.city : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Postal*</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.postal : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Street*</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.street : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">House No.*</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.houseno : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Phone</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.phone : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Email*</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.email : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Local Manager*</label>
                                <input type="text" name="" defaultValue={!this.state.create ? this.state.data.info.localmanager : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                            </div>
                            <div className={this.state.edit || this.state.create ? "btn-container" : "btn-container dissapear"}>
                                <button className="principal">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.create ? "content create" : "content"}>
                    <div className="content-head">
                        <div className="title">
                            <h1>{this.state.create ? "Create Location" : this.state.data.info.name}</h1>
                            <div className="route">Locations &gt; Location Overview &gt; {this.state.create ? "Create Location" : this.state.data.info.name}</div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="flex-4 column left">
                            <div className="card">
                                <div className="card-title">EXIT MOMENT</div>
                                <div className="subtitle">Age at exit</div>
                                <div className="group-input">
                                    <label htmlFor="">Age in months</label>
                                    <input type="number" name="" defaultValue={!this.state.create ? this.state.data.exitmoment.age : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="name" />
                                </div>
                                <div className="subtitle">Children Exit Moments</div>
                                <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                    <label htmlFor="">Date</label>
                                    <input type="date" name="" id="text" />
                                    <div>
                                    <button className="add"><i className="material-icons">add</i></button>
                                    </div>
                                </div>
                                <TableCardSmall
                                    data={this.state.data.exitmoment.exitdate}
                                    columns={[
                                        {
                                            title: 'ID',
                                            name: 'id'
                                        },
                                        {
                                            title: 'Date',
                                            name: 'date'
                                        }
                                    ]}
                                    buttons_first={true}
                                    edit={false}
                                    delete={this.state.edit || this.state.create}
                                    delete_function=""
                                    edit_function=""
                                />
                                <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                    <button>Save</button>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-title">PAYMENT</div>
                                <div className="subtitle">Payment Due</div>
                                <div className="group-input">
                                    <label htmlFor="">No. of days</label>
                                    <input type="number" defaultValue={!this.state.create ? this.state.data.payment.paymentdue : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} name="" id="name" />
                                </div>
                                <div className="subtitle">Cancelation Period</div>
                                <div className="group-input">
                                    <label htmlFor="">No. of days</label>
                                    <input type="number" name="" defaultValue={!this.state.create ? this.state.data.payment.cancelationdays : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                                </div>
                                <div className="subtitle">Invoice Periods</div>
                                <div className="group-input">
                                    <label htmlFor="">Month in advance</label>
                                    <input type="number" name="" defaultValue={!this.state.create ? this.state.data.payment.monthinadvance : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                                </div>
                                <div className="group-input">
                                    <label htmlFor="">Other from log</label>
                                    <input type="number" name="" defaultValue={!this.state.create ? this.state.data.payment.otherfromlog : ""} disabled={this.state.edit || this.state.create ? "" : "disabled"} id="text" />
                                </div>
                                <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                    <button>Save</button>
                                </div>
                            </div>
                        </div>
                        <div className="flex-8 column right">
                            <div className="card">
                                <div className="card-title">LEGAL CARE VALUES</div>
                                <TableCardLarge
                                    data={this.state.data.legalcarevalues.data}
                                    columns={this.state.data.legalcarevalues.columns}
                                    delete={this.state.edit || this.state.create}
                                    delete_function=""
                                    showInput={this.state.edit || this.state.create}
                                />
                                <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                    <button>Save</button>
                                </div>
                            </div>
                            <div className="container">
                                <div className="flex-6 left column">
                                    <div className="card">
                                        <div className="card-title">LIFE PHASES</div>
                                        <div className="subtitle">Life Phases in months</div>
                                        <div className="flex">
                                            <div className="flex-6">
                                                <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                                    <label htmlFor="">From</label>
                                                    <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                                                </div>
                                            </div>
                                            <div className="flex-6">
                                                <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                                    <label htmlFor="" className="center">to</label>
                                                    <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                            <label htmlFor="">Weighting</label>
                                            <input type="number" name="" defaultValue="Susan Doe" id="text" />
                                            <div>
                                            <button className="add"><i className="material-icons">add</i></button>
                                            </div>
                                        </div>
                                        <TableCardSmall
                                            data={this.state.data.lifephases}
                                            columns={[
                                                {
                                                    title: 'ID',
                                                    name: 'id'
                                                },
                                                {
                                                    title: 'Phase',
                                                    name: 'phase'
                                                },
                                                {
                                                    title: 'Weighting',
                                                    name: 'weighting'
                                                }
                                            ]}
                                            buttons_first={true}
                                            edit={false}
                                            delete={this.state.edit || this.state.create}
                                            delete_function=""
                                            edit_function=""
                                        />
                                        <div className={this.state.edit || this.state.create ? "save-container" : "save-container dissapear"}>
                                            <button>Save</button>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-title">PRODUCTS</div>
                                        <div className={this.state.edit || this.state.create ? "" : "dissapear"} >

                                            <div className="subtitle">New product</div>
                                            <div className="group-input">
                                                <label htmlFor="">Name</label>
                                                <input type="text" name="" id="name" />
                                            </div>
                                            <div className="group-input checkbox">
                                                <input type="checkbox" name="" id="remember_login" />
                                                <label htmlFor="remember_login">Flexible hours</label>
                                                <div className="save-container">
                                                    <button>Save</button>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="subtitle">Existing products</div>
                                        <TableCardSmall
                                            data={this.state.data.products}
                                            columns={[
                                                {
                                                    title: 'ID',
                                                    name: 'id'
                                                },
                                                {
                                                    title: "Product's name",
                                                    name: 'name'
                                                }
                                            ]}
                                            buttons_first={true}
                                            edit={this.state.edit || this.state.create}
                                            delete={this.state.edit || this.state.create}
                                            delete_function=""
                                            edit_function=""
                                        />
                                    </div>
                                </div>
                                <div className="flex-6 column right">
                                    <div className="card">
                                        <div className="card-title">HOLIDAYS</div>
                                        <div className="subtitle">Recurring Holidays</div>
                                        <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"} >
                                            <label htmlFor="">Month</label>
                                            <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                                            <label htmlFor="" className="center">Day</label>
                                            <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                                            <div>
                                            <button className="add"><i className="material-icons">add</i></button>
                                            </div>
                                        </div>
                                        <TableCardSmall
                                            data={this.state.data.holidays.recurring}
                                            columns={[
                                                {
                                                    title: 'ID',
                                                    name: 'id'
                                                },
                                                {
                                                    title: "Day",
                                                    name: 'day'
                                                },
                                                {
                                                    title: "Month",
                                                    name: 'month'
                                                }
                                            ]}
                                            buttons_first={true}
                                            edit={false}
                                            delete={this.state.edit || this.state.create}
                                            delete_function=""
                                            edit_function=""
                                        />
                                        <div className="subtitle">Year Specific Holidays</div>
                                        <div className={this.state.edit || this.state.create ? "group-input" : "group-input dissapear"}>
                                            <label htmlFor="">Date</label>
                                            <input type="date" name="" defaultValue="Susan Doe" id="text" />
                                            <div>
                                            <button className="add"><i className="material-icons">add</i></button>
                                            </div>
                                        </div>
                                        <TableCardSmall
                                            data={this.state.data.holidays.specific}
                                            columns={[
                                                {
                                                    title: 'ID',
                                                    name: 'id'
                                                },
                                                {
                                                    title: "Date",
                                                    name: 'date'
                                                }
                                            ]}
                                            buttons_first={true}
                                            edit={false}
                                            delete={this.state.edit || this.state.create}
                                            delete_function=""
                                            edit_function=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CreateLocation;